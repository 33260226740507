/* eslint-disable import/no-default-export */
import { IonCol } from '@ionic/react'
import {
  ProjectAsset,
  ProjectAssetsTypes,
} from '../../pages/ProjectPage/ProjectPage.types'
import { ScrollToContainer } from '../ui/ScrollToContainer'
import MediaItemList from '../ui/Card/MediaItemList'
import { ProjectAerialMapAssetsProps } from './ProjectAerialMapAssets.types'
import Pix4DAssetCard from './Pix4DAssetsCard.component'
import NearmapAssetCard from './NearmapAssetsCard.component'

const ProjectPix4dAssets = ({
  pix4d: pix4dAssets,
  nearmap: nearmapAssets,
}: ProjectAerialMapAssetsProps) => {
  if (!pix4dAssets?.length && !nearmapAssets?.length) {
    return null
  }

  const aerialMapAsset = [
    ...((pix4dAssets || []) as [ProjectAsset]),
    ...((nearmapAssets || []) as [ProjectAsset]),
  ].sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <ScrollToContainer name="project-pix4d" data-testid="ProjectPix4dAssets">
      <MediaItemList title="Aerial" itemCount={aerialMapAsset?.length}>
        {aerialMapAsset.map((asset) => (
          <IonCol
            className="cardContainer"
            key={asset.id}
            size="5"
            sizeLg="6"
            sizeXl="4"
          >
            {asset.type === ProjectAssetsTypes.PIX4D && (
              <Pix4DAssetCard pix4d={asset} />
            )}
            {asset.type === ProjectAssetsTypes.NEARMAP && (
              <NearmapAssetCard nearmap={asset} />
            )}
          </IonCol>
        ))}
      </MediaItemList>
    </ScrollToContainer>
  )
}

export default ProjectPix4dAssets
