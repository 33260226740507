export const projectActions = [
  // PROJECT
  'DeleteProject',
  'ViewAssignedProjects',
  'EditProjectDetails',
  // PROJECT ASSETS
  // 'ViewProjectAssets', // same as ViewAssignedProjects
  'AddProjectAssets',
  'EditProjectAssets',
  'DeleteProjectAssets',
  // PROJECT USERS
  'ViewProjectUsers',
  'AddProjectUsers',
  'DeleteProjectUsers',
  // PROJECT USERS ROLES
  'ManageAdminRole',
  'ManageContributorRole',
  'ManageMemberRole',
  'ManageGuestRole',
  'ManageTestNoPermissionsRole',
  // SHARED LINK
  'EnableSharedLink',
  'ManageSharedLink',
] as const

export const projectRoles = {
  1: 'admin',
  2: 'contributor',
  3: 'member',
  4: 'guest',
  5: 'testNoPermissions',
}

export type ProjectRoleIds = keyof typeof projectRoles
export type ProjectRoleNames = typeof projectRoles[ProjectRoleIds]

type ProjectRolesMapType = {
  [key in ProjectRoleNames]: ProjectRoleIds
}

export const projectRolesMap: ProjectRolesMapType = {
  admin: 1,
  contributor: 2,
  member: 3,
  guest: 4,
  testNoPermissions: 5,
}

export const projectPermissions: {
  [key in ProjectRoleIds]: typeof projectActions[number]
} = {
  1: 'ManageAdminRole',
  2: 'ManageContributorRole',
  3: 'ManageMemberRole',
  4: 'ManageGuestRole',
  5: 'ManageTestNoPermissionsRole',
}
