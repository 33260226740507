import { useLazyQuery } from '@apollo/client'
import { getAssignedProjectsQuery } from './PortfolioUser.graphql'

export const useGetAssignedProjectsQuery = (): {
  getAssignedProjects: any
} => {
  const [getAssignedProjects] = useLazyQuery(getAssignedProjectsQuery)

  return {
    getAssignedProjects,
  }
}
