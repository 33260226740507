import * as yup from 'yup'
import { PortfolioFormFields } from './PortfolioDialog.types'

export const initialValues: PortfolioFormFields = {
  error: null,
  name: '',
}

export const validationSchema = yup.object({
  name: yup.string().max(255).required('Name is required'),
})
