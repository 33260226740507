/* eslint-disable import/no-default-export */
import React, { useState } from 'react'
import { IonCheckbox, IonIcon, IonItem } from '@ionic/react'
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons'
import { ProjectSharedLinkSectionProps } from './ProjectSharedLinkSection.types'
import { ProjectAsset } from '../../pages/ProjectPage/ProjectPage.types'
import styles from './ProjectSharedLinkSection.module.css'

const ProjectSharedLinkSection: React.FC<ProjectSharedLinkSectionProps> = ({
  sectionName,
  assets,
  visibleAssets,
  setVisibleAssets,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  if (!assets || assets.length === 0) {
    return null
  }
  let sectionHalfSelected = false
  let sectionAllSelected = true
  const availableAssets: string[] = []

  assets.forEach((asset: ProjectAsset) => {
    if (visibleAssets.includes(asset.id)) {
      sectionHalfSelected = true
    } else {
      sectionAllSelected = false
    }
    availableAssets.push(asset.id.toString())
  })

  if (sectionAllSelected) {
    sectionHalfSelected = false
  }

  const onSectionChange = () => {
    if (!sectionAllSelected) {
      setVisibleAssets([...new Set(visibleAssets.concat(availableAssets))])
    } else {
      setVisibleAssets(
        visibleAssets.filter((id: string) => !availableAssets.includes(id)),
      )
    }
  }

  const onItemChange = (e: any) => {
    if (e.detail.checked) {
      setVisibleAssets([...new Set(visibleAssets.concat(e.detail.value))])
    } else {
      setVisibleAssets(
        visibleAssets.filter((id: string) => id !== e.detail.value),
      )
    }
  }

  const onChevronClick = (event: any) => {
    event.stopPropagation()
    setIsCollapsed((previousIsCollapsed) => !previousIsCollapsed)
  }

  return (
    <div>
      <IonItem button={false} className={styles.sectionContainer}>
        <IonCheckbox
          slot="start"
          indeterminate={sectionHalfSelected}
          checked={sectionAllSelected}
          onClick={onSectionChange}
          className={styles.sectionName}
          labelPlacement="end"
        >
          {sectionName}
        </IonCheckbox>
        <IonIcon
          className={styles.collapsibleIcon}
          icon={isCollapsed ? chevronForwardOutline : chevronDownOutline}
          slot="end"
          onClick={onChevronClick}
        />
      </IonItem>

      <div style={{ display: isCollapsed ? 'none' : 'block' }}>
        {assets?.map((asset) => (
          <IonItem
            className={styles.assetContainer}
            lines="none"
            key={asset.id}
          >
            <IonCheckbox
              slot="start"
              checked={visibleAssets.includes(asset.id)}
              onIonChange={onItemChange}
              value={asset.id}
              color="secondary"
              labelPlacement="end"
            >
              {asset.name}
            </IonCheckbox>
          </IonItem>
        ))}
      </div>
    </div>
  )
}

export default ProjectSharedLinkSection
