import { gql } from '@apollo/client'

export const isLoggedInQuery = gql`
  query Me {
    me {
      id
      isLoggedIn
      name
      email
      phone
      avatarUrl
      organisations {
        id
        name
        phone
        permissions
        settings {
          wordReport
          bookingFormId
          sharedLinkAssetsDefaultsFilter
        }
      }
    }
  }
`
