/* eslint-disable import/no-default-export */
import { informationCircle } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import styled from 'styled-components'
import { FlexBox } from '../../ui/FlexBox'
import { RoleInfoProps } from './RoleInfo.types'
import {
  portfolioRoleDescriptions,
  portfolioRoles,
} from '../../PortfolioUsersDialog/PortfolioUsersDialog.constants'

const StyledInfoBox = styled(FlexBox)`
  min-height: 56px;
  border-radius: 8px;
  border: 1px solid var(--sync-blue);
  padding: 5px 12px;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    padding: 5px;
  }
`

const StyledIonIconLarger = styled(IonIcon)`
  font-size: 24px;
  color: var(--sync-blue);
  min-width: 24px;
`

const StyledRoleDescription = styled.div`
  margin-left: 8px;
  font-size: 12px;
`

const RoleInfo = ({ roleId }: RoleInfoProps) => (
  <StyledInfoBox alignItems="flex-start">
    <FlexBox direction="row" justifyContent="flex-start" fullHeight>
      <StyledIonIconLarger icon={informationCircle} />
      <StyledRoleDescription>
        <strong>{portfolioRoles[roleId]}</strong>
        &nbsp;
        {portfolioRoleDescriptions[roleId]}
      </StyledRoleDescription>
    </FlexBox>
  </StyledInfoBox>
)

export default RoleInfo
