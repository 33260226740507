import { useQuery } from '@apollo/client'
import { getFullSizeUrl } from './ImagePreviewDialog.graphql'

export const useAssetQuery = (assetId: string, projectId: string) => {
  const { data, loading, error, updateQuery } = useQuery(getFullSizeUrl, {
    variables: { assetId, projectId },
  })

  return {
    asset: {
      ...(data && data.asset),
    },
    error,
    loading,
    updateQuery,
  }
}
