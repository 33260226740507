import * as yup from 'yup'
import { ProjectStatus } from '@synctech/portal-types'
import { ProjectFormFields } from './ProjectDialog.types'

export const initialValues: ProjectFormFields = {
  error: null,
  address: '',
  status: ProjectStatus.PENDING,
  description: '',
}

export const validationSchema = yup.object({
  title: yup.string().max(255).nullable(),
  address: yup.string().max(255).required('Address is required'),
  description: yup.string(),
  status: yup.string().required('Status is required'),
  reference_id: yup.string().max(20).nullable(),
})
