/* eslint-disable import/no-default-export */
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useChangePassword } from '../ChangePasswordDialog/ChangePasswordDialog.hooks'
import { TextInputField } from '../fields/TextInputField'
import { Button } from '../ui/Button'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'
import { ErrorComponent } from '../ui/ErrorComponent'
import { FlexBox } from '../ui/FlexBox'
import { initialValues, validationSchema } from './ChangePasswordForm.constants'
import { ChangePasswordFormProps } from './ChangePasswordForm.types'

const ButtonContainer = styled(FlexBox)`
  margin-top: auto;
  padding-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column-reverse !important;
    justify-content: stretch !important;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
`

const ChangePasswordForm = ({
  onDismiss,
  setAllowedToDismiss,
  forceDismiss,
}: DialogWithAlertInnerAugment & ChangePasswordFormProps) => {
  const { changePassword, loading } = useChangePassword(forceDismiss)
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: changePassword,
  })

  useEffect(() => {
    setAllowedToDismiss(!formik.dirty)
  }, [formik.dirty])

  return (
    <FormikProvider value={formik}>
      <FlexBox
        fullHeight
        justifyContent="space-between"
        dataTestid="ChangePasswordForm"
      >
        <FlexBox fullWidth alignItems="stretch">
          <h2 className="ion-text-center ion-no-margin ion-padding-bottom">
            Enter Current and New password
          </h2>
          <TextInputField
            className="ion-margin-bottom"
            type="password"
            name="oldPassword"
            placeholder="Current Password"
            autocomplete="current-password"
          />
          <TextInputField
            className="ion-margin-bottom"
            type="password"
            name="newPassword"
            placeholder="New Password"
            autocomplete="new-password"
          />
          <TextInputField
            className="ion-margin-bottom"
            type="password"
            name="confirm"
            placeholder="Re-enter the new password"
          />
          <ErrorMessage name="error" component={ErrorComponent} />
          <p>
            Your password must be a minimum of 10 characters, and include at
            least one lowercase letter, one uppercase letter, one symbol and a
            number.
          </p>
        </FlexBox>
        <ButtonContainer direction="row" fullWidth gap={20}>
          <StyledButton
            color="light"
            expand="block"
            onClick={() => onDismiss()}
          >
            Cancel
          </StyledButton>
          <StyledButton disabled={loading} onClick={formik.submitForm}>
            Change Password
          </StyledButton>
        </ButtonContainer>
      </FlexBox>
    </FormikProvider>
  )
}

export default ChangePasswordForm
