import { useAuthContext } from '../contexts/auth'
import { Authorized } from './Authorized'
import { Unauthorized } from './Unauthorized'
import { useOrganisationContext } from '../contexts/organisation'
import { NoOrg } from './NoOrg'

export const AppNavigation = () => {
  const { isLoggedIn } = useAuthContext()
  const { organisationId } = useOrganisationContext()

  if (isLoggedIn) {
    if (!organisationId) {
      return <NoOrg />
    }
    return <Authorized />
  }
  return <Unauthorized />
}
