/* eslint-disable import/no-default-export */
import mixpanel from 'mixpanel-browser'

// advice to set mixpanel's token default value
// https://github.com/mixpanel/mixpanel-js/issues/133#issuecomment-371609893
const token = process.env.REACT_APP_MIXPANEL_TOKEN || 'TEST_TOKEN'
mixpanel.init(token, {
  debug: process.env.NODE_ENV === 'development',
})

export default mixpanel
