/* eslint-disable import/no-default-export */
import styles from './WaitingSpinner.module.css'

const WaitingSpinner: React.FC = () => (
  <div className={styles.backdrop}>
    <div className={styles.container}>
      <div className={styles.ldsSpinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className={styles.text}>Please wait...</div>
    </div>
  </div>
)

export default WaitingSpinner
