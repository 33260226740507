import { useMutation } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { FormikHelpers } from 'formik'
import { useNotification } from '../../contexts/notification'
import { addPortfolioMutation } from './PortfolioAddDialog.graphql'
import { PortfolioFormFields } from './PortfolioDialog.types'
import { useOrganisationContext } from '../../contexts/organisation'
import { portfolioListQuery } from '../../pages/PortfoliosListPage/PortfoliosListPage.graphql'
import { Portfolio } from '../../pages/PortfolioPage/PortfolioPage.types'

export const useAddPortfolio = (updateQuery: any, onDismiss: () => void) => {
  const [addPortfolioRequest, { loading }] = useMutation(addPortfolioMutation, {
    refetchQueries: [getOperationName(portfolioListQuery)!],
  })

  const { notify } = useNotification()
  const { organisationId } = useOrganisationContext()

  const addPortfolio = async (
    values: PortfolioFormFields,
    formikHelpers: FormikHelpers<PortfolioFormFields>,
  ) => {
    try {
      const newPortfolio = await addPortfolioRequest({
        variables: {
          ...values,
          organisation_id: parseInt(organisationId as unknown as string, 10),
        },
      })

      updateQuery(({ portfolios }: { portfolios: Portfolio[] }) => ({
        portfolios: [...portfolios, [newPortfolio.data.addPortfolio]],
      }))

      notify('Portfolio successfully created')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    addPortfolio,
    loading,
  }
}
