import { gql } from '@apollo/client'

export const editProjectMutation = gql`
  mutation editProject(
    $projectId: ID!
    $thumbnail: Upload
    $description: String
    $title: String
    $address: String
    $status: String
    $reference_id: String
  ) {
    editProject(
      projectId: $projectId
      thumbnail: $thumbnail
      description: $description
      title: $title
      address: $address
      status: $status
      reference_id: $reference_id
    ) {
      id
      thumbnailUrl
      title
      description
      address
      status
      reference_id
    }
  }
`
