import { useMutation, useQuery } from '@apollo/client'
import { useNotification } from '../../contexts/notification'
import { unpackPermissions } from '../../helpers/casl'
import { projectQuery } from '../../pages/ProjectPage/ProjectPage.graphql'
import {
  deleteProjectUserMutation,
  projectUsersQuery,
} from './ProjectUsersDialog.graphql'
import { ProjectUser } from './ProjectUsersDialog.types'

export const useUserListQuery = (
  projectId: string,
): {
  userList: ProjectUser[]
  permissions: string[]
  loading: boolean
  updateQuery: any
} => {
  const { data, loading, updateQuery } = useQuery(projectUsersQuery, {
    variables: {
      id: projectId.toString(),
    },
  })

  return {
    userList: data && data.project.users,
    permissions: unpackPermissions(data?.project?.permissions),
    loading,
    updateQuery,
  }
}

export const useProjectUserDelete = (projectId: string, userId: number) => {
  const [deleteProjectUserRequest, { loading }] = useMutation(
    deleteProjectUserMutation,
    {
      variables: {
        projectId,
        userId: Number(userId), // it comes as a string form backend "project" request 0_o
      },
      refetchQueries: [
        // For now we don't trigger project list refetch because it not doing well with paginated results
        {
          query: projectUsersQuery,
          variables: { id: projectId.toString() },
        },
        {
          query: projectQuery,
          variables: { id: projectId.toString() },
        },
      ],
    },
  )

  const { notify, notifyError } = useNotification()

  const deleteProjectUser = async () => {
    try {
      await deleteProjectUserRequest()

      notify('Project member successfully deleted')
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  return {
    deleteProjectUser,
    loading,
  }
}
