/* eslint-disable import/no-default-export */
import { IonChip, IonIcon, IonLabel, IonSpinner } from '@ionic/react'
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  ellipsisHorizontal,
  warning,
} from 'ionicons/icons'
import prettyBytes from 'pretty-bytes'
import styled, { css } from 'styled-components'
import { FlexBox } from '../ui/FlexBox'
import { FileUploadComponentProps, Invalid } from './FileUpload.types'

const StyledBox = styled.div`
  height: 32px;
  background-color: var(--sync-shadowed-white);
  padding: 0 0.5rem;
  margin-bottom: 8px;
`

const StyledFlexBox = styled(FlexBox)`
  > div {
    flex: 1;
    flex-basis: 50%;
  }
`

const StyledFileName = styled.div<Invalid>`
  color: ${(props) => (props.invalid ? css`var(--sync-red)` : 'inherit')};
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: 0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-basis: 50%;
`

const StyledIonSpinner = styled(IonSpinner)`
  --color: var(--sync-light-blue);
  height: 16px;
  width: 16px;
`

const StyledIonIcon = styled(IonIcon)`
  font-size: 14px;
`

const StyledIonChip = styled(IonChip)<Invalid>`
  :hover {
    cursor: default;
    background-color: transparent;
  }
  border-color: ${(props) =>
    props.invalid ? css`var(--sync-red)` : css`var(--sync-light-blue)`};
  color: ${(props) =>
    props.invalid ? css`var(--sync-red)` : css`var(--sync-light-blue)`};
  font-size: 10px;
  font-weight: bold;
  height: 16px;
  min-height: 16px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
`

const StyledErrorBox = styled.div`
  height: 56px;
  border-radius: 8px;
  border: 1px solid var(--sync-red);
  padding: 0 12px;
  margin-bottom: 8px;
`

const StyledIonIconLarger = styled(IonIcon)`
  font-size: 24px;
  color: var(--sync-light-red);
`

const StyledErrorMessage = styled.div`
  margin-left: 8px;
  color: var(--sync-light-red);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.02em;
`

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  file: { name, size },
  loaded,
  loading,
  invalid,
  hold,
  errorMessage = 'Upload failed! File not supported',
}) => (
  <>
    <StyledBox data-testid="FileUpload">
      <StyledFlexBox direction="row" justifyContent="space-between" fullHeight>
        <StyledFileName invalid={invalid}>{name}</StyledFileName>
        <FlexBox direction="row" justifyContent="space-between">
          <StyledIonChip outline invalid={invalid}>
            <IonLabel>{prettyBytes(size)}</IonLabel>
          </StyledIonChip>
          <FlexBox direction="row" justifyContent="flex-end">
            {loading && !loaded && !invalid && (
              <StyledIonSpinner name="circles" />
            )}
            {hold && !loaded && !invalid && (
              <StyledIonIcon color="primary" icon={ellipsisHorizontal} />
            )}
            {!(loading || hold) && (
              <StyledIonIcon
                color={!invalid ? 'primary' : 'syncred'}
                icon={!invalid ? checkmarkCircleOutline : closeCircleOutline}
              />
            )}
          </FlexBox>
        </FlexBox>
      </StyledFlexBox>
    </StyledBox>
    {invalid && (
      <StyledErrorBox>
        <FlexBox direction="row" justifyContent="flex-start" fullHeight>
          <StyledIonIconLarger icon={warning} />
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        </FlexBox>
      </StyledErrorBox>
    )}
  </>
)

export default FileUploadComponent
