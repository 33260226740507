import { gql } from '@apollo/client'

export const getAutodeskTokenQuery = gql`
  query autodeskToken {
    autodeskToken {
      token
      expires
    }
  }
`
