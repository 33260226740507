/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const portfolioUsersQuery = gql`
  query PortfolioUsers($id: ID!) {
    portfolio(id: $id) {
      id
      permissions
      users {
        id
        name
        email
        avatarUrl
        roleId
        isRegistered
      }
    }
  }
`

export const deletePortfolioUserMutation = gql`
  mutation DeletePortfolioUser($portfolioId: ID!, $userId: ID!) {
    deletePortfolioUser(portfolioId: $portfolioId, userId: $userId) {
      id
    }
  }
`
