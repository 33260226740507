/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const portfolioQuery = gql`
  query Portfolio($id: ID!, $offset: Int, $limit: Int) {
    portfolio(id: $id) {
      id
      name
      permissions
      usersPreview {
        id
        name
        avatarUrl
      }
      usersCount
      projects(offset: $offset, limit: $limit) {
        id
        thumbnailUrl
        address
        status
        permissions
        usersPreview {
          id
          name
          avatarUrl
        }
      }
    }
  }
`
