import { useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import {
  assetsTypesMapping,
  Project,
  ProjectAsset,
} from '../../pages/ProjectPage/ProjectPage.types'
import { deleteProjectAssetMutation } from './AssetPopoverMenu.graphql'
import { useNotification } from '../../contexts/notification'
import { useProjectContext } from '../../contexts/project'

export const useAssetDelete = (asset: ProjectAsset) => {
  const { projectId, updateQuery } = useProjectContext()
  // eslint-disable-next-line no-unused-vars
  const [deleteAsset, { loading: mutationLoading }] = useMutation(
    deleteProjectAssetMutation,
    {
      variables: {
        projectId,
        assetId: asset.id,
      },
    },
  )
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    setLoading(mutationLoading)
  }, [mutationLoading])
  const { notifyError, notify } = useNotification()

  const onAssetDelete = async () => {
    setLoading(true)
    try {
      await deleteAsset()

      updateQuery(({ project }: { project: Project }) => {
        // @ts-ignore
        const assetType = assetsTypesMapping[asset.type]
        // @ts-ignore Object is possibly 'undefined'
        const editedAssets: [ProjectAsset] = project?.assets[assetType]

        const result = {
          ...project,
          assets: {
            ...project.assets,
            [assetType]: editedAssets.filter((a) => a.id !== asset.id),
          },
        }
        return {
          project: result,
        }
      })

      notify('Successfully deleted')
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
      setLoading(false)
    }
  }

  return {
    deleteAsset: onAssetDelete,
    loading,
  }
}
