/* eslint-disable import/no-default-export */
import React from 'react'
import { ProjectSharedLinkAccessDialogProps } from './ProjectSharedLinkAccessDialog.types'
import ProjectSharedLinkAccessForm from './ProjectSharedLinkAccessForm.component'
import { DialogWithAlert } from '../ui/DialogWithAlert'

const ProjectSharedLinkAccessDialog: React.FC<
  ProjectSharedLinkAccessDialogProps
> = ({ project, onDismiss }) => {
  const FormWrapper = (props: any) => (
    <ProjectSharedLinkAccessForm {...props} project={project} />
  )

  return (
    <DialogWithAlert
      dialogTitle="Shared Link Visibility Settings"
      alertHeader="Cancel Editing Visibility Settings?"
      alertMessage="The form contains unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectSharedLinkAccessDialog
