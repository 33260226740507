import { gql } from '@apollo/client'

export const getFullSizeUrl = gql`
  query getAsset($assetId: ID!, $projectId: ID!) {
    asset(assetId: $assetId, projectId: $projectId) {
      id
      name
      ... on ImageAsset {
        fullSizeUrl
      }
    }
  }
`
