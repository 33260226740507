/* eslint-disable import/no-default-export */
import { IonCol, IonImg } from '@ionic/react'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import {
  ProjectAsset,
  ThumbnailedAsset,
} from '../../pages/ProjectPage/ProjectPage.types'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { MediaItemCard } from '../ui/Card'
import { ScrollToContainer } from '../ui/ScrollToContainer'
import { ProjectBimAssetsProps } from './ProjectBimAssets.types'
import placeholder from '../../assets/placeholder_captur3d.png'
import extCad from '../../assets/ext_cad.svg'
import extDwg from '../../assets/ext_dwg.svg'
import extRevit from '../../assets/ext_revit.svg'
import extRcs from '../../assets/ext_rcs.svg'
import extSkp from '../../assets/ext_skp.svg'
import autodeskLink from '../../assets/link_autodesk.svg'
import { withCallbackTracker } from '../../hocs/withTracking'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ProjectBimAutodeskAssetsDialog } from '../ProjectBimAutodeskAssetsDialog'
import { ProjectBimAutodeskAssetsDialogHookProps } from '../ProjectBimAutodeskAssetsDialog/ProjectBimAutodeskAssetsDialog.types'
import MediaItemList from '../ui/Card/MediaItemList'
import styles from './ProjectBimAssets.module.css'

function getUrlExtension(url: string) {
  // @ts-ignore
  return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase()
}

const ProjectBimAssets = ({ bimFiles }: ProjectBimAssetsProps) => {
  if (!bimFiles?.length) {
    return null
  }

  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const editMenu = project.permissions.includes('DeleteProjectAssets')
    ? (bimFiles: ProjectAsset) => <AssetPopoverMenu asset={bimFiles} />
    : () => null

  return (
    <ScrollToContainer name="project-bim" data-testid="ProjectBimAssets">
      <MediaItemList title="BIM" itemCount={bimFiles?.length}>
        {bimFiles.map((asset) => {
          const openAsset = () => {
            window.open(asset.value, '_blank')
          }

          let secondTileOpenAsset
          let secondTileThumbnail

          let thumbnail = placeholder

          const ext = getUrlExtension(asset.value)

          switch (ext) {
            case 'dwg':
              thumbnail = extDwg
              break
            case 'dgn':
            case 'dwf':
            case 'ifc':
            case 'sat':
            case 'dxf':
              thumbnail = extCad
              break
            case 'rvt':
              thumbnail = extRevit
              secondTileThumbnail = autodeskLink
              if (asset.attributes?.bim_file?.autodesk_status === 'success') {
                secondTileThumbnail = (asset as ThumbnailedAsset).previewUrl
                  ? (asset as ThumbnailedAsset).previewUrl
                  : autodeskLink
                ;[secondTileOpenAsset] =
                  useDialog<ProjectBimAutodeskAssetsDialogHookProps>(
                    ProjectBimAutodeskAssetsDialog,
                    {
                      bimAsset: asset,
                    },
                  )
              }
              break
            case 'rfa':
            case 'rte':
            case 'rft':
              thumbnail = extRevit
              break
            case 'rcs':
              thumbnail = extRcs
              break
            case 'skp':
              thumbnail = extSkp
              break
            default:
              thumbnail = placeholder
          }

          return (
            <>
              {asset.attributes?.bim_file?.autodesk_status !== undefined && (
                <IonCol
                  className="cardContainer"
                  key={`${asset.id}-dialog`}
                  size="5"
                  sizeLg="6"
                  sizeXl="4"
                >
                  <MediaItemCard
                    editMenu={() => editMenu(asset)}
                    title={asset.name}
                  >
                    {!!secondTileOpenAsset && (
                      <IonImg
                        className={styles.bimCardThumbnail}
                        onClick={withCallbackTracker(
                          secondTileOpenAsset,
                          'FE - Asset View',
                          {
                            Asset_Type: 'BIM',
                            Asset_Name: asset.name,
                            Project_ID: projectId,
                            Project_Name: project.title,
                            Project_Address: project.address,
                          },
                        )}
                        src={secondTileThumbnail}
                        alt={asset.name}
                      />
                    )}
                    {!secondTileOpenAsset && (
                      <>
                        <IonImg
                          className={styles.bimCardThumbnail}
                          onClick={withCallbackTracker(
                            () => {},
                            'FE - Asset View',
                            {
                              Asset_Type: 'BIM',
                              Asset_Name: asset.name,
                              Project_ID: projectId,
                              Project_Name: project.title,
                              Project_Address: project.address,
                            },
                          )}
                          src={secondTileThumbnail}
                          alt={asset.name}
                        />
                        <div className={styles.processingBox}>Processing</div>
                      </>
                    )}
                  </MediaItemCard>
                </IonCol>
              )}
              <IonCol
                className="cardContainer"
                key={asset.id}
                size="5"
                sizeLg="6"
                sizeXl="4"
              >
                <MediaItemCard
                  editMenu={() => editMenu(asset)}
                  title={asset.name}
                >
                  <IonImg
                    className={styles.bimCardThumbnail}
                    onClick={withCallbackTracker(openAsset, 'FE - Asset View', {
                      Asset_Type: 'BIM',
                      Asset_Name: asset.name,
                      Project_ID: projectId,
                      Project_Name: project.title,
                      Project_Address: project.address,
                    })}
                    src={thumbnail}
                    alt={asset.name}
                  />
                </MediaItemCard>
              </IonCol>
            </>
          )
        })}
      </MediaItemList>
    </ScrollToContainer>
  )
}

export default ProjectBimAssets
