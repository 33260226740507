/* eslint-disable import/no-default-export */
import { useState } from 'react'
import { IonRow } from '@ionic/react'
import clsx from 'clsx'
import { MediaItemListProps } from './MediaItemList.types'
import { Title } from '../Title'
import styles from './MediaItemList.module.css'

const MediaItemList = ({ title, itemCount, children }: MediaItemListProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <>
      <Title value={itemCount} collapsible onCollapse={setIsCollapsed}>
        {title}
      </Title>
      <IonRow
        className={clsx(styles.cardGridContainer, {
          [styles.cardGridContainer__collapsed]: isCollapsed,
        })}
      >
        {children}
      </IonRow>
    </>
  )
}

export default MediaItemList
