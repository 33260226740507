/* eslint-disable import/no-default-export */
import React from 'react'
import {
  ProjectAssetNameEditDialogHookProps,
  ProjectAssetNameEditDialogProps,
  ProjectAssetNameEditFormFields,
} from './ProjectAssetNameDialog.types'
import { useEditProjectAssetName } from './ProjectAssetNameEditDialog.hooks'
import ProjectAssetNameForm from './ProjectAssetNameForm.component'
import { validationSchema } from './ProjectAssetNameForm.constants'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'

const TitleForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  projectId,
  assetId,
  name,
  updateQuery,
}: DialogWithAlertInnerAugment & ProjectAssetNameEditDialogHookProps) => {
  const { editProjectAssetName } = useEditProjectAssetName(
    projectId,
    assetId,
    updateQuery,
    forceDismiss,
  )

  const initialValues: ProjectAssetNameEditFormFields = {
    name,
    error: null,
  }

  return (
    <ProjectAssetNameForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={editProjectAssetName}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
    />
  )
}

const ProjectAssetNameEditDialog: React.FC<ProjectAssetNameEditDialogProps> = ({
  projectId,
  assetId,
  name,
  updateQuery,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <TitleForm
      {...props}
      projectId={projectId}
      assetId={assetId}
      name={name}
      updateQuery={updateQuery}
    />
  )

  return (
    <DialogWithAlert
      dialogTitle="Edit asset title"
      alertHeader="Cancel Editing Title"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectAssetNameEditDialog
