import { gql } from '@apollo/client'

export const editPortfolioMutation = gql`
  mutation editPortfolio($portfolioId: ID!, $name: String) {
    editPortfolio(portfolioId: $portfolioId, name: $name) {
      id
      name
    }
  }
`
