/* eslint-disable import/no-default-export */
import React from 'react'
import { IonCol, IonRow } from '@ionic/react'
import styled from 'styled-components'

import { PortfolioDataProps } from '../../pages/PortfolioPage/PortfolioPage.types'
import { ProjectsList } from '../ProjectsList'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { PortfolioDesktopControls } from '../PortfolioDesktopControls'

const StyledIonRow = styled(IonRow)`
  max-width: 1440px;
  margin: 0 auto;
`

const StyledCenterCol = styled(IonCol)`
  margin: 0 auto;
`

const PortfolioData: React.FC<PortfolioDataProps> = ({
  portfolio,
  loading,
}) => {
  const { isMobile } = useDisplaySize()

  const hasProjects = portfolio.projects && portfolio.projects.length !== 0

  return (
    <StyledIonRow className="ion-justify-content-center">
      <StyledCenterCol>
        {(hasProjects || loading) && (
          <ProjectsList projectList={portfolio!.projects!} loading={loading} />
        )}
        {!(hasProjects || loading) && <p>This portfolio has no projects</p>}
      </StyledCenterCol>

      {!isMobile && (
        <IonCol size="auto">
          {portfolio && <PortfolioDesktopControls portfolio={portfolio} />}
        </IonCol>
      )}
    </StyledIonRow>
  )
}

export default PortfolioData
