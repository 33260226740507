/* eslint-disable import/no-default-export */
import { IonGrid, IonPage } from '@ionic/react'
import styled from 'styled-components'
import { Header } from '../../components/Header'
import { ProjectMobileControls as MobileControls } from '../../components/ProjectMobileControls'
import { UserProfileEdit } from '../../components/UserProfile'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { usePersonalProfile } from './UserProfilePage.hooks'

const StyledIonGrid = styled(IonGrid)`
  && {
    width: 100%;
    margin: auto;
    max-width: var(--narrow-page-max-size);
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
  }
`

const StyledPageContainer = styled.div`
  overflow: hidden;
  height: 100%;
`

const StyledContent = styled.div`
  overflow: scroll;
  height: 100%;
  padding-top: 60px;
  max-width: var(--max-width);
`

const UserProfilePage = () => {
  const { user } = usePersonalProfile()
  const { isMobile } = useDisplaySize()

  return (
    <IonPage data-testid="UserProfilePage">
      <StyledPageContainer>
        <Header />
        <StyledContent>
          <StyledIonGrid>
            {user && <UserProfileEdit user={user} />}
          </StyledIonGrid>
          {isMobile && <MobileControls />}
        </StyledContent>
      </StyledPageContainer>
    </IonPage>
  )
}

export default UserProfilePage
