import { useEffect } from 'react'
import { useDisplaySize } from './useDisplaySize'

function setIntercomVisibility(visible: boolean = true) {
  // @ts-ignore
  if (window.Intercom) {
    // @ts-ignore
    window.Intercom('update', {
      hide_default_launcher: !visible,
    })
  }
}

export function useMobileIntercomDisabled() {
  const { isMobile } = useDisplaySize()

  useEffect(() => {
    if (isMobile) {
      setIntercomVisibility(false)
      return () => {
        setIntercomVisibility(true)
      }
    }
    return undefined
  }, [isMobile])
}

export function useIntercomDisabled() {
  const { isMobile } = useDisplaySize()

  useEffect(() => {
    // exclude mobile view, in order not to coalesce with useMobileIntercomDisabled
    if (!isMobile) {
      setIntercomVisibility(false)
      return () => {
        setIntercomVisibility(true)
      }
    }
    return undefined
  }, [isMobile])
}
