/* eslint-disable import/no-default-export */
import { IonSkeletonText } from '@ionic/react'
import styled from 'styled-components'
import { StyledIonCard } from '../ui/Card/MediaItemCard.component'
import { FlexBox } from '../ui/FlexBox'

const ImageSkeleton = styled(IonSkeletonText)`
  height: 150px;
  margin: 0;
`

const ContentContainer = styled.div`
  padding: 10px 15px 15px;
`

const HeaderSkeleton = styled(IonSkeletonText)<{ width?: number }>`
  width: ${({ width = 100 }) => width}%;
  height: 17px;
  line-height: 24px;
`

const PillSkeleton = styled(IonSkeletonText)`
  margin: 11px 0;
  height: 22px;
  width: 30%;
  border-radius: 11px;
`

const AvatarSkeleton = styled(IonSkeletonText)`
  height: 22px;
  width: 22px;
  border-radius: 11px;
  margin-left: -7px;
`

const ProjectInfoCardSkeleton = () => (
  <StyledIonCard>
    <ImageSkeleton animated />
    <ContentContainer>
      <HeaderSkeleton animated />
      <HeaderSkeleton animated width={80} />
      <FlexBox direction="row" justifyContent="space-between">
        <PillSkeleton animated />
        <FlexBox direction="row">
          <AvatarSkeleton animated />
          <AvatarSkeleton animated />
          <AvatarSkeleton animated />
        </FlexBox>
      </FlexBox>
    </ContentContainer>
  </StyledIonCard>
)

export default ProjectInfoCardSkeleton
