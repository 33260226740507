/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import { Button } from '../ui/Button'
import { FlexBox } from '../ui/FlexBox'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ProjectAddDialogHookProps } from '../ProjectDialog/ProjectDialog.types'
import { useProjectListContext } from '../../contexts/projectList'
import ProjectAddDialog from '../ProjectDialog/ProjectAddDialog.component'
import { useMeQuery } from '../ui/UserInfo/UserInfo.hooks'
import BookScanButton from '../ui/BookScanButton/BookScanButton.component'
import { effectiveOrganisationPermissions } from '../../helpers/casl'
import { useOrganisationContext } from '../../contexts/organisation'

const StyledAside = styled.div`
  margin-top: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 68px;
  width: 198px;
  margin-left: auto;
  padding-left: 10px;
`

const AddButton = styled(Button)`
  && {
    ::part(native) {
      padding-right: 14px;
      padding-left: 14px;
    }
  }
`

const ProjectListDesktopControls = () => {
  const { organisationId } = useOrganisationContext()
  const { updateQuery } = useProjectListContext()
  const { user } = useMeQuery()

  const permissions = effectiveOrganisationPermissions(user, organisationId)

  const [addProjectDialogPresent] = useDialog<ProjectAddDialogHookProps>(
    ProjectAddDialog,
    {
      updateQuery,
    },
  )

  return (
    <StyledAside data-testid="ProjectDesktopControls">
      <FlexBox alignItems="stretch">
        <BookScanButton />
        {permissions.includes('CreateProject') && (
          <AddButton onClick={addProjectDialogPresent}>+ Add Project</AddButton>
        )}
      </FlexBox>
    </StyledAside>
  )
}

export default ProjectListDesktopControls
