/* eslint-disable import/no-default-export */
import { IonLabel } from '@ionic/react'
import styled from 'styled-components'
import { AvatarStack } from '../ui/AvatarStack'
import { FlexBox } from '../ui/FlexBox'
import { ProjectUsersProps } from './ProjectUsers.types'

const StyledLabel = styled(IonLabel)`
  && {
    font-weight: bold;
    font-size: 16px;
    align-self: flex-start;

    margin-top: 52px;
    margin-bottom: 16px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);

    @media (max-width: 768px) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
`

const ProjectUsers = ({
  users,
  usersCount,
  onClick,
  title = 'Project Team',
}: ProjectUsersProps) => (
  <FlexBox alignItems="flex-start" dataTestid="ProjectUsers">
    <StyledLabel>{title}</StyledLabel>
    <AvatarStack users={users} itemsCount={usersCount} onClick={onClick} />
  </FlexBox>
)

export default ProjectUsers
