import * as yup from 'yup'
import { passwordValidator } from '@synctech/portal-types'
import { RegisterFormFields } from './RegisterPage.types'

export const validationSchema = yup.object({
  name: yup.string().required('Name is required').min(3).nullable(),
  // eslint-disable-next-line newline-per-chained-call
  email: yup.string().email().required('Email is required').max(255).nullable(),
  password: passwordValidator.label('New Password'),
  confirm: yup
    .string()
    .oneOf([yup.ref('password'), undefined], "Passwords Don't Match")
    .required('Password Confirmation is required')
    .nullable(),
})

export const initialValues: RegisterFormFields = {
  name: null,
  password: null,
  confirm: null,
  email: null,
}
