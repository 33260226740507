import { ProjectStatus } from '@synctech/portal-types'

export const ProjectStatusDecoration = {
  [ProjectStatus.PENDING]: {
    color: '#939393',
    bgColor: '#F5F5F5',
    progress: 0,
  },
  [ProjectStatus.NEW]: {
    color: '#939393',
    bgColor: '#F5F5F5',
    progress: 0,
  },
  [ProjectStatus.INSPECTION]: {
    color: '#EB8D4B',
    bgColor: '#EB8D4B26',
    progress: 25,
  },
  [ProjectStatus.PRODUCTION]: {
    color: '#EB8D4B',
    bgColor: '#EB8D4B26',
    progress: 50,
  },
  [ProjectStatus.DELIVERED]: {
    color: '#EB8D4B',
    bgColor: '#EB8D4B26',
    progress: 75,
  },
  [ProjectStatus.COMPLETE]: {
    color: '#25B2A6',
    bgColor: '#25B2A626',
    progress: 100,
  },
  [ProjectStatus.CANCELLED]: {
    color: '#939393',
    bgColor: '#F5F5F5',
    progress: 100,
  },
  [ProjectStatus.DECLINED]: {
    color: '#939393',
    bgColor: '#F5F5F5',
    progress: 100,
  },
  [ProjectStatus.HOLD]: {
    color: '#EE3344',
    bgColor: '#EE334426',
    progress: 100,
  },
  [ProjectStatus.ARCHIVED]: {
    color: '#939393',
    bgColor: '#F5F5F5',
    progress: 100,
  },
}
