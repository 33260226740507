/* eslint-disable import/no-default-export */
import { IonFabButton, IonIcon, IonLabel } from '@ionic/react'
import { add, search, homeOutline } from 'ionicons/icons'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { FlexBox } from '../ui/FlexBox'
import { Popover } from '../ui/Popover'
import { usePopoverContext } from '../../contexts/popover'
import { ProjectMobileControlsProps } from './ProjectMobileControls.types'

const StyledIonBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px -2px 4px rgb(0 0 0 / 25%);
  height: 82px;
  z-index: 200;
`

const StyledIonLabel = styled(IonLabel)`
  margin-top: 4px;
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  height: 100%;
  width: 100%;
  font-size: 10px;
`

const LeftLink = styled(StyledNavLink)`
  margin-right: 40px;
  color: rgba(0, 0, 0);
`

const RightLink = styled(StyledNavLink)`
  margin-left: 40px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
`

const addPopoverCss = css`
  && {
    .popover-content {
      width: 335px;
      transform: translateY(-10px);
    }
  }
`

const TriggerAddButton = () => {
  const { openPopover } = usePopoverContext()
  // TODO figure out why stylings by styled-components break popover positioning
  const style = {
    position: 'absolute',
    width: '80px',
    height: '80px',
    border: '8px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '40px',
    top: '-40px',
  }

  return (
    <IonFabButton style={style} color="syncred" onClick={openPopover}>
      <IonIcon
        style={{
          pointerEvents: 'none',
        }}
        icon={add}
      />
    </IonFabButton>
  )
}

const ProjectMobileControls = ({ menuItems }: ProjectMobileControlsProps) => {
  const popoverContent = menuItems ? menuItems() : null

  return (
    <StyledIonBottom slot="fixed">
      <FlexBox
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        fullHeight
        dataTestid="ProjectMobileControls"
      >
        <LeftLink to="/projects">
          <FlexBox fullHeight>
            <IonIcon icon={homeOutline} size="small" />
            <StyledIonLabel>Home</StyledIonLabel>
          </FlexBox>
        </LeftLink>

        {popoverContent && (
          <Popover css={addPopoverCss} trigger={TriggerAddButton}>
            {popoverContent}
          </Popover>
        )}

        <RightLink to="/search">
          <FlexBox fullHeight>
            <IonIcon icon={search} size="small" />
            <StyledIonLabel>Search</StyledIonLabel>
          </FlexBox>
        </RightLink>
      </FlexBox>
    </StyledIonBottom>
  )
}

export default ProjectMobileControls
