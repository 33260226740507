import { useQuery } from '@apollo/client'
import { getAutodeskTokenQuery } from './ProjectBimAutodeskAssetsDialog.graphql'

export const useAutodeskToken = () => {
  const { data, loading, error, updateQuery, refetch } = useQuery(
    getAutodeskTokenQuery,
    { fetchPolicy: 'network-only' },
  )

  return {
    token: data?.autodeskToken?.token,
    expires: data?.autodeskToken?.expires,
    error,
    loading,
    updateQuery,
    refetch,
  }
}
