/* eslint-disable */
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { useEffect, useState } from 'react'
import { cloudDownloadOutline, refreshOutline } from 'ionicons/icons'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Dialog } from '../ui/Dialog'
import { ImagePreviewDialogProps } from './ImagePreviewDialog.types'
import { FlexBox } from '../ui/FlexBox'
import { Button } from '../ui/Button'
import { useAssetQuery } from './ImagePreviewDialog.hooks'

const StyledFlexBox = styled(FlexBox)`
  margin-bottom: 15px;
`

const StyledImageFlexBox = styled(FlexBox)`
  height: 60vh;
`

const ImagePreviewDialog = ({
  requestedId,
  projectId,
  imageList,
  onDismiss,
}: ImagePreviewDialogProps) => {
  const [rotationAngles, setRotationAngles] = useState(imageList.map(() => 0))
  const [currentImageIndex, setCurrentImageIndex] = useState(requestedId)

  const { asset } = useAssetQuery(imageList[currentImageIndex].id, projectId)

  const onImageChange = (index: number) => {
    setCurrentImageIndex(index)
  }

  const rotateImage = () => {
    setRotationAngles((prevAngles) =>
      prevAngles.map((item, index) =>
        index == currentImageIndex ? (item - 90) % 360 : item,
      ),
    )
  }

  const onKeyDownEventListener = (event: KeyboardEvent) => {
    switch (event.code) {
      case 'ArrowLeft':
        setCurrentImageIndex((idx) => (idx > 0 ? idx - 1 : idx))
        break
      case 'ArrowRight':
        setCurrentImageIndex((idx) =>
          idx < imageList.length - 1 ? idx + 1 : idx,
        )
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDownEventListener)
    return () => {
      window.removeEventListener('keydown', onKeyDownEventListener)
    }
  }, [])

  return (
    <Dialog
      title={imageList[currentImageIndex].name || 'Image'}
      onDismiss={onDismiss}
      cssClass="sync-fullscreen-modal"
    >
      <Carousel
        selectedItem={currentImageIndex}
        autoFocus
        emulateTouch
        onChange={onImageChange}
        showIndicators={false}
      >
        {imageList.map((image, index) => (
          <StyledImageFlexBox justifyContent="center" alignItems="center">
            <img
              src={image.value}
              alt={image.name}
              style={{
                maxHeight:
                  Math.abs(rotationAngles[index]) % 180 !== 0
                    ? undefined
                    : '60vh',
                maxWidth:
                  Math.abs(rotationAngles[index]) % 180 !== 0
                    ? '60vh'
                    : undefined,
                width:
                  Math.abs(rotationAngles[index]) % 180 !== 0
                    ? undefined
                    : 'auto',
                height:
                  Math.abs(rotationAngles[index]) % 180 !== 0
                    ? 'auto'
                    : undefined,
                rotate: `${rotationAngles[index]}deg`,
              }}
            />
          </StyledImageFlexBox>
        ))}
      </Carousel>
      <StyledFlexBox direction="row" gap={15}>
        <Button onClick={rotateImage}>
          <IonIcon
            slot="start"
            icon={refreshOutline}
            style={{ transform: 'scaleX(-1)' }}
          />
          Rotate
        </Button>
        <Button href={asset.fullSizeUrl} target="_blank">
          <IonIcon slot="start" icon={cloudDownloadOutline} />
          Open Original
        </Button>
      </StyledFlexBox>
    </Dialog>
  )
}

export default ImagePreviewDialog
