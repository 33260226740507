/* eslint-disable import/no-default-export */
import { detect } from 'detect-browser'
import styled from 'styled-components'

const StyledDiv = styled.div`
  text-align: center;
  padding: 10px 35px;
  margin: -10px -25px 10px -25px;
  background-color: var(--ion-color-warning);
`

const ObsoleteBrowserBanner = () => {
  const browser = detect()
  switch (browser && browser.name) {
    case 'chrome':
      if (browser?.version && parseInt(browser.version, 10) > 120) {
        return null
      }
      break
    case 'edge-chromium':
      if (browser?.version && parseInt(browser.version, 10) > 120) {
        return null
      }
      break
    case 'firefox':
      if (browser?.version && parseInt(browser.version, 10) > 122) {
        return null
      }
      break
    default:
      console.log('browser is not supported')
  }
  return (
    <StyledDiv>
      You are using an out-of-date or unsupported browser. Certain features may
      not work as intended. Please ensure you are using the latest versions of
      either Google Chrome or Mozilla Firefox
    </StyledDiv>
  )
}

export default ObsoleteBrowserBanner
