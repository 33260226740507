/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const editUserProfileQuery = gql`
  mutation editUserProfile($name: String!, $email: String!, $phone: String) {
    editUserProfile(name: $name, email: $email, phone: $phone) {
      id
    }
  }
`

export const deleteUserAvatarQuery = gql`
  mutation deleteUserAvatar {
    deleteUserAvatar {
      id
    }
  }
`

export const updateUserAvatarQuery = gql`
  mutation updateUserAvatar($file: Upload!) {
    updateUserAvatar(file: $file) {
      id
    }
  }
`
