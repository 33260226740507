import { useMutation } from '@apollo/client'
import { useNotification } from '../../contexts/notification'
import { updateProjectPublicItemsMutation } from './ProjectSharedLinkAccessDialog.graphql'

export const useUpdateProjectPublicItems = (
  projectId: string,
  onDismiss: () => void,
) => {
  const [updateProjectPublicItemsRequest, { loading }] = useMutation(
    updateProjectPublicItemsMutation,
  )

  const { notify, notifyError } = useNotification()

  const updatePublicItems = async (description: boolean, assets: string[]) => {
    try {
      await updateProjectPublicItemsRequest({
        variables: {
          projectId,
          description,
          assets,
        },
      })
      notify('Visible assets updated successfully')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      notifyError(`Visible assets update failed. ${error.message}`)
    }
  }

  return {
    updatePublicItems,
    loading,
  }
}
