import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

const redColor = css`
  color: var(--sync-dark-gray);
  font-weight: bold;
`

const blackColor = css`
  color: black;
  font-weight: bold;
`

export const StyledAnchor = styled.a`
  white-space: nowrap;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  ${(props) => (props.color === 'black' ? blackColor : redColor)};
`

const StyledLink = styled(RouterLink)`
  white-space: nowrap;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  ${(props) => (props.color === 'black' ? blackColor : redColor)};
`

export default StyledLink
