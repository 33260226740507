/* eslint-disable import/no-default-export */
import React from 'react'
import { IonItem } from '@ionic/react'
import styled, { css } from 'styled-components'
import { usePopoverContext } from '../../../contexts/popover'
import { SubmenuPopoverItemProps } from './SubmenuPopoverItem.types'
import { Popover } from '../Popover'

const StyledIonItem = styled(IonItem)`
  && {
    font-size: 16px;
    font-weight: 700;
    --background-hover: var(--sync-light-blue);
    --background-hover-opacity: 1;
    --background-activated: var(--sync-light-blue);
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`

const menuItemCss = css`
  && {
    .popover-content {
      transform: translateX(-232px) translateY(-40px);
    }
    --width: 230px;
  }
`

const SubmenuTriggerItem = ({ title }: { title: string }) => {
  const { openPopover } = usePopoverContext()
  return (
    <StyledIonItem
      lines="none"
      button
      style={{ '--inner-padding-end': '6px' }}
      onClick={openPopover}
    >
      {title}
    </StyledIonItem>
  )
}

const SubmenuPopoverItem: React.FC<SubmenuPopoverItemProps> = ({
  children,
  title,
  ...rest
}) => (
  <Popover
    css={menuItemCss}
    trigger={() => SubmenuTriggerItem({ title })}
    {...rest}
  >
    {children}
  </Popover>
)

export default SubmenuPopoverItem
