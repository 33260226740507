// Helper function to extract readable for humans role
import { ProjectRoleIds, projectRoles } from './project'
import { PortfolioRoleIds, portfolioRoles } from './portfolio'

/**
 * Helper function to generate human-readable role from role id
 *
 * Supports both project and portfolio roles. For portfolio roles you need to
 * pass portfolioRoles as second argument.
 * @param roleId
 * @param roleSource
 */
export const getHumanReadableRole = (
  roleId: ProjectRoleIds | PortfolioRoleIds,
  roleSource: typeof projectRoles | typeof portfolioRoles = projectRoles,
) => {
  // check if role is defined
  if (roleId in roleSource) {
    return [...roleSource[roleId]]
      .map((char: string, i: number) => {
        // Capitalize first character
        if (i === 0) {
          return char.toUpperCase()
        }
        // If char is capitalized - add empty space before it
        return char === char.toUpperCase() ? ` ${char}` : char
      })
      .join('')
  }
  return 'Unknown Role'
}
