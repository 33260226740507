/* eslint-disable import/no-default-export */
import { IonCol, IonItem, IonRow } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import { ProjectInfoCard, ProjectInfoCardSkeleton } from '../ProjectInfoCard'
import { Title } from '../ui/Title'
import { ProjectListProps } from './ProjectList.types'
import { OrderControls } from '../OrderControls'
import { FlexBox } from '../ui/FlexBox'

const StyledCenterCol = styled(IonCol)`
  max-width: 798px;
  margin: 0 auto;
`

const StyledTitle = styled(Title)`
  @media (max-width: 768px) {
    margin-top: 0 !important;
  }
`

const StyledIonRow = styled(IonRow)`
  margin: 0 -5px;
`

const SkeletonSet = React.memo(() => (
  <>
    {[1, 2, 3, 4, 5, 6].map((num) => (
      <IonCol key={num} size="6" sizeXl="4">
        <ProjectInfoCardSkeleton />
      </IonCol>
    ))}
  </>
))

const AuxContent: React.VFC<{ loading: boolean }> = ({ loading }) =>
  loading ? (
    <SkeletonSet />
  ) : (
    <IonItem lines="none" className="ion-justify-content-center">
      No projects
    </IonItem>
  )

const ProjectList: React.FC<ProjectListProps> = ({ projectList, loading }) => (
  <StyledCenterCol>
    <FlexBox direction="row" justifyContent="flex-start" alignItems="baseline">
      <div style={{ flexGrow: 2 }}>
        <StyledTitle>Projects</StyledTitle>
      </div>
      <OrderControls />
    </FlexBox>
    <StyledIonRow data-testid="ProjectsList">
      {projectList?.length ? (
        projectList.map((project) => (
          <IonCol key={project.id} size="6" sizeXl="4">
            <ProjectInfoCard project={project} />
          </IonCol>
        ))
      ) : (
        <AuxContent loading={loading} />
      )}
    </StyledIonRow>
  </StyledCenterCol>
)

export default ProjectList
