/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import { IonSelect } from '@ionic/react'

const StyledIonSelect = styled(IonSelect)`
  && {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    border-radius: 8px;
    --border-radius: 8px;
    --padding-start: 10.5px;
    --padding-end: 6px;
    --padding-left: 16px;

    border: 1px solid transparent;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    min-height: 38px;

    &.error {
      box-shadow: 0 0 4px #e34148;
    }

    &:focus {
      box-shadow: none;
      border: 1px solid var(--sync-blue);
    }
  }
`

export default StyledIonSelect
