/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const changePortfolioUserRole = gql`
  mutation changePortfolioUserRole(
    $portfolioId: ID!
    $userId: ID!
    $newRoleId: Int!
  ) {
    changePortfolioUserRole(
      portfolioId: $portfolioId
      userId: $userId
      newRoleId: $newRoleId
    ) {
      id
    }
  }
`
