/* eslint-disable import/no-default-export */
import React from 'react'
import { useDialogContext } from '../../../contexts/dialog'

const DialogRoot = () => {
  const { activeDialog } = useDialogContext()

  if (!activeDialog) {
    return null
  }

  return React.createElement(
    activeDialog.component,
    activeDialog.componentProps,
    null,
  )
}

export default DialogRoot
