export interface PortfolioUserAddFieldProps {
  portfolioId: number
  permissions: string[]
}

// interface and factory to manage onChange function for react-select
export interface Option {
  readonly label: string
  readonly value: string
}

// factory for Option type
export const createOption = (label: string) => ({
  label,
  value: label,
})
