/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import { IonTextarea } from '@ionic/react'

const StyledIonTextarea = styled(IonTextarea)`
  && {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    border-radius: 8px;
    --border-radius: 8px;
    --padding-start: 10.5px;
    --padding-left: 16px;

    label {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      border: 1px solid transparent;
    }

    &.error label {
      box-shadow: 0 0 4px #e34148;
    }

    label:focus-within {
      box-shadow: none;
      border: 1px solid var(--sync-blue);
    }
  }
`

export default StyledIonTextarea
