/* eslint-disable import/no-default-export */
import {
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonItem,
  IonText,
} from '@ionic/react'
import { downloadOutline } from 'ionicons/icons'
import styled from 'styled-components'
import { CardEditButton } from '../CardEditButton'
import { MediaItemCardProps } from './MediaItemCard.types'
import ShadowCard from './ShadowCard.component'

export const StyledIonCard = styled(ShadowCard)`
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledIonCardContent = styled(IonCardContent)`
  && {
    padding: 10px 5px 15px 15px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    height: 100%;
    @media (max-width: 768px) {
      padding: 5px 0 10px 10px;
    }
  }
`

const StyledIonCardHeader = styled(IonCardHeader)`
  flex-grow: 2;
`
const StyledIonText = styled(IonText)`
  font-weight: bold;
  line-height: 24px !important;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: black;
    text-decoration: none;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px !important;
    a {
      font-size: 12px;
    }
  }
`

const StyledIonItem = styled(IonItem)`
  --inner-padding-end: 0;
  flex-grow: 2;
  ::part(native) {
    min-height: 10px;
  }
`

const StyledIonIcon = styled(IonIcon)`
  color: #393939;
  margin: 0 0 0 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 14px;
  }
`

const downloadAsset = (downloadUrl: string) => {
  const a = window.document.createElement('a')
  a.href = downloadUrl
  a.click()
  a?.parentNode?.removeChild(a)
}

const MediaItemCard = ({
  children,
  bottomSlot,
  title,
  editMenu,
  downloadUrl,
}: MediaItemCardProps) => (
  <StyledIonCard>
    {children}
    <StyledIonCardContent>
      <StyledIonCardHeader className="ion-no-padding">
        <StyledIonItem lines="none" className="ion-no-padding">
          <StyledIonText>{title}</StyledIonText>
          {downloadUrl && (
            <StyledIonIcon
              slot="end"
              icon={downloadOutline}
              onClick={() => downloadAsset(downloadUrl)}
            />
          )}
          <CardEditButton editMenu={editMenu} />
        </StyledIonItem>
      </StyledIonCardHeader>
      {bottomSlot && bottomSlot()}
    </StyledIonCardContent>
  </StyledIonCard>
)
export default MediaItemCard
