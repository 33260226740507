/* eslint-disable import/no-default-export */
import React from 'react'
import { IonCol, IonRow, IonSkeletonText, IonText } from '@ionic/react'
import styled from 'styled-components'
import { PortfolioInfoProps } from '../../pages/PortfolioPage/PortfolioPage.types'

const StyledAddressIonText = styled(IonText)`
  font-size: 40px;
  @media (max-width: 992px) {
    font-size: 18px;
    margin-top: 24px;
    font-weight: bold;
  }
`

const StyledAddressIonCol = styled(IonCol)`
  @media (max-width: 992px) {
    margin-top: 24px;
  }
`

const AddressSkeleton = styled(IonSkeletonText)`
  font-size: 40px;
  height: 50px;
  width: 50%;
  @media (max-width: 992px) {
    font-size: 18px;
    margin-top: 24px;
    font-weight: bold;
  }
`

const PortfolioInfo: React.FC<PortfolioInfoProps> = ({
  portfolio,
  loading,
}) => (
  <IonRow
    className="ion-justify-content-center  ion-align-items-center"
    data-testid="PortfolioInfo"
  >
    <StyledAddressIonCol size="12" sizeLg="12">
      {loading ? (
        <AddressSkeleton animated />
      ) : (
        <StyledAddressIonText>{portfolio?.name}</StyledAddressIonText>
      )}
    </StyledAddressIonCol>
  </IonRow>
)

export default PortfolioInfo
