/* eslint-disable import/no-default-export */
import React, { useState } from 'react'
import { IonAlert, IonItem } from '@ionic/react'
import styled from 'styled-components'
import { usePopoverContext } from '../../../contexts/popover'
import { PopoverItemWithAlertProps } from './PopoverItemWithAlert.types'

const StyledIonItem = styled(IonItem)`
  && {
    font-size: 16px;
    font-weight: 700;
    --background-hover: var(--sync-light-blue);
    --background-hover-opacity: 1;
    --background-activated: var(--sync-light-blue);
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`
// TODO figure out why styled component won't apply
// const StyledIonAlert = styled(IonAlert)``

const PopoverItem: React.FC<PopoverItemWithAlertProps> = ({
  children,
  header,
  message,
  confirmButtonText = 'Ok',
  onClick = () => {},
  disabled = false,
  globalSpinner = false,
  ...rest
}) => {
  const { closePopover } = usePopoverContext()
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const onAlertConfirmKeepMenu = async () => {
    await onClick()
    await closePopover()
  }

  const onAlertConfirmHideMenu = async () => {
    await closePopover()
    await onClick()
  }

  const openAlert = () => {
    if (disabled) {
      return
    }
    setAlertOpen(true)
  }
  const closeAlert = () => setAlertOpen(false)

  return (
    <>
      <StyledIonItem
        lines="none"
        detail={false}
        button
        disabled={disabled}
        onClick={openAlert}
        {...rest}
        data-testid="PopoverItemWithAlert"
      >
        {children}
      </StyledIonItem>
      <IonAlert
        isOpen={alertOpen}
        header={header}
        message={message}
        cssClass="sync-alert"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: closeAlert,
          },
          {
            text: confirmButtonText,
            handler: globalSpinner
              ? onAlertConfirmHideMenu
              : onAlertConfirmKeepMenu,
            cssClass: 'alert-button__confirm',
          },
        ]}
      />
    </>
  )
}

export default PopoverItem
