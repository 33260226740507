import { gql } from '@apollo/client'

export const addProjectLinkMutation = gql`
  mutation addProjectLink($projectId: ID!, $name: String, $value: String) {
    addProjectAsset(
      projectId: $projectId
      type: url
      name: $name
      value: $value
    ) {
      id
      type
      name
      value
    }
  }
`
