import { gql } from '@apollo/client'
import { ProjectAssetsTypes } from '../../pages/ProjectPage/ProjectPage.types'

export const addFileAssetMutation = gql`
  mutation addProjectAsset(
    $projectId: ID!
    $type: ProjectAssetsTypes
    $name: String
    $file: Upload
  ) {
    addProjectAsset(
      projectId: $projectId
      type: $type
      name: $name
      file: $file
    ) {
      id
      type
      name
      value
      ... on ImageAsset {
        fullSizeUrl
      }
    }
  }
`

export const addVideoAssetMutation = gql`
  mutation addProjectLink($projectId: ID!, $name: String, $value: String) {
    addProjectAsset(
      projectId: $projectId
      type: ${ProjectAssetsTypes.VIDEO}
      name: $name
      value: $value
    ) {
      id
      type
      name
      value
    }
  }
`
