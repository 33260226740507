import { useMutation, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import mixpanel from '../../../helpers/tracking'
import { logoutMutation, meQuery } from './UserInfo.graphql'
import { UserQueryResult } from './UserInfo.types'

export const useMeQuery = (): UserQueryResult => {
  const { data, loading } = useQuery(meQuery)

  return {
    user: {
      ...(data && data.me),
    },
    loading,
  }
}

export const useLogoutMutation = () => {
  const [logout, { client }] = useMutation(logoutMutation)

  const onLogout = async () => {
    await logout()
    mixpanel.reset()
    Sentry.configureScope((scope) => scope.setUser(null))
    client.clearStore()
  }

  return {
    logout: onLogout,
  }
}
