/* eslint-disable import/no-default-export */
import React from 'react'
import { FlexBoxProps } from './FlexBox.types'

const FlexBox: React.FC<FlexBoxProps> = ({
  direction = 'column',
  grow = 0,
  alignItems = 'center',
  alignSelf = 'auto',
  justifyContent = 'center',
  className,
  fullWidth,
  fullHeight,
  children,
  gap = 'normal normal',
  dataTestid = 'FlexBox',
}) => (
  <div
    className={className}
    style={
      {
        display: 'flex',
        flexDirection: direction,
        flexGrow: grow,
        alignItems,
        alignSelf,
        justifyContent,
        gap,
        ...(fullWidth && { width: '100%' }),
        ...(fullHeight && { height: '100%' }),
      } as React.CSSProperties
    }
    data-testid={dataTestid}
  >
    {children}
  </div>
)

export default FlexBox
