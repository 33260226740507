import React, { createContext, useContext } from 'react'

interface ProjectContext {
  projectId: string
  updateQuery: any
}

const Context = createContext<ProjectContext>({
  projectId: '0',
  updateQuery: () => {},
})

export const useProjectContext = () => useContext<ProjectContext>(Context)

export const ProjectProvider: React.FC<{
  children: React.ReactNode
  value: ProjectContext
}> = ({ children, value }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
)
