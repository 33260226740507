import { gql } from '@apollo/client'

export const editProjectAssetNameMutation = gql`
  mutation editProjectAsset($projectId: ID!, $assetId: ID!, $name: String!) {
    editProjectAsset(projectId: $projectId, assetId: $assetId, name: $name) {
      id
      type
      name
      value
    }
  }
`
