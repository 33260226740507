import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import mixpanel from '../helpers/tracking'

export const withPageTracker =
  (Component: any, pageName: pageNames) => (props: any) => {
    const location = useLocation()
    const params = useParams()

    useEffect(() => {
      mixpanel.track('FE - Page View', {
        url: location.pathname,
        page_name: pageName,
        ...(params && params),
      })
    }, [location])

    return <Component {...props} />
  }

export const withCallbackTracker =
  (callback: Function, eventName: string, eventPayload: any) =>
  (...args: any) => {
    mixpanel.track(eventName, eventPayload)
    callback(...args)
  }

export enum pageNames {
  // Authorized
  PROJECT_DETAILS = 'Project Details',
  PROJECT_SHARED = 'Project Shared View',
  SHARED_ASSET = 'Shared Asset Page',
  PROJECTS_LISTING = 'Project Listing',
  PORTFOLIO_DETAILS = 'Portfolios Details',
  PORTFOLIOS_LISTING = 'Portfolios Listing',
  MY_PROFILE = 'My Profile Page',
  USER_PROFILE = 'User’s Profile Page',
  //  Unauthorized
  LOGIN = 'Login',
  FORGOT_PASSWORD = 'Forgot Password',
  REGISTER = 'Register by Email Invite',
  RESET_PASSWORD = 'Reset Password',
}
