/* eslint-disable import/no-default-export */
import { IonImg } from '@ionic/react'
import styled from 'styled-components'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ThumbnailedAsset } from '../../pages/ProjectPage/ProjectPage.types'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { MediaItemCard } from '../ui/Card'
import { SelfHostedAssetsMediaCardProps } from './ProjectDigitalTwinAssets.types'
import placeholder from '../../assets/placeholder_captur3d.png'
import { withCallbackTracker } from '../../hocs/withTracking'

const StyledImg = styled(IonImg)`
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 185px;

  @media (max-width: 768px) {
    height: 100px;
  }
`

const SelfHostedAssetsFileCard = ({
  selfHostedTour,
}: SelfHostedAssetsMediaCardProps) => {
  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const downloadAsset = () => {
    const a = window.document.createElement('a')
    a.href = selfHostedTour.value
    a.click()
    a?.parentNode?.removeChild(a)
  }

  const editMenu = project.permissions.includes('DeleteProjectAssets')
    ? (asset: ThumbnailedAsset) => <AssetPopoverMenu asset={asset} />
    : () => null
  return (
    <MediaItemCard
      editMenu={() => editMenu(selfHostedTour)}
      title={selfHostedTour.name}
    >
      <StyledImg
        onClick={withCallbackTracker(downloadAsset, 'FE - Asset View', {
          Asset_Type: 'Self Hosted Tour - Download',
          Asset_Name: selfHostedTour.name,
          Project_ID: projectId,
          Project_Name: project.title,
          Project_Address: project.address,
        })}
        src={selfHostedTour.previewUrl || placeholder}
        alt={selfHostedTour.name}
      />
    </MediaItemCard>
  )
}

export default SelfHostedAssetsFileCard
