/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import {
  FileAssetsUploadFormProps,
  UploadFile,
} from './FileAssetsUploadForm.types'
import { Button } from '../ui/Button'
import { FileUpload } from '../FileUpload'
import { FlexBox } from '../ui/FlexBox'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { acceptMap } from '../../pages/ProjectPage/ProjectPage.constants'
import { MAX_SIMULTANEOUS_UPLOADS } from './FileAssetsUploadForm.constants'

const HiddenInput = styled.input`
  display: none;
`

const StyledButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 5px 20px 20px;
  background-color: white;
`

const StyledFlexBox = styled(FlexBox)`
  > * {
    flex: 1;
    margin: 4px;
    max-width: 50%;
    @media (max-width: 768px) {
      height: 32px;
      max-width: 100%;
    }
  }
`

const StyledWrapper = styled.div`
  margin-bottom: 60px;
`

const FileAssetsUploadForm: React.FC<FileAssetsUploadFormProps> = ({
  onDismiss,
  type,
  allowedToDismiss,
  setAllowedToDismiss,
}) => {
  const [files, setFiles] = useState<UploadFile[]>([])
  const [count, setCount] = useState<number | undefined>()
  const fileInput = useRef<HTMLInputElement>(null)
  const { isMobile } = useDisplaySize()
  // @ts-ignore
  const accept = acceptMap[type]

  const handleChange = async (event: any) => {
    if (event.target.validity.valid) {
      const newFilesArray = [...event.target.files].map((file: File) => {
        // eslint-disable-next-line no-param-reassign
        ;(file as UploadFile).hold = true
        // eslint-disable-next-line no-param-reassign
        ;(file as UploadFile).done = false
        return file as UploadFile
      })

      setFiles(newFilesArray)
      setCount(newFilesArray.length)
    }
  }

  const updateFileStatuses = () => {
    let simultaneousUploads = 0
    setFiles(
      files.map((file) => {
        if (!file.done && simultaneousUploads < MAX_SIMULTANEOUS_UPLOADS) {
          simultaneousUploads += 1
          // eslint-disable-next-line no-param-reassign
          file.hold = false
        }
        return file
      }),
    )
  }

  useEffect(() => {
    if (!files.length) {
      // Timeout here because modal must end its animation
      setTimeout(() => {
        fileInput?.current?.click()
      }, 500)
    }
  }, [files])

  useEffect(() => {
    if (files.length) {
      setTimeout(updateFileStatuses, 500)
    }
  }, [files])

  useEffect(() => {
    if (count !== undefined && count <= 0) {
      setAllowedToDismiss(true)
    }
  }, [count])

  const onDoneWithFile = (fileName: string) => {
    if (count) {
      setCount((prevCount) => (prevCount as number) - 1)
    }

    setFiles(
      files.map((file) => {
        if (file.name === fileName) {
          // eslint-disable-next-line no-param-reassign
          file.done = true
        }
        return file
      }),
    )
  }

  return (
    <StyledWrapper>
      <HiddenInput
        ref={fileInput}
        onChange={handleChange}
        type="file"
        multiple
        name="files"
        accept={accept}
        data-testid="FileAssetsUploadForm"
      />
      {files.map((file) => (
        <FileUpload
          key={file.name}
          file={file}
          type={type}
          done={onDoneWithFile}
          hold={file.hold}
        />
      ))}
      <StyledButtonsWrapper
        style={{ height: isMobile && !files.length ? '108px' : '66px' }}
      >
        <StyledFlexBox
          fullHeight
          fullWidth
          direction={isMobile ? 'column-reverse' : 'row'}
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          <Button color="light" onClick={onDismiss}>
            {allowedToDismiss ? 'Close' : 'Cancel'}
          </Button>
          {!files.length && (
            <Button
              color="primary"
              onClick={() => {
                fileInput?.current?.click()
              }}
            >
              Add Files
            </Button>
          )}
        </StyledFlexBox>
      </StyledButtonsWrapper>
    </StyledWrapper>
  )
}

export default FileAssetsUploadForm
