import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import {
  deleteUserAvatarQuery,
  editUserProfileQuery,
  updateUserAvatarQuery,
} from './UserProfileEdit.graphql'
import { UserProfileFormFields } from './UserProfile.types'
import { useNotification } from '../../contexts/notification'

export const useEditUserProfile = () => {
  const [editUserProfileRequest, { loading, error }] = useMutation(
    editUserProfileQuery,

    {
      update(cache) {
        // better to reset the entire cache, because we might have this user
        // in the cached project list, project details and project users list queries
        cache.evict({ id: 'ROOT_QUERY' })
        // https://www.apollographql.com/docs/react/caching/garbage-collection/#cacheevict
        // mandatory call for the garbage collector
        cache.gc()
      },
    },
  )

  const { notify } = useNotification()

  const editUserProfile = async (
    values: UserProfileFormFields,
    formikHelpers: FormikHelpers<UserProfileFormFields>,
  ) => {
    try {
      await editUserProfileRequest({
        variables: {
          ...values,
        },
      })

      notify('Your profile is successfully updated')
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return { editUserProfile, loading, error }
}

export const useUpdateUserAvatar = () => {
  const [updateUserAvatarRequest, { loading, error }] = useMutation(
    updateUserAvatarQuery,

    {
      update(cache) {
        // better to reset the entire cache, because we might have this user
        // in the cached project list, project details and project users list queries
        cache.evict({ id: 'ROOT_QUERY' })
        // https://www.apollographql.com/docs/react/caching/garbage-collection/#cacheevict
        // mandatory call for the garbage collector
        cache.gc()
      },
    },
  )

  const { notify, notifyError } = useNotification()

  const updateUserAvatar = async (file: File) => {
    try {
      await updateUserAvatarRequest({
        variables: {
          file,
        },
      })

      notify('Your avatar is successfully updated')
    } catch (error) {
      // @ts-ignore
      notifyError(error.message)
    }
  }

  return { updateUserAvatar, loading, error }
}

export const useDeleteUserAvatar = () => {
  const [deleteUserAvatarRequest, { loading, error }] = useMutation(
    deleteUserAvatarQuery,

    {
      update(cache) {
        // better to reset the entire cache, because we might have this user
        // in the cached project list, project details and project users list queries
        cache.evict({ id: 'ROOT_QUERY' })
        // https://www.apollographql.com/docs/react/caching/garbage-collection/#cacheevict
        // mandatory call for the garbage collector
        cache.gc()
      },
    },
  )

  const { notify, notifyError } = useNotification()

  const deleteUserAvatar = async () => {
    try {
      await deleteUserAvatarRequest()

      notify('Your avatar is removed')
    } catch (error) {
      // @ts-ignore
      notifyError(error.message)
    }
  }

  return { deleteUserAvatar, loading, error }
}
