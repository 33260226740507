/* eslint-disable import/no-default-export */
import { KeyboardEventHandler, useCallback, useEffect, useState } from 'react'
import { IonItem, IonText } from '@ionic/react'
import CreatableSelect from 'react-select/creatable'
import { InputActionMeta, OnChangeValue } from 'react-select'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { ProjectRoleIds, projectRolesMap } from '@synctech/portal-types'
import { Button } from '../../ui/Button'
import { ErrorComponent } from '../../ui/ErrorComponent'
import UserSelectRoleMenu from './UserSelectRoleMenu.component'
import { FlexBox } from '../../ui/FlexBox'
import { useDisplaySize } from '../../../helpers/useDisplaySize'
import RoleInfo from './RoleInfo.component'
import {
  useAddProjectUsers,
  useEmailHints,
  validateEmail,
} from './UserAddField.hooks'
import { createOption, Option, UserAddFieldProps } from './UserAddField.types'
import {
  getCustomComponents,
  RoleEditButton,
  SelectCustomStyles,
  triggerRoleChange,
} from './ReactSelectCustomisation'

const FieldLabelText = styled(IonText)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    flex: 0 1 fit-content;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`

const TitleContainer = styled(IonItem)`
  --min-height: auto;
`

// ************************************
// Main component
// ************************************
const UserAddField = ({ projectId, permissions }: UserAddFieldProps) => {
  const [roleId, setRoleId] = useState<ProjectRoleIds>(projectRolesMap.guest)
  const [emails, setEmails] = useState<readonly Option[]>([])
  const [inputValue, setInputValue] = useState<string>()
  const [error, setError] = useState<string>()
  const [selectOptions, setSelectOptions] = useState<Option[]>([])

  const { isMobile } = useDisplaySize()

  const { addProjectUsers, loading } = useAddProjectUsers(projectId)

  const { refetch: refetchHints } = useEmailHints(projectId, '')

  const hintsFetch = useCallback(
    (email: string) => {
      if (email !== '') {
        refetchHints({ projectId, email }).then((result) => {
          setSelectOptions(
            result?.data?.emailHints.map((item: string) =>
              createOption(item),
            ) || [],
          )
        })
      }
    },
    [refetchHints],
  )

  const updateSelectOptions = useCallback(debounce(hintsFetch, 200), [
    refetchHints,
  ])

  const onAddClick = async () => {
    if (error) {
      return
    }

    await addProjectUsers(
      emails.map((email) => email.value),
      roleId,
    )
  }

  useEffect(() => {
    // clear emails when loading finished
    if (!loading) {
      setEmails([])
    }
  }, [loading])

  const handleInputChange = (v: string, { action }: InputActionMeta) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      setInputValue(v)
    }
    updateSelectOptions(v)
  }

  const handleChange = (value: OnChangeValue<Option, true>) => {
    setEmails(value)
    setError('')
  }

  const isValidNewOption = (inputValue: string) => {
    if (inputValue.trim().length === 0) {
      return false
    }

    return validateEmail(inputValue) !== null
  }

  const handleBlur = () => {
    if (inputValue) {
      processInput(inputValue)
    }
  }

  const processInput = (value?: string) => {
    if (!value) {
      setInputValue('')
      setError('')
      return
    }
    // firstly. we split list of emails by comma, to identify if there are more than one email
    const userInputEmails = value.split(',')
    const validEmails: Option[] = []
    const invalidEmails: string[] = []

    // for each email in the list check it validity and create option to add to
    // resulting array
    userInputEmails.forEach((email) => {
      const trimmedEmail = email.trim()
      if (validateEmail(trimmedEmail) !== null) {
        // don't add duplicated emails
        if (!emails.find((value) => value.value === trimmedEmail)) {
          validEmails.push(createOption(trimmedEmail))
        }
      } else {
        invalidEmails.push(trimmedEmail)
      }
    })
    setEmails((v) => [...v, ...validEmails])
    setInputValue(invalidEmails.join(','))
    if (invalidEmails.length > 0) {
      setError('You have entered an invalid email, please check your input.')
    } else {
      setError('')
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    switch (event.key) {
      case 'Backspace':
        processInput(inputValue?.slice(0, -1) || '')
        event.preventDefault()
        updateSelectOptions(inputValue?.slice(0, -1) || '')
        break

      case 'Tab':
      case ' ':
      // @ts-ignore
      // eslint-disable-next-line no-fallthrough
      case ',':
        event.preventDefault()
      // eslint-disable-next-line no-fallthrough
      case 'Enter':
        processInput(inputValue || '')
        break
      default:
        break
    }
  }

  const rolesMenu = () => (
    <UserSelectRoleMenu
      listedUserRoleId={roleId}
      permissions={permissions}
      onChange={(roleId) => setRoleId(roleId)}
    />
  )

  return (
    <FlexBox alignItems="stretch" gap={15}>
      <TitleContainer className="ion-no-margin ion-no-padding" lines="none">
        <FieldLabelText>Add Users</FieldLabelText>
      </TitleContainer>
      <FlexBox
        fullWidth
        direction={isMobile ? 'column' : 'row'}
        alignItems="stretch"
        gap={10}
      >
        <CreatableSelect
          components={getCustomComponents(isMobile, rolesMenu, roleId)}
          styles={SelectCustomStyles}
          inputValue={inputValue}
          isClearable={false}
          isLoading={false}
          isMulti
          closeMenuOnSelect
          openMenuOnFocus={false}
          openMenuOnClick={false}
          hideSelectedOptions
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Email, comma separated"
          onBlur={handleBlur}
          value={emails}
          options={selectOptions}
          isValidNewOption={isValidNewOption}
          noOptionsMessage={() => 'Similar emails not found'}
        />
        {isMobile && (
          <>
            <RoleEditButton
              rolesMenu={rolesMenu}
              trigger={() => triggerRoleChange(roleId)}
            />
            <RoleInfo roleId={roleId} />
          </>
        )}
        <Button onClick={onAddClick} disabled={!!error}>
          Add
        </Button>
      </FlexBox>
      {!isMobile && <RoleInfo roleId={roleId} />}
      {error && <ErrorComponent>{error}</ErrorComponent>}
    </FlexBox>
  )
}

export default UserAddField
