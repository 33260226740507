import * as yup from 'yup'
import { ProjectLinkAddFormFields } from './ProjectLinkDialog.types'

export const validationSchema = yup.object({
  name: yup.string().max(20).required('Title is required'),
  value: yup
    .string()
    .url('Must be a valid URL starting with https:// or http://')
    .max(255)
    .required('Link address is required'),
})

export const initialValues: ProjectLinkAddFormFields = {
  name: '',
  value: '',
  error: null,
}
