/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const meQuery = gql`
  query Me {
    me {
      id
      name
      email
      phone
      avatarUrl
      isLoggedIn
      organisations {
        id
        name
        phone
        permissions
        settings {
          wordReport
          bookingFormId
          sharedLinkAssetsDefaultsFilter
        }
      }
    }
  }
`

export const logoutMutation = gql`
  mutation Logout {
    logout {
      isLoggedIn
    }
  }
`
