/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const getAssignedProjectsQuery = gql`
  query GetAssignedProjects($portfolioId: ID!, $userId: ID!) {
    getAssignedProjects(portfolioId: $portfolioId, userId: $userId) {
      id
      address
    }
  }
`
