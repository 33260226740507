/* eslint-disable import/no-default-export */
import { IonAlert } from '@ionic/react'
import { useEffect, useState } from 'react'
import { ProjectAssetsTypes } from '../../pages/ProjectPage/ProjectPage.types'
import FileUploadComponent from './FileUpload.component'
import { useFileUpload } from './FIleUpload.hooks'
import { FileUploadContainerProps } from './FileUpload.types'

const FileUpload: React.FC<FileUploadContainerProps> = ({
  file,
  type,
  done,
  hold = false,
}) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const { uploadFile, loaded, loading, invalid, errorMessage, onUploadAbort } =
    useFileUpload({
      file,
      type,
    })

  useEffect(() => {
    if (!hold) {
      const add = async () => {
        await uploadFile()
      }
      add()
    }
  }, [file, type, hold])

  useEffect(() => {
    if (loading && !invalid) {
      return
    }
    if (loaded || invalid) {
      done(file.name)
    }
  }, [loaded, loading, invalid])

  const openAlert = () => {
    // We do not allow to cancel video upload for now
    if (type !== ProjectAssetsTypes.VIDEO) {
      setAlertOpen(true)
    }
  }
  const closeAlert = () => setAlertOpen(false)

  const onAlertConfirm = () => {
    onUploadAbort()
  }

  return (
    <>
      <FileUploadComponent
        file={file}
        loaded={loaded}
        loading={loading}
        invalid={invalid}
        hold={hold}
        errorMessage={errorMessage}
        handleAbortUpload={openAlert}
      />
      <IonAlert
        isOpen={alertOpen}
        header="Cancel Upload File?"
        message="Upload is still in progress. Are you sure you want to abort?"
        cssClass="sync-alert"
        buttons={[
          {
            text: 'Close',
            role: 'cancel',
            handler: closeAlert,
          },
          {
            text: 'Abort',
            handler: onAlertConfirm,
            cssClass: 'alert-button__confirm',
          },
        ]}
      />
    </>
  )
}

export default FileUpload
