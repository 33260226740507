/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const sharedAssetQuery = gql`
  query sharedAsset($uuid: String!) {
    sharedAsset(uuid: $uuid) {
      id
      value
      name
      project_id
      type
    }
  }
`
