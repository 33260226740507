import { IonRouterOutlet } from '@ionic/react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useLocation } from 'react-router'
import { LoginPage } from '../pages/LoginPage'
import { ForgotPage } from '../pages/ForgotPage'
import { ResetPage } from '../pages/ResetPage'
import { RegisterPage } from '../pages/RegisterPage'
import { pageNames, withPageTracker } from '../hocs/withTracking'
import { ProjectSharedPage } from '../pages/ProjectSharedPage'

export const Unauthorized = () => {
  const currentLocation = useLocation()

  return (
    <IonRouterOutlet>
      <Switch>
        <Route
          path="/login"
          component={withPageTracker(LoginPage, pageNames.LOGIN)}
          exact
        />
        <Route
          path="/forgot"
          component={withPageTracker(ForgotPage, pageNames.FORGOT_PASSWORD)}
          exact
        />
        <Route
          path="/register/:token/:email"
          component={withPageTracker(RegisterPage, pageNames.REGISTER)}
          exact
        />
        <Route
          path="/reset-password/:token"
          component={withPageTracker(ResetPage, pageNames.RESET_PASSWORD)}
          exact
        />
        <Route
          path="/p/:uuid"
          component={withPageTracker(
            ProjectSharedPage,
            pageNames.PROJECT_SHARED,
          )}
          exact
        />
        <Route
          component={() => (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: currentLocation },
              }}
            />
          )}
        />
      </Switch>
    </IonRouterOutlet>
  )
}
