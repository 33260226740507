/* eslint-disable import/no-default-export */
import { IonGrid, IonPage } from '@ionic/react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Header } from '../../components/Header'
import { ProjectData } from '../../components/ProjectData'
import { ProjectInfo } from '../../components/ProjectInfo'
import { ProjectMobileControls } from '../../components/ProjectMobileControls'
import { TopScrollMenu } from '../../components/ui/TopScrollMenu'
import { ProjectProvider } from '../../contexts/project'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { ScrollMenuItems } from './ProjectPage.constants'
import { useCountableMenu, useProjectQuery } from './ProjectPage.hooks'
import { ProjectContentProps, ProjectDetailsQuery } from './ProjectPage.types'
import { ProjectAddPopoverMenu } from '../../components/ProjectAddPopoverMenu'
import { BackendError } from '../../components/BackendError'
import WaitingSpinner from '../../components/ui/WaitingSpinner/WaitingSpinner'

const StyledIonGrid = styled(IonGrid)`
  && {
    width: 100%;
    max-width: var(--page-max-size);
    padding-left: 50px;
    padding-right: 50px;
    margin: auto auto 112px;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
  }
`

const StyledContent = styled.div`
  overflow: scroll;
  height: 100%;
  padding-top: 60px;
`

const StyledPageContainer = styled.div`
  overflow: hidden;
  height: 100%;
`

const ProjectContent: React.VFC<ProjectContentProps> = ({
  project,
  updateQuery,
}) => {
  const { isMobile } = useDisplaySize()

  const menu = useCountableMenu(ScrollMenuItems, project)

  return (
    <ProjectProvider value={{ projectId: project?.id, updateQuery }}>
      <StyledContent id="scroll-container">
        <StyledIonGrid>
          <ProjectInfo project={project} />
          {isMobile && <TopScrollMenu menuItems={menu} />}
          <ProjectData project={project} />
        </StyledIonGrid>
        {isMobile && (
          <ProjectMobileControls menuItems={ProjectAddPopoverMenu} />
        )}
      </StyledContent>
    </ProjectProvider>
  )
}

const ProjectPage = ({ match }: RouteComponentProps<ProjectDetailsQuery>) => {
  const { project, updateQuery, loading, error } = useProjectQuery({
    id: match.params.id,
  })

  let content = <ProjectContent project={project} updateQuery={updateQuery} />

  if (loading) {
    content = <WaitingSpinner />
  }

  if (error) {
    let message = 'Something went wrong. Please try again later.'
    const error404 = error.graphQLErrors.find(
      (err) => err.extensions?.code === '404',
    )

    if (error404) {
      message =
        'This project does not exist or You don’t have permission to see this project.'
    }

    content = <BackendError message={message} routeReturnTo="/" />
  }

  return (
    <IonPage data-testid="ProjectPage">
      <StyledPageContainer>
        <Header />
        {content}
      </StyledPageContainer>
    </IonPage>
  )
}

export default ProjectPage
