import {
  getHumanReadableRole,
  portfolioRoles as systemPortfolioRoles,
  PortfolioRoleIds,
} from '@synctech/portal-types'

export const portfolioRoles = Object.keys(systemPortfolioRoles).reduce(
  (acc, key) => {
    acc[Number(key)] = getHumanReadableRole(Number(key) as PortfolioRoleIds)
    return acc
  },
  {} as { [key: number]: string },
)

export const availablePortfolioRoles = Object.entries(portfolioRoles).filter(
  ([, roleName]) => roleName !== 'Test No Permissions',
)

export const portfolioRoleDescriptions: { [key in PortfolioRoleIds]: string } =
  {
    1: 'Administrators have unrestricted access and control over the entire system. They possess full permissions to manage portfolios, including manipulation, deletion, and complete user management capabilities.',
    2: 'Contributors have the ability to actively participate in portfolios. They can upload, delete, and modify assets within the project included int the portfolio, and also have the privilege to invite other contributors to join the portfolio.',
    3: 'Members are internal users who are granted read-only access to projects in portfolios. They can view project details and assets, and they also have the ability to access the user information specifically related to the project.',
    4: 'Guests are external users who have read-only access to projects, allowing them to view project details and assets without the ability to modify the project or access its user information.',
    5: 'Test No Permissions',
  }
