import {
  getHumanReadableRole,
  ProjectRoleIds,
  projectRoles as systemProjectRoles,
} from '@synctech/portal-types'

export const projectRoles = Object.keys(systemProjectRoles).reduce(
  (acc, key) => {
    acc[Number(key)] = getHumanReadableRole(Number(key) as ProjectRoleIds)
    return acc
  },
  {} as { [key: number]: string },
)

export const availableProjectRoles = Object.entries(projectRoles).filter(
  ([, roleName]) => roleName !== 'Test No Permissions',
)

export const projectRoleDescriptions: { [key in ProjectRoleIds]: string } = {
  1: 'Administrators have unrestricted access and control over the entire system. They possess full permissions to manage projects, including manipulation, deletion, and complete user management capabilities.',
  2: 'Contributors have the ability to actively participate in projects. They can upload, delete, and modify assets within the project, and also have the privilege to invite other contributors to join the project.',
  3: 'Members are internal users who are granted read-only access to projects. They can view project details and assets, and they also have the ability to access the user information specifically related to the project.',
  4: 'Guests are external users who have read-only access to projects, allowing them to view project details and assets without the ability to modify the project or access its user information.',
  5: 'Test No Permissions',
}
