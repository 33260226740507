/* eslint-disable import/no-default-export */
import React from 'react'
import {
  ProjectAerialMapAddDialogHookProps,
  ProjectAerialMapAddDialogProps,
} from './ProjectAerialMapDialog.types'
import { useAddProjectAerialMap } from './ProjectAerialMapAddDialog.hooks'
import ProjectAerialMapForm from './ProjectAerialMapForm.component'
import {
  initialValues,
  validationSchema,
} from './ProjectAerialMapForm.constants'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'

const AerialMapForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  projectId,
  updateQuery,
}: DialogWithAlertInnerAugment & ProjectAerialMapAddDialogHookProps) => {
  const { addProjectAerialMap } = useAddProjectAerialMap(
    projectId,
    updateQuery,
    forceDismiss,
  )

  return (
    <ProjectAerialMapForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addProjectAerialMap}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
    />
  )
}

const ProjectAerialMapAddDialog: React.FC<ProjectAerialMapAddDialogProps> = ({
  projectId,
  updateQuery,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <AerialMapForm {...props} projectId={projectId} updateQuery={updateQuery} />
  )

  return (
    <DialogWithAlert
      dialogTitle="Add Aerial"
      alertHeader="Cancel Adding Aerial?"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectAerialMapAddDialog
