import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { deleteProjectMutation } from './ProjectDesktopControls.graphql'
import { useNotification } from '../../contexts/notification'
import { useProjectContext } from '../../contexts/project'

export const useProjectDelete = () => {
  const { projectId } = useProjectContext()
  const history = useHistory()

  const [deleteProjectRequest, { loading }] = useMutation(
    deleteProjectMutation,
    {
      variables: {
        projectId,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: projectId,
          __typename: 'Project',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    },
  )

  const { notify, notifyError } = useNotification()

  const deleteProject = async () => {
    try {
      await deleteProjectRequest()

      notify('Project successfully deleted')
      history.push('/projects')
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  return {
    deleteProject,
    loading,
  }
}
