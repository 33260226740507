/* eslint-disable import/no-default-export */
import { IonImg } from '@ionic/react'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ProjectAsset } from '../../pages/ProjectPage/ProjectPage.types'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { MediaItemCard } from '../ui/Card'
import { useDialog } from '../ui/Dialog/Dialog.hooks'

import placeholder from '../../assets/placeholder_pix4d.png'
import { withCallbackTracker } from '../../hocs/withTracking'
import { ProjectPix4dAssetsDialogHookProps } from '../ProjectPix4dAssetsDialog/ProjectPix4dAssetsDialog.types'
import { Pix4DAssetsCardProps } from './ProjectAerialMapAssets.types'
import { ProjectPix4dAssetsDialog } from '../ProjectPix4dAssetsDialog'

const Pix4DAssetCard = ({ pix4d }: Pix4DAssetsCardProps) => {
  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const [openDialog] = useDialog<ProjectPix4dAssetsDialogHookProps>(
    ProjectPix4dAssetsDialog,
    {
      pix4dAsset: pix4d,
    },
  )
  const editMenu = project.permissions.includes('DeleteProjectAssets')
    ? (pix4d: ProjectAsset) => <AssetPopoverMenu asset={pix4d} />
    : () => null
  return (
    <MediaItemCard editMenu={() => editMenu(pix4d)} title={pix4d.name}>
      <IonImg
        className="cardThumbnail"
        onClick={withCallbackTracker(openDialog, 'FE - Asset View', {
          Asset_Type: 'Pix4D',
          Asset_Name: pix4d.name,
          Project_ID: projectId,
          Project_Name: project.title,
          Project_Address: project.address,
        })}
        src={placeholder}
        alt={pix4d.name}
      />
    </MediaItemCard>
  )
}

export default Pix4DAssetCard
