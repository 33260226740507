import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useAuthContext } from './auth'
import { useMeQuery } from '../components/ui/UserInfo/UserInfo.hooks'
import { useStorageContext } from './storage'

interface OrganisationContext {
  organisationId: number | undefined
  setOrganisationId: Dispatch<SetStateAction<number | undefined>>
}

const Context = createContext<OrganisationContext>({
  organisationId: 0,
  setOrganisationId: () => {},
})

export const useOrganisationContext = () =>
  useContext<OrganisationContext>(Context)

export const OrganisationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [organisationId, setOrganisationId] = useState<number | undefined>(
    undefined,
  )

  const { isLoggedIn } = useAuthContext()
  const { user } = useMeQuery()
  const { store } = useStorageContext()

  useEffect(() => {
    ;(async () => {
      if (store) {
        const storeOrganisationId = await store.get('organisation_id')

        if (organisationId && storeOrganisationId !== organisationId) {
          await store.set('organisation_id', organisationId)
        }
      }
    })()
  }, [organisationId])

  useEffect(() => {
    ;(async () => {
      // if user is not logged in we don't want to set the organisation id
      // because this setting will be overwritten when the user logs in
      if (isLoggedIn) {
        if (user && user.organisations?.length > 0) {
          if (store) {
            const storeOrganisationId = await store.get('organisation_id')
            if (!storeOrganisationId) {
              await store.set('organisation_id', user.organisations[0].id)
              setOrganisationId(user.organisations[0].id)
            } else {
              // check if current user has access to the organisation id stored in the system storage
              // if not, then set first available organisation as default
              const organisationIds = user.organisations.map(
                (userOrganisation) => userOrganisation.id,
              )
              if (!organisationIds.includes(storeOrganisationId)) {
                await store.set('organisation_id', user.organisations[0].id)
                setOrganisationId(user.organisations[0].id)
              } else {
                setOrganisationId(storeOrganisationId)
              }
            }
          }
        }
      }
    })()
  }, [isLoggedIn, user?.organisations])

  // if no value in the system storage, then set first available organisation as default
  return (
    <Context.Provider value={{ organisationId, setOrganisationId }}>
      {children}
    </Context.Provider>
  )
}
