/**
 * convert and format a numeric string representing a timestamp into a date string
 * @param date numeric string representing a timestamp ie '1636093857414'
 * @returns an empty string if the value is undefined or a date representation. ie 5 Nov 2021
 */
export function formatDate(date: string | undefined) {
  // skip the date conversion if the value is undefined
  if (typeof date !== 'undefined') {
    // convert a time stamp from a numeric string to a Date object
    const dateObj = new Date(parseInt(date, 10))
    return dateObj.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }
  return ''
}
