/* eslint-disable import/no-default-export */
import { IonContent, IonPage } from '@ionic/react'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { TextInputField } from '../../components/fields/TextInputField'
import { ErrorComponent } from '../../components/ui/ErrorComponent'
import { Button } from '../../components/ui/Button'
import { FlexBox } from '../../components/ui/FlexBox'
import { useRegister } from './RegisterPage.hooks'
import { RegisterPageContainer } from './RegisterPage.styled'
import { RegisterPageProps } from './RegisterPage.types'
import { validationSchema, initialValues } from './RegisterPage.constants'
import WaitingSpinner from '../../components/ui/WaitingSpinner/WaitingSpinner'

const RegisterPage: React.FC<RouteComponentProps<RegisterPageProps>> = ({
  match: {
    params: { token, email },
  },
}) => {
  const { handleRegister, loading, isDone, isInvalid } = useRegister(token)
  let content: React.ReactNode

  const initValues = email ? { ...initialValues, email } : initialValues

  if (isDone) {
    content = (
      <FlexBox alignItems="stretch">
        <h2 className="ion-text-center">Done</h2>
        <p className="ion-text-center">Your password has been set</p>
        <Button className="ion-margin-top" routerLink="/login">
          Go to login
        </Button>
      </FlexBox>
    )
  }

  if (isInvalid) {
    content = (
      <FlexBox alignItems="stretch">
        <h2 className="ion-text-center">Invalid Link</h2>
        <p className="ion-text-justify">
          We are sorry, but the link has expired or is incorrect.
        </p>
        <p className="ion-text-justify">
          Please ensure the exact link was copied from the invite email.
        </p>
        <Button className="ion-margin-vertical" routerLink="/login">
          Go to login
        </Button>
      </FlexBox>
    )
  }

  if (!isDone && !isInvalid) {
    content = (
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleRegister}
      >
        {({ submitForm }) => (
          <FlexBox alignItems="stretch">
            <h2 className="ion-text-center ion-margin-bottom">
              Enter your name and password
            </h2>
            <TextInputField
              className="ion-margin-vertical"
              type="text"
              name="email"
              placeholder="Email"
              autocomplete="email"
              disabled
            />
            <TextInputField
              className="ion-margin-bottom"
              type="text"
              name="name"
              placeholder="Full name"
              autocomplete="name"
            />
            <TextInputField
              className="ion-margin-bottom"
              type="password"
              name="password"
              placeholder="New Password"
              autocomplete="new-password"
            />
            <TextInputField
              className="ion-margin-bottom"
              type="password"
              name="confirm"
              placeholder="Re-enter the new password"
            />
            <p>
              Your password must be a minimum of 10 characters, and include at
              least one lowercase letter, one uppercase letter, one symbol and a
              number.
            </p>
            <Button disabled={loading} onClick={submitForm}>
              Register
            </Button>
            <Link className="ion-text-center ion-margin-vertical" to="/login">
              Back
            </Link>
            <ErrorMessage name="error" component={ErrorComponent} />
          </FlexBox>
        )}
      </Formik>
    )
  }

  return (
    <IonPage data-testid="RegisterPage">
      <IonContent className="ion-padding-horizontal">
        <RegisterPageContainer>{content}</RegisterPageContainer>
        {loading && <WaitingSpinner />}
      </IonContent>
    </IonPage>
  )
}

export default RegisterPage
