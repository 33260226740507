/* eslint-disable import/no-default-export */
import { ProjectDocumentsProps } from './ProjectDocuments.types'
import { ItemCard } from '../ui/Card'
import { ScrollToContainer } from '../ui/ScrollToContainer'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import mixpanel from '../../helpers/tracking'
import ItemList from '../ui/Card/ItemList'

const ProjectDocuments = ({ documents }: ProjectDocumentsProps) => {
  if (!documents?.length) {
    return null
  }

  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const trackClick = (name: string) => () => {
    mixpanel.track('FE - Asset View', {
      Asset_Type: 'Document',
      Asset_Name: name,
      Project_ID: projectId,
      Project_Name: project.title,
      Project_Address: project.address,
    })
    // in order to allow follow the link
    return true
  }

  return (
    <ScrollToContainer name="project-documents" data-testid="ProjectDocuments">
      <ItemList title="Documents" itemCount={documents?.length}>
        {documents.map((document) => (
          <ItemCard
            key={document.id}
            asset={document}
            title={document.name}
            url={document.value}
            onTitleClick={trackClick(document.name)}
          />
        ))}
      </ItemList>
    </ScrollToContainer>
  )
}

export default ProjectDocuments
