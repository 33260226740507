import { useMutation } from '@apollo/client'
import { projectUsersQuery } from '../ProjectUsersDialog/ProjectUsersDialog.graphql'
import { changeProjectUserRole } from './UserAssignRoleItem.graphql'

export const useChangeProjectUserRole = (
  projectId: string,
  userId: number,
  newRoleId: number,
) => {
  const [changeUserRole, { loading }] = useMutation(changeProjectUserRole, {
    variables: {
      projectId,
      userId,
      newRoleId,
    },
    refetchQueries: [
      {
        query: projectUsersQuery,
        variables: { id: projectId.toString() },
      },
    ],
  })

  return { changeUserRole, loading }
}
