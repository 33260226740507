import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { ProjectAssetNameEditFormFields } from './ProjectAssetNameDialog.types'
import { editProjectAssetNameMutation } from './ProjectAssetNameEditDialog.graphql'
import {
  assetsTypesMapping,
  Project,
  ProjectAsset,
} from '../../pages/ProjectPage/ProjectPage.types'
import { useNotification } from '../../contexts/notification'

export const useEditProjectAssetName = (
  projectId: string,
  assetId: string,
  updateQuery: any,
  onDismiss: () => void,
) => {
  const [editProjectAssetNameRequest, { loading }] = useMutation(
    editProjectAssetNameMutation,
  )

  const { notify } = useNotification()

  const editProjectAssetName = async (
    values: ProjectAssetNameEditFormFields,
    formikHelpers: FormikHelpers<ProjectAssetNameEditFormFields>,
  ) => {
    try {
      const newAsset = await editProjectAssetNameRequest({
        variables: {
          ...values,
          projectId,
          assetId,
        },
      })

      updateQuery(({ project }: { project: Project }) => {
        const updatedAsset = newAsset.data.editProjectAsset
        // @ts-ignore
        const assetType = assetsTypesMapping[updatedAsset.type]
        const assets = {
          ...project.assets,
        }
        // @ts-ignore
        assets[assetType] = project.assets[assetType]
          .map((item: ProjectAsset) => {
            if (item.id === assetId) {
              return {
                ...item,
                ...updatedAsset,
              }
            }
            return item
          })
          .sort((a: ProjectAsset, b: ProjectAsset) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )

        return {
          project: {
            ...project,
            assets,
          },
        }
      })

      notify('Asset title successfully updated')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    editProjectAssetName,
    loading,
  }
}
