/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import _ from 'lodash'
import { Link } from 'react-scroll'
import { IonBadge } from '@ionic/react'
import { TopScrollMenuProps } from './TopScrollMenu.types'

const StyledMenuContainer = styled.div`
  background-color: var(--sync-shadowed-white);
  margin: 15px 0 0 0;
  position: sticky;
  pointer-events: initial;
  z-index: 102;
  top: 0;
  border-radius: 8px;
  overflow-x: scroll;
`

const ItemsWrapper = styled.div`
  padding: 8px;
  display: flex;
  ::before, ::after {
    content: '';  /* Insert pseudo-element */
    margin: auto; /* Make it push flex items to the center */
`

const StyledLink = styled(Link)`
  &.active {
    color: white;
    background-color: var(--sync-blue);
  }
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: black;
  letter-spacing: 0.02em;
  height: 38px;
  min-width: auto;
  padding: 0 10px;
  border-color: #000000;
  border-width: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  opacity: 1;
  white-space: nowrap;

  // hover styles applied for desktop devices only
  // to avoid sticky hover bug for ionic button component
  // https://github.com/ionic-team/ionic-framework/issues/18570
  @media (pointer: fine) {
    :hover {
      background-color: var(--sync-light-blue);
      color: var(--sync-dark-gray) !important;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`

const StyledIonBadge = styled(IonBadge)`
  margin-left: 5px;
  vertical-align: text-bottom;
  && {
    font-size: 12px;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
`

const TopScrollMenu = ({ menuItems }: TopScrollMenuProps) => {
  if (!menuItems) return null

  const onButtonActivated = (value: any) => {
    document.getElementById(`top-scroll-item-${value}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'end',
    })
  }

  // fire scroll event for top menu after user scroll is finished
  const safeButtonActivated = _.debounce(
    (value) => {
      onButtonActivated(value)
    },
    200,
    { leading: false, trailing: true },
  )

  return (
    <StyledMenuContainer id="top-scroll-menu" data-testid="TopScrollMenu">
      <ItemsWrapper>
        {menuItems.map((item) => (
          <StyledLink
            id={`top-scroll-item-${item.id}`}
            value={item.id}
            title={item.title}
            activeClass="active"
            to={item.id}
            spy
            smooth
            duration={500}
            offset={-100}
            containerId="scroll-container"
            key={item.id}
            onSetActive={safeButtonActivated}
          >
            {item.title}
            {!!item.count && <StyledIonBadge>{item.count}</StyledIonBadge>}
          </StyledLink>
        ))}
      </ItemsWrapper>
    </StyledMenuContainer>
  )
}

export default TopScrollMenu
