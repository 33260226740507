/* eslint-disable */
import styled from 'styled-components'
import { Dialog } from '../ui/Dialog'
import { FlexBox } from '../ui/FlexBox'
import { ProjectPix4dAssetsDialogProps } from './ProjectPix4dAssetsDialog.types'
import { useIntercomDisabled } from '../../helpers/useIntercomDisabled'

const StyledWrapper = styled(FlexBox)`
  max-width: 500px;
  padding: 0 2rem 3rem;
  font-size: 1.5rem;
`

const StyledHeader = styled.h2`
  color: var(--sync-red);
  align-self: flex-start;
  font-size: 2rem;
  font-weight: 600;
  max-width: 500px;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`

const StyledMessage = styled.div`
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const ProjectPix4dAssetsDialog = ({
  pix4dAsset,
  onDismiss,
}: ProjectPix4dAssetsDialogProps) => {
  const valid = /^https:\/\/cloud\.pix4d\.com\/*/.test(pix4dAsset.value)

  useIntercomDisabled()

  return (
    <Dialog
      title={pix4dAsset.name || 'PIX4D map'}
      onDismiss={onDismiss}
      cssClass="sync-fullscreen-modal"
    >
      {valid ? (
        <iframe
          width="100%"
          height="100%"
          src={pix4dAsset.value}
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      ) : (
        <FlexBox fullHeight fullWidth>
          <StyledWrapper>
            <StyledHeader>Cannot load PIX4D map.</StyledHeader>
            <StyledMessage>
              Provided PIX4D link is not properly set or corrupted.
            </StyledMessage>
          </StyledWrapper>
        </FlexBox>
      )}
    </Dialog>
  )
}

export default ProjectPix4dAssetsDialog
