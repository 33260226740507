/* eslint-disable import/no-default-export */
import { useState } from 'react'
import clsx from 'clsx'
import { Title } from '../Title'
import styles from './ItemList.module.css'
import { ItemListProps } from './ItemList.types'

const ItemList = ({ title, itemCount, children }: ItemListProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <>
      <Title value={itemCount} collapsible onCollapse={setIsCollapsed}>
        {title}
      </Title>
      <div
        className={clsx(styles.cardGridContainer, {
          [styles.cardGridContainer__collapsed]: isCollapsed,
        })}
      >
        {children}
      </div>
    </>
  )
}

export default ItemList
