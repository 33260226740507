/* eslint-disable */
import { Dialog } from '../ui/Dialog'
import { ProjectMatterportAssetsDialogProps } from './ProjectMatterportAssetsDialog.types'
import { useEffect, useRef } from 'react'

const ProjectMatterportAssetsDialog = ({
  matterportAsset,
  onDismiss,
}: ProjectMatterportAssetsDialogProps) => {
  const ifRef = useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    if (ifRef.current) {
      ifRef.current.addEventListener(
        'touchstart',
        (e) => {
          e.preventDefault()
          e.stopPropagation()
        },
        false,
      )
    }
  }, [ifRef.current])
  return (
    <Dialog
      title={matterportAsset.name || 'Matterport scan'}
      onDismiss={onDismiss}
      cssClass="sync-fullscreen-modal"
    >
      <iframe
        width="100%"
        height="100%"
        src={`https://my.matterport.com/show/?m=${matterportAsset.value}&play=1`}
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
        ref={ifRef}
      ></iframe>
    </Dialog>
  )
}
export default ProjectMatterportAssetsDialog
