/* eslint-disable import/no-default-export */
import { IonIcon } from '@ionic/react'
import { ellipsisVertical } from 'ionicons/icons'
import styled, { css } from 'styled-components'
import { Popover } from '../Popover'
import { AssetEditButtonProps } from './CardEditButton.types'
import { usePopoverContext } from '../../../contexts/popover'

const StyledIonIcon = styled(IonIcon)`
  color: #393939;
  margin: 0 0 0 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 14px;
  }
`
const popoverCss = css`
  && {
    .popover-content {
      width: 181px;
      @media (min-width: 768.02px) {
        transform: translateX(-96px) translateY(-20px);
      }
    }
  }
`

const TriggerIcon = () => {
  const { openPopover } = usePopoverContext()

  return (
    <StyledIonIcon
      size="small"
      slot="end"
      icon={ellipsisVertical}
      onClick={openPopover}
    />
  )
}

const AssetEditButton = ({ editMenu }: AssetEditButtonProps) => {
  const menuContent = editMenu && editMenu()
  if (!menuContent) {
    return null
  }

  return (
    <Popover css={popoverCss} trigger={TriggerIcon}>
      {menuContent}
    </Popover>
  )
}
export default AssetEditButton
