/* eslint-disable import/no-default-export */
import { IonContent, IonGrid, IonPage } from '@ionic/react'
import styled from 'styled-components'
import { Header } from '../../components/Header'
import { ProjectListAddPopoverMenu } from '../../components/ProjectListAddPopoverMenu'
import { ProjectMobileControls } from '../../components/ProjectMobileControls'
import { ProjectListProvider } from '../../contexts/projectList'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { usePortfolioListQuery } from './PortfoliosListPage.hooks'
import { useOrganisationContext } from '../../contexts/organisation'
import { PortfolioList } from '../../components/PortfoliosList'

const StyledIonGrid = styled(IonGrid)`
  && {
    width: 100%;
    margin: auto;
    max-width: var(--page-max-size);
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
  }
`

const StyledContent = styled.div`
  overflow: scroll;
  height: 100%;
  padding-top: 60px;
`

const StyledPageContainer = styled.div`
  overflow: hidden;
  height: 100%;
`

const StyledMobileControlsContainer = styled.div`
  margin-top: 112px;
`

const PortfoliosListPage = () => {
  const { organisationId } = useOrganisationContext()

  const {
    portfolioList = [],
    loading,
    updateQuery,
    fetchMore,
  } = usePortfolioListQuery(organisationId)

  const { isMobile } = useDisplaySize()

  return (
    <IonPage data-testid="ProjectsListPage">
      <StyledPageContainer>
        <Header />
        <StyledContent id="scroll-container">
          <ProjectListProvider value={{ updateQuery, fetchMore }}>
            <IonContent>
              <StyledIonGrid>
                <PortfolioList
                  portfolioList={portfolioList}
                  loading={loading}
                />
              </StyledIonGrid>
              {isMobile && (
                <>
                  <StyledMobileControlsContainer />
                  <ProjectMobileControls
                    menuItems={ProjectListAddPopoverMenu}
                  />
                </>
              )}
            </IonContent>
          </ProjectListProvider>
        </StyledContent>
      </StyledPageContainer>
    </IonPage>
  )
}

export default PortfoliosListPage
