/* eslint-disable import/no-default-export */
import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { BookingDialogProps } from './BookingDialog.types'
import { Dialog } from '../ui/Dialog'
import { useOrganisationContext } from '../../contexts/organisation'
import { useMeQuery } from '../ui/UserInfo/UserInfo.hooks'

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

const BookingDialog: React.FC<BookingDialogProps> = ({
  projectId,
  projectReference,
  onDismiss,
}) => {
  const { organisationId } = useOrganisationContext()
  const { user } = useMeQuery()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        if (iframeRef.current) {
          iframeRef.current.contentWindow?.addEventListener(
            'PaperformSubmission',
            () => {
              onDismiss()
            },
          )
        }
      }
    }
  }, [iframeRef.current])
  // @ts-ignore
  const { organisationName, organisationPhone, organisationFormId } =
    useMemo(() => {
      if (!user) {
        return null
      }

      const organisation = user.organisations?.find(
        (organisation) => organisation.id === organisationId,
      )

      const organisationFormId = organisation?.settings?.bookingFormId
        ? organisation.settings.bookingFormId
        : process.env.REACT_APP_DEFAULT_BOOKING_FORM_ID

      return {
        organisationName: organisation ? organisation.name : null,
        organisationPhone: organisation ? organisation.phone : null,
        organisationFormId,
      }
    }, [organisationId, user?.organisations])

  const phone = encodeURIComponent(user?.phone || organisationPhone)

  return (
    <Dialog
      title="Book a Service"
      onDismiss={onDismiss}
      cssClass="sync-help-modal"
    >
      <StyledIframe
        ref={iframeRef}
        srcDoc={`
      <div prefill="pre=true&username=${user?.name}&useremail=${user?.email}&userphone=${phone}&userorg=${organisationName}&pid=${projectId}&pref=${projectReference}" data-paperform-id="${organisationFormId}"></div><script>(function() {var script = document.createElement('script'); script.src = "https://paperform.co/__embed.min.js"; document.body.appendChild(script); })()</script>
    `}
      />
    </Dialog>
  )
}

export default BookingDialog
