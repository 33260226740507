import { gql } from '@apollo/client'

export const presignedPutUrl = gql`
  query presignedPutUrl(
    $projectId: ID!
    $type: ProjectAssetsTypes!
    $filename: String!
    $contentType: String!
  ) {
    presignedPutUrl(
      projectId: $projectId
      type: $type
      filename: $filename
      contentType: $contentType
    ) {
      method
      url
    }
  }
`

export const presignedMultipartUrl = gql`
  query presignedMultipartUrl(
    $uploadId: ID!
    $partNumber: Int!
    $key: String!
  ) {
    presignedMultipartUrl(
      uploadId: $uploadId
      partNumber: $partNumber
      key: $key
    ) {
      method
      url
    }
  }
`

export const listMultipartUploadParts = gql`
  query listMultipartUploadParts($uploadId: ID!, $key: String!) {
    listMultipartUploadParts(uploadId: $uploadId, key: $key) {
      partNumber
      etag
    }
  }
`

export const createMultipartUploadMutation = gql`
  mutation createMultipartUpload(
    $projectId: ID!
    $type: ProjectAssetsTypes!
    $filename: String!
    $contentType: String!
    $metadata: FileMetadata!
  ) {
    createMultipartUpload(
      projectId: $projectId
      type: $type
      filename: $filename
      contentType: $contentType
      metadata: $metadata
    ) {
      uploadId
      key
    }
  }
`

export const abortMultipartUploadMutation = gql`
  mutation abortMultipartUpload($uploadId: ID!, $key: String!) {
    abortMultipartUpload(uploadId: $uploadId, key: $key)
  }
`

export const completeMultipartUploadMutation = gql`
  mutation completeMultipartUpload(
    $uploadId: ID!
    $key: String!
    $parts: [MultipartPartInput!]!
  ) {
    completeMultipartUpload(uploadId: $uploadId, parts: $parts, key: $key)
  }
`

export const addS3FileAssetMutation = gql`
  mutation addProjectAsset(
    $projectId: ID!
    $type: ProjectAssetsTypes
    $name: String
  ) {
    addProjectAsset(projectId: $projectId, type: $type, name: $name) {
      id
      type
      name
      value
      ... on ImageAsset {
        fullSizeUrl
      }
    }
  }
`
