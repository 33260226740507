import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { ProjectFormFields } from './ProjectDialog.types'
import { editProjectMutation } from './ProjectEditDialog.graphql'
import { Project } from '../../pages/ProjectPage/ProjectPage.types'
import { useNotification } from '../../contexts/notification'

export const useEditProject = (
  projectId: string,
  updateQuery: any,
  onDismiss: () => void,
) => {
  const [editProjectRequest, { loading }] = useMutation(editProjectMutation)

  const { notify } = useNotification()

  const editProject = async (
    values: ProjectFormFields,
    formikHelpers: FormikHelpers<ProjectFormFields>,
  ) => {
    try {
      const updatedProject = await editProjectRequest({
        variables: {
          ...values,
          thumbnail:
            typeof values.thumbnail === 'string' ? undefined : values.thumbnail,
          projectId,
        },
      })

      updateQuery(({ project }: { project: Project }) => ({
        project: {
          ...project,
          ...updatedProject.data.editProject,
        },
      }))

      notify('Project successfully updated')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    editProject,
    loading,
  }
}
