/* eslint-disable import/no-default-export */
import React from 'react'
import { ProjectNoteAddDialogProps } from './ProjectNoteDialog.types'
import { useAddProjectNote } from './ProjectNoteAddDialog.hooks'
import ProjectNoteForm from './ProjectNoteForm.component'
import { initialValues, validationSchema } from './ProjectNoteForm.constants'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'
import { ProjectLinkAddDialogHookProps } from '../ProjectLinkDialog/ProjectLinkDialog.types'

const NoteForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  projectId,
  updateQuery,
}: DialogWithAlertInnerAugment & ProjectLinkAddDialogHookProps) => {
  const { addProjectNote } = useAddProjectNote(
    projectId,
    updateQuery,
    forceDismiss,
  )

  return (
    <ProjectNoteForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addProjectNote}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
    />
  )
}

const ProjectNoteAddDialog: React.FC<ProjectNoteAddDialogProps> = ({
  projectId,
  updateQuery,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <NoteForm {...props} projectId={projectId} updateQuery={updateQuery} />
  )

  return (
    <DialogWithAlert
      dialogTitle="Add Note"
      alertHeader="Cancel Adding Note"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectNoteAddDialog
