/* eslint-disable import/no-default-export */
import { ChangePasswordForm } from '../ChangePasswordForm'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { ChangePasswordDialogProps } from './ChangePasswordDialog.types'

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
  onDismiss,
}) => (
  <DialogWithAlert
    dialogTitle="Change Password"
    alertHeader="Cancel Password Change?"
    alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
    alertConfirmLabel="Cancel & Leave"
    alertCancelLabel="Stay"
    onDismiss={onDismiss}
    content={(props) => <ChangePasswordForm {...props} />}
  />
)

export default ChangePasswordDialog
