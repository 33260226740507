import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import { useNotification } from '../../contexts/notification'
import {
  Project,
  ProjectAsset,
} from '../../pages/ProjectPage/ProjectPage.types'
import { addProjectAerialMapMutation } from './ProjectAerialMapAddDialog.graphql'
import { ProjectAerialMapAddFormFields } from './ProjectAerialMapDialog.types'
import { nearmapRegex, pix4DRegex } from './ProjectAerialMapForm.constants'

export const useAddProjectAerialMap = (
  projectId: number,
  updateQuery: any,
  onDismiss: () => void,
) => {
  const [addProjectAerialMapRequest, { loading }] = useMutation(
    addProjectAerialMapMutation,
  )

  const { notify } = useNotification()

  const addProjectAerialMap = async (
    values: ProjectAerialMapAddFormFields,
    formikHelpers: FormikHelpers<ProjectAerialMapAddFormFields>,
  ) => {
    try {
      let type
      if (pix4DRegex.test(values.value)) {
        type = 'pix4d'
      } else if (nearmapRegex.test(values.value)) {
        type = 'nearmap'
      } else {
        throw new Error('Aerial Link accepts only Pix4D and Nearmap links')
      }

      const newAsset = await addProjectAerialMapRequest({
        variables: {
          ...values,
          type,
          projectId,
        },
      })

      updateQuery(({ project }: { project: Project }) => {
        const updatedAsset = newAsset.data.addProjectAsset

        return {
          project: {
            ...project,
            assets: {
              ...project.assets,
              pix4d: (project.assets?.pix4d || ([] as ProjectAsset[]))
                .concat([updatedAsset as ProjectAsset])
                .sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                ),
            },
          },
        }
      })

      notify('Aerial link successfully added')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    addProjectAerialMap,
    loading,
  }
}
