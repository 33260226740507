import { PortfolioRoleIds } from '@synctech/portal-types'
/* eslint-disable import/no-default-export */
import { FlexBox } from '../ui/FlexBox'
import { PortfolioUserAssignRoleMenuProps } from './PortfolioUserAssignRoleMenu.types'
import { availablePortfolioRoles } from '../PortfolioUsersDialog/PortfolioUsersDialog.constants'
import { PortfolioUserAssignRoleItem } from '../PortfolioUserAssignRoleItem'

const PortfolioUserAssignRoleMenu = ({
  listedUserId,
  listedUserRoleId,
  permissions,
  portfolioId,
}: PortfolioUserAssignRoleMenuProps) => (
  <FlexBox alignItems="stretch" dataTestid="PortfolioUserAssignRoleMenu">
    {availablePortfolioRoles.map(([portfolioRoleIdString, roleName]) => (
      <PortfolioUserAssignRoleItem
        key={portfolioRoleIdString}
        portfolioRoleId={Number(portfolioRoleIdString) as PortfolioRoleIds}
        portfolioId={portfolioId}
        listedUserId={listedUserId}
        listedUserRoleId={listedUserRoleId}
        permissions={permissions}
        roleName={roleName}
      />
    ))}
  </FlexBox>
)

export default PortfolioUserAssignRoleMenu
