/* eslint-disable import/no-default-export */
import React from 'react'
import {
  PortfolioEditDialogHookProps,
  PortfolioEditDialogProps,
  PortfolioFormFields,
} from './PortfolioDialog.types'
import { useEditPortfolio } from './PortfolioEditDialog.hooks'
import PortfolioForm from './PortfolioForm.component'
import { validationSchema } from './PortfolioForm.constants'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'
import { DialogWithAlert } from '../ui/DialogWithAlert'

const PortfolioEditForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  portfolioId,
  updateQuery,
  portfolio,
}: DialogWithAlertInnerAugment & PortfolioEditDialogHookProps) => {
  const { editPortfolio } = useEditPortfolio(
    portfolioId,
    updateQuery,
    forceDismiss,
  )

  const initialValues: PortfolioFormFields = {
    ...portfolio,
    error: null,
  }

  return (
    <PortfolioForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={editPortfolio}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
    />
  )
}

const PortfolioEditDialog: React.FC<PortfolioEditDialogProps> = ({
  portfolioId,
  updateQuery,
  portfolio,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <PortfolioEditForm
      {...props}
      portfolioId={portfolioId}
      updateQuery={updateQuery}
      portfolio={portfolio}
    />
  )

  return (
    <DialogWithAlert
      dialogTitle="Edit Portfolio"
      alertHeader="Cancel Editing Portfolio?"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default PortfolioEditDialog
