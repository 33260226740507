import { useMutation } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { FormikHelpers } from 'formik'
import { useNotification } from '../../contexts/notification'
import { Project } from '../../pages/ProjectPage/ProjectPage.types'
import { projectListQuery } from '../../pages/ProjectsListPage/ProjectListPage.graphql'
import { addProjectMutation } from './ProjectAddDialog.graphql'
import { ProjectFormFields } from './ProjectDialog.types'
import { useOrganisationContext } from '../../contexts/organisation'
import { Portfolio } from '../../pages/PortfolioPage/PortfolioPage.types'
import { portfolioQuery } from '../../pages/PortfolioPage/PortfolioPage.graphql'

export const useAddProject = (
  updateQuery: any,
  onDismiss: () => void,
  portfolioId: number | undefined = undefined,
  portfolioUpdateQuery: any = undefined,
) => {
  const [addProjectRequest, { loading }] = useMutation(addProjectMutation, {
    refetchQueries: [
      getOperationName(projectListQuery)!,
      getOperationName(portfolioQuery)!,
    ],
  })

  const { notify } = useNotification()
  const { organisationId } = useOrganisationContext()

  const addProject = async (
    values: ProjectFormFields,
    formikHelpers: FormikHelpers<ProjectFormFields>,
  ) => {
    try {
      const newProject = await addProjectRequest({
        variables: {
          ...values,
          organisation_id: parseInt(organisationId as unknown as string, 10),
          portfolio_id: portfolioId,
        },
      })

      updateQuery(({ projects }: { projects: Project[] }) => ({
        projects: [...projects, [newProject.data.addProject]],
      }))

      if (portfolioUpdateQuery) {
        portfolioUpdateQuery(({ portfolio }: { portfolio: Portfolio }) => ({
          portfolio: {
            ...portfolio,
            projects: portfolio.projects
              ? [...portfolio.projects, [newProject.data.addProject]]
              : undefined,
          },
        }))
      }

      notify('Project successfully created')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    addProject,
    loading,
  }
}
