/* eslint-disable import/no-default-export */
import { Link } from 'react-router-dom'
import { FlexBox } from '../ui/FlexBox'
import { BackendErrorProps } from './BackendError.types'
import styles from './BackendError.module.css'

const BackendError: React.VFC<BackendErrorProps> = ({
  message,
  routeReturnTo = '/',
}) => (
  <FlexBox fullHeight fullWidth dataTestid="BackendError">
    <FlexBox className={styles.wrapper}>
      <h2 className={styles.redTitle}>Ooops...</h2>
      <div className={styles.message}>{message}</div>
      {routeReturnTo && <Link to={routeReturnTo}>Back</Link>}
    </FlexBox>
  </FlexBox>
)

export default BackendError
