/* eslint-disable import/no-default-export */
import { IonText } from '@ionic/react'
import styled from 'styled-components'
import { CharacterCounterProps } from './CharacterCounter.types'

const TextWrapper = styled(IonText)`
  color: var(--sync-light-gray);
  font-size: 12px;
  line-height: 18px;
`

const CharacterCounter = ({ count, max, slot }: CharacterCounterProps) => {
  let counterColor = 'dark'

  if (count >= 0.9 * max) {
    counterColor = 'warning'
  }

  if (count >= max) {
    counterColor = 'danger'
  }

  return (
    <TextWrapper slot={slot} data-testid="CharacterCounter">
      <span>Characters&nbsp;</span>
      <IonText color={counterColor}>{`${count}/${max}`}</IonText>
    </TextWrapper>
  )
}

export default CharacterCounter
