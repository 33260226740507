/* eslint-disable import/no-default-export */
import { IonIcon, IonSpinner } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { projectPermissions } from '@synctech/portal-types'
import { useNotification } from '../../contexts/notification'
import { projectRoles } from '../ProjectUsersDialog/ProjectUsersDialog.constants'
import { PopoverItem } from '../ui/PopoverItem'
import { PopoverItemWithAlert } from '../ui/PopoverItemWithAlert'
import { useChangeProjectUserRole } from './UserAssignRoleItem.hooks'
import { UserAssignRoleItemProps } from './UserAssignRoleItem.types'

const UserAssignRoleItem = ({
  projectId,
  listedUserId,
  listedUserRoleId,
  projectRoleId,
  roleName,
  permissions,
}: UserAssignRoleItemProps) => {
  const { changeUserRole, loading } = useChangeProjectUserRole(
    projectId,
    listedUserId,
    projectRoleId,
  )
  const { notify, notifyError } = useNotification()

  const onRoleChange = async () => {
    try {
      await changeUserRole()

      notify(
        `Role changed from ${projectRoles[listedUserRoleId]} to ${roleName}`,
      )
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  const message = `Are you sure you want to change this user's role from ${projectRoles[listedUserRoleId]} to ${roleName}?`

  const canManageRole = permissions.includes(
    projectPermissions[listedUserRoleId],
  )
  const disabled =
    !canManageRole || !permissions.includes(projectPermissions[projectRoleId])

  return !(projectRoleId === listedUserRoleId) ? (
    <PopoverItemWithAlert
      key={projectRoleId}
      header="Assign a new role"
      message={message}
      confirmButtonText="Assign"
      onClick={onRoleChange}
      disabled={disabled}
    >
      {roleName}
      {loading && <IonSpinner slot="end" name="lines-small" />}
    </PopoverItemWithAlert>
  ) : (
    <PopoverItem key={projectRoleId} disabled={disabled}>
      {roleName}
      <IonIcon
        slot="end"
        color="primary"
        size="small"
        icon={checkmarkCircleOutline}
      />
    </PopoverItem>
  )
}

export default UserAssignRoleItem
