import { ApolloError, useQuery } from '@apollo/client'
import { portfolioQuery } from './PortfolioPage.graphql'
import { unpackPermissions } from '../../helpers/casl'
import { Portfolio, PortfolioDetailsQuery } from './PortfolioPage.types'
import { PAGINATION_LIMIT } from '../ProjectsListPage/ProjectListPage.constants'

export const usePortfolioQuery = (
  props: PortfolioDetailsQuery,
): {
  portfolio: Portfolio
  loading: boolean
  updateQuery: any
  fetchMore: any
  refetch: any
  error?: ApolloError
} => {
  const { data, loading, error, updateQuery, fetchMore, refetch } = useQuery(
    portfolioQuery,
    {
      variables: { id: props.id, offset: 0, limit: PAGINATION_LIMIT },
    },
  )

  return {
    portfolio: {
      ...(data && data.portfolio),
      permissions: unpackPermissions(data?.portfolio?.permissions),
    },
    error,
    loading,
    updateQuery,
    fetchMore,
    refetch,
  }
}
