/* eslint-disable no-unused-vars */
import { createContext, useContext, useState } from 'react'
import { IonToast } from '@ionic/react'
import styled from 'styled-components'

interface NotificationContext {
  notify: (message: string) => void
  notifyError: (message: string) => void
}

const Context = createContext<NotificationContext>({
  notify: () => {},
  notifyError: () => {},
})

const StyledIonToast = styled(IonToast)`
  && {
    --border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
`

export const useNotification = () => useContext<NotificationContext>(Context)

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [{ message, color }, setNotification] = useState<any>({})

  const notify = (messageText: string) =>
    setNotification({ message: messageText, color: 'success' })
  const notifyError = (errorText: string) =>
    setNotification({ message: errorText, color: 'syncred' })

  return (
    <Context.Provider value={{ notify, notifyError }}>
      {children}
      {message && (
        <StyledIonToast
          isOpen
          position="top"
          onDidDismiss={() => setNotification({})}
          color={color}
          message={message}
          duration={3000}
        />
      )}
    </Context.Provider>
  )
}
