import { ApolloError, useQuery } from '@apollo/client'
import { projectSharedQuery } from './ProjectSharedPage.graphql'
import { ProjectSharedQuery } from './ProjectSharedPage.types'
import { Project } from '../ProjectPage/ProjectPage.types'

export const useProjectSharedQuery = (
  props: ProjectSharedQuery,
): {
  project: Project
  loading: boolean
  updateQuery: any
  error?: ApolloError
} => {
  const { data, loading, error, updateQuery } = useQuery(projectSharedQuery, {
    variables: { uuid: props.uuid },
  })
  const project = data?.sharedProject

  return {
    project: {
      ...project,
      permissions: [],
    },
    error,
    loading,
    updateQuery,
  }
}
