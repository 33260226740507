/* eslint-disable import/no-default-export */
import { IonCol, IonItem, IonRow } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { ProjectInfoCardSkeleton } from '../ProjectInfoCard'
import { Title } from '../ui/Title'
import { PortfolioListProps } from './PortfolioList.types'
import PortfolioInfoCard from '../PortfolioInfoCard/PortfoloInfoCard.component'
import PortfolioListDesktopControls from '../PortfolioListDesktopControls/PortfolioListDesktopControls.component'

const ContainerRow = styled(IonRow)`
  max-width: 1440px;
  margin: 0 auto;
`

const StyledCenterCol = styled(IonCol)`
  max-width: 798px;
  margin: 0 auto;
`

// Placeholder for the left scroll menu
const Spacer = styled.div`
  min-width: 100px;
  width: 188px;
  @media (max-width: 1140px) {
    width: 100%;
  }
`

const StyledTitle = styled(Title)`
  @media (max-width: 768px) {
    margin-top: 0 !important;
  }
`

const StyledIonRow = styled(IonRow)`
  margin: 0 -5px;
`

const SkeletonSet = React.memo(() => (
  <>
    {[1, 2, 3, 4, 5, 6].map((num) => (
      <IonCol key={num} size="6" sizeXl="4">
        <ProjectInfoCardSkeleton />
      </IonCol>
    ))}
  </>
))

const AuxContent: React.VFC<{ loading: boolean }> = ({ loading }) =>
  loading ? (
    <SkeletonSet />
  ) : (
    <IonItem lines="none" className="ion-justify-content-center">
      No portfolios
    </IonItem>
  )

const PortfolioList: React.FC<PortfolioListProps> = ({
  portfolioList,
  loading,
}) => {
  const { isMobile } = useDisplaySize()

  return (
    <ContainerRow
      className="ion-justify-content-center"
      data-testid="PortfoliosList"
    >
      {!isMobile && (
        <IonCol size="auto">
          <Spacer />
        </IonCol>
      )}
      <StyledCenterCol>
        <StyledTitle>Portfolios</StyledTitle>
        <StyledIonRow>
          {portfolioList?.length ? (
            portfolioList.map((portfolio) => (
              <IonCol key={portfolio.id} size="6" sizeXl="4">
                <PortfolioInfoCard portfolio={portfolio} />
              </IonCol>
            ))
          ) : (
            <AuxContent loading={loading} />
          )}
        </StyledIonRow>
      </StyledCenterCol>
      {!isMobile && (
        <IonCol size="auto">
          <PortfolioListDesktopControls />
        </IonCol>
      )}
    </ContainerRow>
  )
}

export default PortfolioList
