import { ScrollMenuItem } from '../../contexts/scrollMenu.types'
import { MaxFileSize, ProjectAssetsTypes } from './ProjectPage.types'

export const ScrollMenuItems: ScrollMenuItem[] = [
  {
    id: 'project-description',
    title: 'Description',
  },
  {
    id: 'project-captur3d',
    title: 'Digital Twin',
  },
  {
    id: 'project-bim_file',
    title: 'BIM',
  },
  {
    id: 'project-pix4d',
    title: 'Aerial',
  },
  {
    id: 'project-images',
    title: 'Images',
  },
  {
    id: 'project-videos',
    title: 'Videos',
  },
  {
    id: 'project-documents',
    title: 'Documents',
  },
  {
    id: 'project-urls',
    title: 'Links',
  },
  {
    id: 'project-custom',
    title: 'Notes',
  },
]

// IMPORTANT when changing the max value for the assets,
// you'll also need to update the values on the server
// Set client_max_body_size to match in
// /etc/nginx/conf.d/dashboard-api.synctech.io.conf
export const fileAssetFileMaxSize: MaxFileSize = {
  // when changing fileAssetFileMaxSize, also change MaxFileSize value in apps/backend/config.ts
  binary: 53687091200,
  simple: 50000000000,
} // in bytes

// Videos are uploaded directly into Vimeo service,
// so no need to update anything on the server
export const videoAssetFileMaxSize: MaxFileSize = {
  binary: 21474836480,
  simple: 20000000000,
} // in bytes

export const acceptMap = {
  [ProjectAssetsTypes.BIM_FILE]:
    '.rvt,.rfa,.rte,.rft,.dgn,.dwf,.dwg,.dxf,.ifc,.sat,.skp,.rcs,.e57',
  [ProjectAssetsTypes.DOCUMENT]: '.pdf,.doc,.docx,.xls,.xlsx,image/*,.heic',
  [ProjectAssetsTypes.IMAGE]: 'image/*,.heic',
  [ProjectAssetsTypes.VIDEO]: 'video/*',
  [ProjectAssetsTypes.SELF_HOSTED_TOUR]: '.zip,.tar,tar.gz,.tar.bz2',
}
