import { useLazyQuery, useMutation } from '@apollo/client'
import { useNotification } from '../../../contexts/notification'
import {
  addPortfolioUsersMutation,
  emailHintsQuery,
} from './PortfolioUserAddField.graphql'
import { portfolioUsersQuery } from '../../PortfolioUsersDialog/PortfolioUsersDialog.graphql'
import { portfolioQuery } from '../../../pages/PortfolioPage/PortfolioPage.graphql'

export const useAddPortfolioUsers = (portfolioId: number) => {
  const [addPortfolioUsersRequest, { loading, error }] = useMutation(
    addPortfolioUsersMutation,
    {
      refetchQueries: [
        {
          query: portfolioUsersQuery,
          variables: { id: portfolioId.toString() },
        },
        // For now we don't trigger project list refetch because it not doing well with paginated results
        {
          query: portfolioQuery,
          variables: { id: portfolioId.toString() },
        },
      ],
    },
  )

  const { notify, notifyError } = useNotification()

  const addPortfolioUsers = async (emails: string[], roleId: number) => {
    try {
      await addPortfolioUsersRequest({
        variables: { portfolioId, emails, roleId },
      })

      notify('Portfolio members successfully added')
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  return { addPortfolioUsers, loading, error }
}

export const useEmailHints = (portfolioId: number, email: string) => {
  const [getHints, { refetch }] = useLazyQuery(emailHintsQuery, {
    variables: {
      portfolioId,
      email,
    },
    fetchPolicy: 'network-only',
  })

  return { getHints, refetch }
}

// email validation regexp
// https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
export const validateEmail = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
