import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { deletePortfolioMutation } from './PortfolioDesktopControls.graphql'
import { useNotification } from '../../contexts/notification'
import { usePortfolioContext } from '../../contexts/portfolio'

export const usePortfolioDelete = () => {
  const { portfolioId } = usePortfolioContext()
  const history = useHistory()

  const [deletePortfolioRequest, { loading }] = useMutation(
    deletePortfolioMutation,
    {
      variables: {
        portfolioId,
      },
      update(cache) {
        // since we disabled __typename ( addTypename: false) in ApolloClient,
        // we can't identify particular object in the list of projects
        // https://www.apollographql.com/docs/react/caching/cache-interaction/#obtaining-an-objects-cache-id
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'portfolios' })
        // https://www.apollographql.com/docs/react/caching/garbage-collection/#cacheevict
        // mandatory call for the garbage collector
        cache.gc()
      },
    },
  )

  const { notify, notifyError } = useNotification()

  const deletePortfolio = async () => {
    try {
      await deletePortfolioRequest()

      notify('Portfolio successfully deleted')
      history.push('/portfolios')
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  return {
    deletePortfolio,
    loading,
  }
}
