/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const projectQuery = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      thumbnailUrl
      title
      address
      attributes {
        projectPublicItems {
          description
          assets
        }
      }
      status
      description
      permissions
      reference_id
      sharedLinkEnabled
      shared_link_uuid
      created_at
      usersPreview {
        id
        name
        avatarUrl
      }
      usersCount
      assets {
        documents {
          id
          value
          name
          type
        }
        images {
          id
          value
          name
          type
        }
        videos {
          id
          value
          name
          type
        }
        custom {
          id
          value
          name
          type
        }
        urls {
          id
          value
          name
          type
        }
        pix4d {
          id
          value
          name
          type
        }
        nearmap {
          id
          value
          name
          type
        }
        captur3d {
          id
          value
          name
          type
          previewUrl
        }
        matterport {
          id
          value
          name
          type
          previewUrl
        }
        self_hosted_tour {
          id
          value
          name
          type
          previewUrl
          attributes {
            self_hosted_tour {
              status
            }
          }
        }
        bim_file {
          id
          value
          name
          type
          previewUrl
          attributes {
            bim_file {
              is_autodesk_published
              autodesk_status
              autodesk_urn
            }
          }
        }
      }
    }
  }
`
