import * as yup from 'yup'
import { passwordValidator } from '@synctech/portal-types'
import { ChangePasswordFormFields } from '../ChangePasswordDialog/ChangePasswordDialog.types'

export const validationSchema = yup.object({
  oldPassword: yup.string().required('Current Password is required').nullable(),
  newPassword: passwordValidator.label('New Password'),
  confirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], "Passwords Don't Match")
    .required('Password confirmation is required')
    .nullable(),
})

export const initialValues: ChangePasswordFormFields = {
  oldPassword: null,
  newPassword: null,
  confirm: null,
  error: null,
}
