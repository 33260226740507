/* eslint-disable */
import { Dialog } from '../ui/Dialog'
import { ProjectSelfHostedTourAssetsDialogProps } from './ProjectSelfHostedTourAssetsDialog.types'
import { useOrganisationContext } from '../../contexts/organisation'
import { useIntercomDisabled } from '../../helpers/useIntercomDisabled'

const ProjectSelfHostedTourAssetsDialog = ({
  projectId,
  selfHostedAsset,
  onDismiss,
}: ProjectSelfHostedTourAssetsDialogProps) => {
  const { organisationId } = useOrganisationContext()

  useIntercomDisabled()

  return (
    <Dialog
      title={selfHostedAsset.name || 'Self Hosted Tour'}
      onDismiss={onDismiss}
      cssClass="sync-fullscreen-modal"
    >
      <iframe
        width="100%"
        height="100%"
        src={`${process.env.REACT_APP_API_HOST}/assets/${organisationId}/${projectId}/${selfHostedAsset.id}/index.html`}
        frameBorder="0"
        allow="xr-spatial-tracking"
        allowFullScreen={true}
      ></iframe>
    </Dialog>
  )
}

export default ProjectSelfHostedTourAssetsDialog
