/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Button } from '../Button'
import { useDialog } from '../Dialog/Dialog.hooks'
import { BookingDialogProps } from '../../BookingDialog/BookingDialog.types'
import BookingDialog from '../../BookingDialog/BookingDialog.component'
import { useMeQuery } from '../UserInfo/UserInfo.hooks'
import { useOrganisationContext } from '../../../contexts/organisation'
import { effectiveOrganisationPermissions } from '../../../helpers/casl'
import { useProjectContext } from '../../../contexts/project'
import { useProjectQuery } from '../../../pages/ProjectPage/ProjectPage.hooks'

const StyledButton = styled(Button)`
  && {
    margin: 0 0 8px 0;
    ::part(native) {
      padding-right: 14px;
      padding-left: 14px;
    }
  }
`

const BookScanButton = () => {
  const { projectId } = useProjectContext()

  const { project } = useProjectQuery({ id: projectId })

  const [openBookingDialog] = useDialog<BookingDialogProps>(BookingDialog, {
    projectId,
    projectReference: project?.reference_id || '0',
    onDismiss: () => {},
  })
  const { user } = useMeQuery()
  const history = useHistory()
  const { organisationId } = useOrganisationContext()
  const permissions = effectiveOrganisationPermissions(user, organisationId)

  const onBookingButtonClick = () => {
    if (user?.phone) {
      openBookingDialog()
    } else {
      // if user has no phone number assigned, redirect to profile page and ask to add phone number
      history.push('/profile?fromBooking=true')
    }
  }

  if (!permissions.includes('ViewBookLink')) {
    return null
  }

  return (
    <StyledButton color="light" onClick={onBookingButtonClick}>
      Book a Service
    </StyledButton>
  )
}

export default BookScanButton
