/* eslint-disable import/no-default-export */
import styled from 'styled-components'

const Wrapper = styled.div`
  color: var(--sync-red);
  margin: 6px 12px 0;
`

const ErrorComponent: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <Wrapper data-testid="ErrorComponent">
    <small>{children}</small>
  </Wrapper>
)

export default ErrorComponent
