import { Dialog } from '../ui/Dialog'
import { ProjectDigitalTwinAssetDialogProps } from './ProjectDigitalTwinAssetDialog.types'
import { useIntercomDisabled } from '../../helpers/useIntercomDisabled'
import { ProjectDigitalTwinFullScreen } from '../ProjectDigitalTwinAssetFullScreen'

const ProjectDigitalTwinAssetDialog = ({
  digitalTwinAsset,
  onDismiss,
}: ProjectDigitalTwinAssetDialogProps) => {
  useIntercomDisabled()

  return (
    <Dialog
      title={digitalTwinAsset.name || 'Digital Twin Asset'}
      onDismiss={onDismiss}
      cssClass="sync-fullscreen-modal"
    >
      <ProjectDigitalTwinFullScreen digitalTwinAsset={digitalTwinAsset} />
    </Dialog>
  )
}

export default ProjectDigitalTwinAssetDialog
