/* eslint-disable import/no-default-export */
import { IonIcon, useIonPopover } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'
import { useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import screenfull from 'screenfull'
import styled from 'styled-components'
import { FlexBox } from '../FlexBox'

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  .errorWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const StyledIcon = styled(IonIcon)`
  width: 48px;
  height: 48px;
  cursor: pointer;
  z-index: 1;
`

const tooltip = () => (
  <div className="ion-padding">
    Your video is being processed. Please refresh this page later.
  </div>
)

const VideoPlayer = ({
  url,
  id,
  fullscreenOnStart = false,
  onStart = undefined,
  ...rest
}: ReactPlayerProps) => {
  const [error, setError] = useState<boolean>(false)
  const [present, dismiss] = useIonPopover(tooltip, {
    onHide: () => dismiss(),
  })

  const onPlay = () => {
    if (screenfull.isEnabled && fullscreenOnStart) {
      const wrapper = document.querySelector(`div[data-id="${id}"]`)
      const iframe = wrapper && wrapper.querySelector('iframe')
      screenfull.request(iframe || undefined)
    }
  }

  return (
    <PlayerWrapper data-id={id} className="VideoPlayer">
      {error && (
        <FlexBox
          justifyContent="center"
          alignItems="center"
          className="errorWrapper"
        >
          <StyledIcon
            size="large"
            icon={alertCircleOutline}
            onClick={(e) =>
              present({
                event: e.nativeEvent,
              })
            }
          />
        </FlexBox>
      )}
      <ReactPlayer
        width="100%"
        height="100%"
        className="react-player"
        url={url}
        controls
        config={{
          vimeo: {
            playerOptions: {
              // If responsive is set to "true" it will break current layout.
              responsive: false,
            },
          },
        }}
        onPlay={onPlay}
        onStart={onStart}
        onError={() => {
          setError(true)
        }}
        {...rest}
      />
    </PlayerWrapper>
  )
}

export default VideoPlayer
