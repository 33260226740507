import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import { useState } from 'react'
import { forgotMutation } from './ForgotPage.graphql'
import { ForgotFormFields } from './ForgotPage.types'

export const useForgot = () => {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [forgot, { loading }] = useMutation(forgotMutation)

  const handleForgot = async (
    values: ForgotFormFields,
    formikHelpers: FormikHelpers<ForgotFormFields>,
  ) => {
    try {
      const { data: emailSent } = await forgot({
        variables: values,
      })

      setIsEmailSent(emailSent)
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    isEmailSent,
    handleForgot,
    loading,
  }
}
