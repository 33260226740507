import { IonRouterOutlet } from '@ionic/react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { UserProfilePage } from '../pages/UserProfilePage'
import { ProjectPage } from '../pages/ProjectPage'
import { ProjectsListPage } from '../pages/ProjectsListPage'
import { ProfilePage } from '../pages/ProfilePage'
import { pageNames, withPageTracker } from '../hocs/withTracking'
import { useAuthContext } from '../contexts/auth'
import { ProjectSharedPage } from '../pages/ProjectSharedPage'
import { PortfoliosListPage } from '../pages/PortfoliosListPage'
import PortfolioPage from '../pages/PortfolioPage/PortfolioPage.component'
import { SharedAssetPage } from '../pages/SharedAssetPage'

export const Authorized = () => {
  const { redirectUrl, setRedirectUrl } = useAuthContext()
  const history = useHistory()
  useEffect(() => {
    // if we have a redirect url, we need to redirect to it
    if (redirectUrl && redirectUrl !== '') {
      history.replace(redirectUrl)
      setRedirectUrl('')
    }
  }, [redirectUrl, setRedirectUrl, history])

  return (
    <IonRouterOutlet>
      <Switch>
        <Route
          path="/asset/:uuid"
          component={withPageTracker(SharedAssetPage, pageNames.SHARED_ASSET)}
          exact
        />
        <Route
          path="/projects"
          component={withPageTracker(
            ProjectsListPage,
            pageNames.PROJECTS_LISTING,
          )}
          exact
        />
        <Route
          path="/projects/:id"
          component={withPageTracker(ProjectPage, pageNames.PROJECT_DETAILS)}
          exact
        />
        <Route
          path="/p/:uuid"
          component={withPageTracker(
            ProjectSharedPage,
            pageNames.PROJECT_SHARED,
          )}
          exact
        />
        <Route
          path="/portfolios/:id"
          component={withPageTracker(
            PortfolioPage,
            pageNames.PORTFOLIO_DETAILS,
          )}
          exact
        />
        <Route
          path="/portfolios"
          component={withPageTracker(
            PortfoliosListPage,
            pageNames.PORTFOLIOS_LISTING,
          )}
          exact
        />
        <Route
          path="/profile/:id"
          component={withPageTracker(ProfilePage, pageNames.USER_PROFILE)}
          exact
        />
        <Route
          path="/profile"
          component={withPageTracker(UserProfilePage, pageNames.MY_PROFILE)}
          exact
        />
        <Route component={() => <Redirect to={redirectUrl || '/projects'} />} />
      </Switch>
    </IonRouterOutlet>
  )
}
