// ************************************
// react-select component customisation
// ************************************
import React from 'react'
import { components } from 'react-select'
import { chevronDown } from 'ionicons/icons'
import { IonIcon, IonText } from '@ionic/react'
import styled, { css } from 'styled-components'
import { Popover } from '../../ui/Popover'
import { usePopoverContext } from '../../../contexts/popover'
import { Button } from '../../ui/Button'
import { projectRoles } from '../../ProjectUsersDialog/ProjectUsersDialog.constants'

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: black;
  background-color: transparent;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
  min-width: 150px;
  border-radius: 16px;
  font-size: 12px;
  margin: 0 0 0 auto;
  padding: 0 15px 0 0;

  flex-shrink: 0;
  @media (max-width: 768px) {
    font-size: 12px;
    height: 24px;
    border-radius: 12px;

    min-width: inherit;
    padding: 25px 10px 25px 15px;
  }
`

const StyledText = styled(IonText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;

  height: 32px;
  padding-right: 0.5rem;

  @media (max-width: 768px) {
    font-size: 12px;
    flex-shrink: 0.5;
  }
`

const StyledIonIcon = styled(IonIcon)`
  pointer-events: none;
`

const changeRolePopoverCss = css`
  && {
    .popover-content {
      width: 200px;
      transform: translateY(3px);
      @media (max-width: 768px) {
        width: 180px;
        transform: translateX(-19px) translateY(3px);
      }
    }
  }
`

export const SelectCustomStyles = {
  multiValue: (provided: any) => ({
    ...provided,
    border: '1px solid var(--sync-light-gray)',
    boxSizing: 'border-box',
    borderRadius: 12,
    height: '24px',
    lineHeight: '24px',
    backgroundColor: 'white',
    padding: '0 5px',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    fontSize: 12,
    color: 'black',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: 'var(--sync-light-gray)',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
    },
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: 12,
  }),

  control: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    width: '100%',
    border:
      state.isFocused || state.isSelected
        ? '1px solid var(--sync-blue)'
        : '1px solid transparent',
    boxShadow:
      state.isFocused || state.isSelected
        ? '0 0 0 0'
        : '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    '&:hover': {
      // disable hover styles
    },
  }),

  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
}

// ************************************
// end of react-select component customisation
// ************************************

export const RoleEditButton = ({ rolesMenu, trigger }: any) => (
  <Popover css={changeRolePopoverCss} trigger={trigger}>
    {rolesMenu && rolesMenu()}
  </Popover>
)

const TriggerRoleChange: React.VFC<{ currentRoleName: string }> = ({
  currentRoleName,
}): JSX.Element => {
  const { openPopover } = usePopoverContext()

  return (
    <StyledButton as="button" onClick={openPopover}>
      <StyledText>{currentRoleName}</StyledText>
      <StyledIonIcon
        slot="end"
        onClick={openPopover}
        size="small"
        icon={chevronDown}
      />
    </StyledButton>
  )
}

export const triggerRoleChange = (roleId: number) => (
  <TriggerRoleChange currentRoleName={projectRoles[roleId]} />
)

const MemoizedButton = React.memo(
  ({ rolesMenu, roleId }: any) => (
    <div
      onMouseDown={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
      }}
    >
      <RoleEditButton
        rolesMenu={rolesMenu}
        trigger={() => triggerRoleChange(roleId)}
      />
    </div>
  ),
  // @ts-ignore
  (prevProps, nextProps) => prevProps.roleId === nextProps.roleId,
)

export const getCustomComponents = (
  isMobile: boolean,
  rolesMenu: Function,
  roleId: number,
) => ({
  DropdownIndicator: undefined,
  IndicatorSeparator: undefined,
  LoadingIndicator: undefined,
  IndicatorsContainer: ({ children, ...rest }: any) => (
    <>
      <components.IndicatorsContainer {...rest}>
        {children}
      </components.IndicatorsContainer>
      {!isMobile && <MemoizedButton rolesMenu={rolesMenu} roleId={roleId} />}
    </>
  ),
})
