/* eslint-disable import/no-default-export */
import { IonItem, IonText } from '@ionic/react'
import { useField } from 'formik'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CharacterCounter } from '../../ui/CharacterCounter'
import { ErrorComponent } from '../../ui/ErrorComponent'
import { TextInput } from '../../ui/TextInput'
import { TextInputFieldProps } from './TextInputField.types'

const StyledIonText = styled(IonText)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    flex: 0 1 fit-content;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`

const TextInputField: React.FC<TextInputFieldProps> = ({
  name,
  placeholder,
  type,
  label,
  className,
  maxLength,
  // For html input to be able to receive longer strings for formatting
  maxLengthStrict = true,
  isRequired,
  autocomplete = 'off',
  formatter,
  disabled = false,
  ...restProps
}) => {
  /* eslint-disable-next-line operator-linebreak */
  const [{ onBlur, value, ...rest }, { error, touched }, { setValue }] =
    useField(name)
  const ref = useRef<any>(null)

  // If formik receives the same value after formatting
  // it not gonna rerender DOM's input value
  // so we need to ask react to do it
  useEffect(() => {
    const input = ref.current.querySelector('input')
    if (input && value && value !== input.value) {
      input.value = value
    }
  }, [ref.current?.querySelector('input')?.value, value])

  const onChange = (event: any) => {
    let newValue = event.target.value
    if (formatter) {
      newValue = formatter(event.target.value)
    }
    setValue(newValue)
  }

  return (
    <div className={className}>
      {label && (
        <IonItem className="ion-no-margin ion-no-padding" lines="none">
          <StyledIonText>
            {label}
            {isRequired && '*'}
          </StyledIonText>
          {!!maxLength && (
            <CharacterCounter
              max={maxLength}
              count={value?.length || 0}
              slot="end"
            />
          )}
        </IonItem>
      )}
      <TextInput
        ref={ref}
        onIonInput={onChange}
        onIonBlur={onBlur}
        placeholder={placeholder}
        type={type}
        value={value}
        className={touched && error ? 'error' : ''}
        autocomplete={autocomplete}
        disabled={disabled}
        {...(maxLengthStrict && { maxlength: maxLength })}
        {...restProps}
        {...rest}
      />
      {touched && error && <ErrorComponent>{error}</ErrorComponent>}
    </div>
  )
}

export default TextInputField
