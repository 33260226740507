import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { PortfolioFormFields } from './PortfolioDialog.types'
import { editPortfolioMutation } from './PortfolioEditDialog.graphql'
import { useNotification } from '../../contexts/notification'
import { Portfolio } from '../../pages/PortfolioPage/PortfolioPage.types'

export const useEditPortfolio = (
  portfolioId: number,
  updateQuery: any,
  onDismiss: () => void,
) => {
  const [editPortfolioRequest, { loading }] = useMutation(editPortfolioMutation)

  const { notify } = useNotification()

  const editPortfolio = async (
    values: PortfolioFormFields,
    formikHelpers: FormikHelpers<PortfolioFormFields>,
  ) => {
    try {
      const updatedPortfolio = await editPortfolioRequest({
        variables: {
          ...values,
          portfolioId,
        },
      })

      updateQuery(({ portfolio }: { portfolio: Portfolio }) => ({
        portfolio: {
          ...portfolio,
          ...updatedPortfolio.data.editPortfolio,
        },
      }))

      notify('Portfolio successfully updated')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    editPortfolio,
    loading,
  }
}
