/* eslint-disable import/no-default-export */
import { checkmarkCircleOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { projectPermissions, ProjectRoleIds } from '@synctech/portal-types'
import { availableProjectRoles } from '../../ProjectUsersDialog/ProjectUsersDialog.constants'
import { FlexBox } from '../../ui/FlexBox'
import { PopoverItem } from '../../ui/PopoverItem'
import { UserSelectRoleMenuProps } from './UserSelectRoleMenu.types'

const UserSelectRoleMenu = ({
  listedUserRoleId,
  permissions,
  onChange,
}: UserSelectRoleMenuProps) => (
  <FlexBox alignItems="stretch">
    {availableProjectRoles.map(([projectRoleIdString, roleName]) => {
      const projectRoleId = parseInt(projectRoleIdString, 10) as ProjectRoleIds
      const canManageRole = permissions.includes(
        projectPermissions[projectRoleId],
      )

      const disabled =
        !canManageRole ||
        !permissions.includes(projectPermissions[projectRoleId])

      return (
        <PopoverItem
          key={projectRoleIdString}
          disabled={disabled}
          onClick={() => onChange(projectRoleId)}
        >
          {roleName}
          <IonIcon
            slot="end"
            color="primary"
            size="small"
            icon={
              listedUserRoleId === projectRoleId
                ? checkmarkCircleOutline
                : undefined
            }
          />
        </PopoverItem>
      )
    })}
  </FlexBox>
)

export default UserSelectRoleMenu
