/* eslint-disable */
import styled from 'styled-components'
import { Dialog } from '../ui/Dialog'
import { FlexBox } from '../ui/FlexBox'
import { ProjectBimAutodeskAssetsDialogProps } from './ProjectBimAutodeskAssetsDialog.types'
import { useEffect, useRef, useState } from 'react'
import { useAutodeskToken } from './ProjectBimAutodeskAssetsDialog.hooks'
import { useIntercomDisabled } from '../../helpers/useIntercomDisabled'

const StyledWrapper = styled(FlexBox)`
  max-width: 500px;
  padding: 0 2rem 3rem;
  font-size: 1.5rem;
`

const StyledHeader = styled.h2`
  color: var(--sync-red);
  align-self: flex-start;
  font-size: 2rem;
  font-weight: 600;
  max-width: 500px;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`

const StyledMessage = styled.div`
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const StyledForgeViewer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

// You may refer to
// https://github.com/outer-labs/react-forge-viewer/blob/master/src/index.js

const ProjectBimAutodeskAssetsDialog = ({
  bimAsset,
  onDismiss,
}: ProjectBimAutodeskAssetsDialogProps) => {
  const [viewer, setViewer] = useState<any>()

  // Save this variable for useEffect cleanup function
  const viewerRef = useRef<any>()
  viewerRef.current = viewer

  const { refetch: refetchToken } = useAutodeskToken()

  useIntercomDisabled()

  const initializeViewer = () => {
    const options = {
      env: 'AutodeskProduction2',
      api: 'streamingV2', // for models uploaded to EMEA change this option to 'streamingV2_EU'
      getAccessToken: function (onTokenReady: any) {
        refetchToken().then((result) => {
          onTokenReady(
            result.data?.autodeskToken?.token,
            result.data?.autodeskToken?.expires,
          )
        })
      },
    }

    // @ts-ignore
    Autodesk.Viewing.Initializer(options, function () {
      const htmlDiv = document.getElementById('forgeViewer')
      // @ts-ignore

      const autodeskViewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv)
      setViewer(autodeskViewer)
      const startedCode = autodeskViewer.start()
      if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.')
        return
      }

      console.log('Initialization complete, loading a model next...')
    })
  }

  useEffect(() => {
    if (!viewer) {
      return
    }

    const documentId = bimAsset.attributes?.bim_file?.autodesk_urn

    if (!documentId) {
      return
    }

    // @ts-ignore
    Autodesk.Viewing.Document.load(
      `urn:${documentId}`,
      onDocumentLoadSuccess,
      onDocumentLoadFailure,
    )

    function onDocumentLoadSuccess(viewerDocument: any) {
      const defaultModel = viewerDocument.getRoot().getDefaultGeometry()
      viewer.loadDocumentNode(viewerDocument, defaultModel)
    }

    function onDocumentLoadFailure() {
      console.error('Failed fetching Forge manifest')
    }
  }, [viewer, bimAsset])

  useEffect(() => {
    // Load viewer script only once, because of strange errors when reinitializing
    // https://stackoverflow.com/questions/61885753/extensions-do-not-load-after-the-second-time-in-forge-viewer
    // @ts-ignore
    if (window.Autodesk) {
      initializeViewer()
      return
    }

    const script = document.createElement('script')
    script.src =
      'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js'
    script.async = true
    script.onload = () => initializeViewer()
    document.body.appendChild(script)
  }, [])

  return (
    <Dialog
      title={bimAsset.name || 'Autodesk asset'}
      onDismiss={onDismiss}
      cssClass="sync-fullscreen-modal"
    >
      <link
        rel="stylesheet"
        href="https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css"
        type="text/css"
      />
      <StyledForgeViewer id="forgeViewer"></StyledForgeViewer>
    </Dialog>
  )
}

export default ProjectBimAutodeskAssetsDialog
