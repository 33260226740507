/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const userPersonalProfileQuery = gql`
  query PersonalProfile {
    me {
      id
      name
      email
      phone
      avatarUrl
    }
  }
`
