/* eslint-disable import/no-default-export */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { IonSelectOption } from '@ionic/react'
import { ProjectStatus } from '@synctech/portal-types'
import { Button } from '../ui/Button'
import { FlexBox } from '../ui/FlexBox'
import { TextInputField } from '../fields/TextInputField'
import { ErrorComponent } from '../ui/ErrorComponent'
import { TextareaField } from '../fields/TextareaField'
import { SelectField } from '../fields/SelectField'
import { ProjectFormProps } from './ProjectForm.types'
import { ImageUploadField } from '../fields/ImageUploadField'

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledTextInputField = styled(TextInputField)`
  align-self: flex-start;
  width: 100%;
`

const StyledSelectField = styled(SelectField)`
  align-self: flex-start;
  width: 100%;
`

const ButtonContainer = styled(FlexBox)`
  margin-top: auto;
  padding-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column-reverse !important;
    justify-content: stretch !important;
  }
`

const FieldsContainer = styled(FlexBox)`
  @media (max-width: 768px) {
    flex-direction: column !important;
    justify-content: stretch !important;
  }
`

const ProjectForm: React.FC<ProjectFormProps> = ({
  validationSchema,
  initialValues,
  onSubmit,
  onDismiss,
  setAllowedToDismiss,
  thumbnailButtonTitle,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    setAllowedToDismiss(!formik.dirty)
  }, [formik.dirty])

  return (
    <FormikProvider value={formik}>
      <FlexBox
        direction="column"
        fullWidth
        fullHeight
        gap={20}
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <ImageUploadField
          name="thumbnail"
          label="Project Thumbnail"
          buttonTitle={thumbnailButtonTitle}
        />
        <TextInputField
          name="address"
          type="text"
          label="Project Address"
          placeholder="Project address"
          isRequired
          maxLength={255}
        />
        <TextInputField
          name="title"
          type="text"
          label="Project Name"
          placeholder="Project name"
          maxLength={255}
        />
        <TextareaField
          name="description"
          label="Description"
          placeholder="Description"
          rows={4}
        />
        <FieldsContainer direction="row" fullWidth gap={20}>
          <StyledSelectField
            name="status"
            label="Status"
            placeholder="Status"
            isRequired
          >
            <IonSelectOption value={ProjectStatus.PENDING}>
              Pending
            </IonSelectOption>
            <IonSelectOption value={ProjectStatus.NEW}>New</IonSelectOption>
            <IonSelectOption value={ProjectStatus.INSPECTION}>
              Inspection
            </IonSelectOption>
            <IonSelectOption value={ProjectStatus.PRODUCTION}>
              Production
            </IonSelectOption>
            <IonSelectOption value={ProjectStatus.DELIVERED}>
              Delivered
            </IonSelectOption>
            <IonSelectOption value={ProjectStatus.COMPLETE}>
              Complete
            </IonSelectOption>
            <IonSelectOption value={ProjectStatus.CANCELLED}>
              Cancelled
            </IonSelectOption>
            <IonSelectOption value={ProjectStatus.DECLINED}>
              Declined
            </IonSelectOption>
            <IonSelectOption value={ProjectStatus.HOLD}>Hold</IonSelectOption>
            <IonSelectOption value={ProjectStatus.ARCHIVED}>
              Archived
            </IonSelectOption>
          </StyledSelectField>
          <StyledTextInputField
            name="reference_id"
            type="text"
            label="Customer Ref"
            placeholder="Customer Ref"
            maxLength={20}
          />
        </FieldsContainer>
        <ButtonContainer direction="row" fullWidth gap={20}>
          <StyledButton
            color="light"
            expand="block"
            onClick={() => onDismiss()}
          >
            Cancel
          </StyledButton>
          <StyledButton expand="block" onClick={formik.submitForm}>
            Save
          </StyledButton>
        </ButtonContainer>
        <ErrorMessage name="error" component={ErrorComponent} />
      </FlexBox>
    </FormikProvider>
  )
}

export default ProjectForm
