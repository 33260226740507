/* eslint-disable */
import { PortfolioUsersDialogProps } from './PortfolioUsersDialog.types'
import { Dialog } from '../ui/Dialog'
import { FlexBox } from '../ui/FlexBox'
import styled from 'styled-components'
import { Button } from '../ui/Button'
import { useUserListQuery } from './PortfolioUsersDialog.hooks'
import { PortfolioUserAddField } from '../fields/PortfolioUserAddField'
import { PortfolioRoleIds } from '@synctech/portal-types'
import PortfolioUser from './PortfolioUser.component'

const ButtonContainer = styled(FlexBox)`
  margin-top: auto;
  padding-bottom: 10px;
`

const PortfolioUsersDialog = ({
  portfolioId,
  onDismiss,
}: PortfolioUsersDialogProps) => {
  const { userList, permissions } = useUserListQuery(portfolioId)

  return (
    <Dialog title="Users" onDismiss={onDismiss} cssClass="sync-users-modal">
      <FlexBox
        direction="column"
        gap={30}
        alignItems="stretch"
        justifyContent="flex-start"
        fullHeight
      >
        {permissions.includes('AddPortfolioUser') && (
          <PortfolioUserAddField
            portfolioId={portfolioId}
            permissions={permissions}
          />
        )}
        <FlexBox gap="5px normal" alignItems="stretch">
          {userList?.map((user) => (
            <PortfolioUser
              key={user.id}
              id={user.id}
              name={user.name}
              avatar={user.avatarUrl}
              email={user.email}
              roleId={Number(user.roleId) as PortfolioRoleIds}
              userId={Number(user.id)}
              portfolioId={portfolioId}
              permissions={permissions}
              isRegistered={user.isRegistered}
            />
          ))}
        </FlexBox>
        <ButtonContainer direction="row" fullWidth gap={20}>
          <Button color="light" expand="block" onClick={() => onDismiss()}>
            Close
          </Button>
        </ButtonContainer>
      </FlexBox>
    </Dialog>
  )
}

export default PortfolioUsersDialog
