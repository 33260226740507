/* eslint-disable import/no-default-export */
import { useHistory } from 'react-router-dom'
import OrderParameter from './OrderParameter.component'
import { OrderOption } from './OrderParameter.types'
import { FlexBox } from '../ui/FlexBox'

const orderFields = [
  {
    label: 'Date Created',
    value: 'date',
  },
  {
    label: 'Alphabetical',
    value: 'address',
  },
  {
    label: 'Status',
    value: 'status',
  },
]

const orderDirections = [
  {
    label: 'Descending',
    value: 'DESC',
  },
  {
    label: 'Ascending',
    value: 'ASC',
  },
]

const OrderControls = () => {
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)
  // const searchQuery = params.get('q') || undefined
  const orderField = params.get('o') || orderFields[0].value
  const orderDirection = params.get('od') || orderDirections[0].value

  const orderFieldOption =
    orderFields.find((o) => o.value === orderField) || orderFields[0]

  const orderDirectionOption =
    orderDirections.find((o) => o.value === orderDirection) ||
    orderDirections[0]

  const onOrderFieldChange = (value?: OrderOption) => {
    if (value) {
      params.set('o', value.value)
      params.set('od', orderDirection)
    }
    history.replace(`/projects?${params.toString()}`)
  }

  const onOrderDirectionhange = (value?: OrderOption) => {
    if (value) {
      params.set('o', orderField)
      params.set('od', value.value)
    }
    history.replace(`/projects?${params.toString()}`)
  }

  return (
    <FlexBox direction="row" justifyContent="flex-end" gap={16}>
      <OrderParameter
        options={orderFields}
        value={orderFieldOption}
        onChange={onOrderFieldChange}
      />
      <OrderParameter
        options={orderDirections}
        value={orderDirectionOption}
        onChange={onOrderDirectionhange}
      />
    </FlexBox>
  )
}
export default OrderControls
