/* eslint-disable import/no-default-export */
import { IonImg } from '@ionic/react'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ProjectAsset } from '../../pages/ProjectPage/ProjectPage.types'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { MediaItemCard } from '../ui/Card'

import placeholder from '../../assets/placeholder_nearmap.png'
import { withCallbackTracker } from '../../hocs/withTracking'
import { NearmapAssetsCardProps } from './ProjectAerialMapAssets.types'

const NearmapAssetCard = ({ nearmap }: NearmapAssetsCardProps) => {
  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const onCardClick = () => {
    window.open(nearmap.value, '_blank')
  }

  const editMenu = project.permissions.includes('DeleteProjectAssets')
    ? (pix4d: ProjectAsset) => <AssetPopoverMenu asset={pix4d} />
    : () => null
  return (
    <MediaItemCard editMenu={() => editMenu(nearmap)} title={nearmap.name}>
      <IonImg
        className="cardThumbnail"
        onClick={withCallbackTracker(onCardClick, 'FE - Asset View', {
          Asset_Type: 'Nearmap',
          Asset_Name: nearmap.name,
          Project_ID: projectId,
          Project_Name: project.title,
          Project_Address: project.address,
        })}
        src={placeholder}
        alt={nearmap.name}
      />
    </MediaItemCard>
  )
}

export default NearmapAssetCard
