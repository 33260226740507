import { gql } from '@apollo/client'

export const loginMutation = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      id
      name
      email
      phone
      avatarUrl
      isLoggedIn
      organisations {
        id
        name
        phone
        permissions
        settings {
          wordReport
          bookingFormId
          sharedLinkAssetsDefaultsFilter
        }
      }
    }
  }
`
