/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const addPortfolioUsersMutation = gql`
  mutation AddPortfolioUsers(
    $portfolioId: ID!
    $emails: [String]!
    $roleId: Int!
  ) {
    addPortfolioUsers(
      portfolioId: $portfolioId
      emails: $emails
      roleId: $roleId
    ) {
      id
    }
  }
`

export const emailHintsQuery = gql`
  query EmailHints($portfolioId: ID, $email: String) {
    portfolioEmailHints(portfolioId: $portfolioId, email: $email)
  }
`
