/* eslint-disable import/no-default-export */
import { Element } from 'react-scroll'
import styled from 'styled-components'

const ScrollToContainer = styled(Element)`
  overflow: auto;
  margin: 0 -5px;
  padding: 0 5px;
`

export default ScrollToContainer
