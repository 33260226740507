/* eslint-disable import/no-default-export */
import { IonItem } from '@ionic/react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import imagePlaceholder from '../../assets/placeholder_600x400.svg'
import { hasPermission } from '../../helpers/casl'
import { ProjectProps } from '../../pages/ProjectPage/ProjectPage.types'
import ProjectUsersDialog from '../ProjectUsersDialog/ProjectUsersDialog.component'
import { ProjectUsersDialogHookProps } from '../ProjectUsersDialog/ProjectUsersDialog.types'
import { AvatarStack } from '../ui/AvatarStack'
import { MediaItemCard } from '../ui/Card'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import {
  ProjectStatusIndicator,
  ProjectStatusProgress,
} from '../ui/ProjectStatusIndicator'

const StyledIonItemContainer = styled(IonItem)`
  --inner-padding-end: 0;
`

const StyledImg = styled.img`
  && {
    object-fit: cover;
    height: 150px;
    width: 100%;
    display: block;
  }
`

const BottomSlot = ({ project }: ProjectProps) => {
  // eslint-disable-next-line operator-linebreak
  const [openUserDialog] = useDialog<ProjectUsersDialogHookProps>(
    ProjectUsersDialog,
    {
      projectId: project?.id,
    },
  )

  return (
    <IonItem lines="none" className="ion-no-padding">
      <ProjectStatusIndicator status={project?.status} />
      {hasPermission(project, ['ViewProjectUsers']) && (
        <StyledIonItemContainer
          slot="end"
          lines="none"
          className="ion-no-padding ion-no-margin"
        >
          {!!project?.usersPreview?.length && (
            <AvatarStack
              users={project.usersPreview}
              onClick={openUserDialog}
            />
          )}
        </StyledIonItemContainer>
      )}
    </IonItem>
  )
}

const ProjectInfoCard = ({ project }: ProjectProps) => {
  // set fallback image for the project thumbnail
  const thumbnailUrl = useMemo(
    () => (project?.thumbnailUrl ? project?.thumbnailUrl : imagePlaceholder),
    [project.thumbnailUrl],
  )

  return (
    <MediaItemCard
      title={project?.address || ''}
      editMenu={() => {}}
      bottomSlot={() => <BottomSlot project={project} />}
    >
      <Link to={`/projects/${project?.id}`}>
        <StyledImg src={thumbnailUrl} alt={project?.address} />
      </Link>
      <ProjectStatusProgress status={project?.status} />
    </MediaItemCard>
  )
}

export default ProjectInfoCard
