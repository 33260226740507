/* eslint-disable import/no-default-export */
import React from 'react'
import { IonCardContent, IonCol, IonRow, IonText } from '@ionic/react'
import styled from 'styled-components'
import { ProjectProps } from '../../pages/ProjectPage/ProjectPage.types'
import InfoField from './InfoField.component'
import { formatDate } from '../../helpers'
import { ShadowCard } from '../ui/Card'

const StyledAddressIonText = styled(IonText)`
  font-size: 40px;
  @media (max-width: 992px) {
    font-size: 18px;
    margin-top: 24px;
    font-weight: bold;
  }
`

const StyledIonCardContent = styled(IonCardContent)`
  && {
    background-color: white;
    margin-left: 8px;
    @media (max-width: 992px) {
      padding: 10px;
    }
  }
`

const StyledIonCard = styled(ShadowCard)`
  border-right: 8px solid white;
  background-color: var(--sync-red);
  margin-right: 0;
  width: 100%;
  @media (max-width: 992px) {
    margin: 16px 0;
  }
`

const StyledAddressIonCol = styled(IonCol)`
  @media (max-width: 992px) {
    margin-top: 24px;
  }
`

const ProjectInfo: React.FC<ProjectProps> = ({ project }) => (
  <IonRow
    className="ion-justify-content-center  ion-align-items-center"
    data-testid="ProjectInfo"
  >
    <StyledAddressIonCol size="12" sizeLg="4">
      <StyledAddressIonText>{project?.address}</StyledAddressIonText>
    </StyledAddressIonCol>
    <IonCol size="12" sizeLg="7" offsetLg="1">
      <IonRow className="ion-justify-content-end">
        <StyledIonCard>
          <StyledIonCardContent>
            <IonRow className="ion-no-padding">
              <IonCol size="6" sizeLg="3">
                <InfoField title="Status" value={project?.status} />
              </IonCol>
              <IonCol size="6" sizeLg="3">
                <InfoField
                  title="Date Created"
                  value={formatDate(project?.created_at)}
                />
              </IonCol>
              <IonCol size="6" sizeLg="3">
                <InfoField title="Project ID" value={project?.id} />
              </IonCol>
              <IonCol size="6" sizeLg="3">
                <InfoField title="Customer Ref" value={project?.reference_id} />
              </IonCol>
            </IonRow>
          </StyledIonCardContent>
        </StyledIonCard>
      </IonRow>
    </IonCol>
  </IonRow>
)

export default ProjectInfo
