/* eslint-disable import/no-default-export */
import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { IonItem, IonText } from '@ionic/react'
import { ErrorComponent } from '../../ui/ErrorComponent'
import { Textarea } from '../../ui/Textarea'
import { TextareaFieldProps } from './TextareaField.types'
import { CharacterCounter } from '../../ui/CharacterCounter'

const StyledIonText = styled(IonText)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    flex: 0 1 fit-content;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`

const TextareaField: React.FC<TextareaFieldProps> = ({
  name,
  placeholder,
  label,
  className,
  maxLength,
  isRequired,
  ...restProps
}) => {
  /* eslint-disable-next-line operator-linebreak */
  const [{ onChange, onBlur, value, ...rest }, { error, touched }] =
    useField(name)

  return (
    <div className={className}>
      {label && (
        <IonItem className="ion-no-margin ion-no-padding" lines="none">
          <StyledIonText>
            {label}
            {isRequired && '*'}
          </StyledIonText>
          {!!maxLength && (
            <CharacterCounter
              max={maxLength}
              count={value?.length || 0}
              slot="end"
            />
          )}
        </IonItem>
      )}
      <Textarea
        onIonInput={onChange}
        onIonBlur={onBlur}
        placeholder={placeholder}
        value={value}
        maxlength={maxLength}
        className={touched && error ? 'error' : ''}
        {...restProps}
        {...rest}
      />
      {touched && error && <ErrorComponent>{error}</ErrorComponent>}
    </div>
  )
}

export default TextareaField
