import * as yup from 'yup'
import { ProjectAerialMapAddFormFields } from './ProjectAerialMapDialog.types'

export const pix4DRegex = /^https:\/\/cloud\.pix4d\.com\/*/
export const nearmapRegex = /^https:\/\/apps\.nearmap\.com\/*/

export const validationSchema = yup.object({
  name: yup.string().max(255).required('Title is required'),
  value: yup
    .string()
    .required('Aerial Link is required')
    .ensure()
    .test(
      'is-aerial-map-link',
      'Must be valid Pix4D or Nearmap link',
      (value) => {
        const isPix4DLink = pix4DRegex.test(value)
        const isNearmapLink = nearmapRegex.test(value)

        return isPix4DLink || isNearmapLink
      },
    )
    .max(255),
})

export const initialValues: ProjectAerialMapAddFormFields = {
  name: '',
  value: '',
  error: null,
}
