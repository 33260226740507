/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const portfolioListQuery = gql`
  query Portfolios($organisationId: ID!) {
    portfolios(organisationId: $organisationId) {
      id
      name
      permissions
      usersPreview {
        id
        name
        avatarUrl
      }
    }
  }
`
