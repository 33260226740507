import React, { createContext, useContext, useState } from 'react'

export interface IDialogContext {
  activeDialog: IDialogInfo | undefined
  openDialog: any
  closeDialog: any
}

export interface IDialogInfo {
  component: any
  componentProps?: any
}

const DialogContext = createContext<IDialogContext>({
  activeDialog: undefined,
  openDialog: () => {},
  closeDialog: () => {},
})

export const useDialogContext = () => useContext<IDialogContext>(DialogContext)

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeDialog, setActiveDialog] = useState<IDialogInfo>()

  const openDialog = (component: React.ReactNode, componentProps: any) => {
    if (activeDialog) {
      closeDialog().then(() => {
        setActiveDialog({
          component,
          componentProps,
        })
      })
    } else {
      setActiveDialog({
        component,
        componentProps,
      })
    }
  }

  const closeDialog = async () =>
    new Promise<void>((resolve) => {
      setActiveDialog(undefined)
      resolve()
    })

  return (
    <DialogContext.Provider value={{ activeDialog, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  )
}
