/* eslint-disable import/no-default-export */
import React from 'react'
import {
  ProjectEditDialogHookProps,
  ProjectEditDialogProps,
  ProjectFormFields,
} from './ProjectDialog.types'
import { useEditProject } from './ProjectEditDialog.hooks'
import ProjectForm from './ProjectForm.component'
import { validationSchema } from './ProjectForm.constants'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'
import { DialogWithAlert } from '../ui/DialogWithAlert'

const ProjectEditForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  projectId,
  updateQuery,
  project,
}: DialogWithAlertInnerAugment & ProjectEditDialogHookProps) => {
  const { editProject } = useEditProject(projectId, updateQuery, forceDismiss)

  const initialValues: ProjectFormFields = {
    ...project,
    thumbnail: project.thumbnailUrl,
    error: null,
  }
  return (
    <ProjectForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={editProject}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
      thumbnailButtonTitle="Edit"
    />
  )
}

const ProjectEditDialog: React.FC<ProjectEditDialogProps> = ({
  projectId,
  updateQuery,
  project,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <ProjectEditForm
      {...props}
      projectId={projectId}
      updateQuery={updateQuery}
      project={project}
    />
  )

  return (
    <DialogWithAlert
      dialogTitle="Edit Project"
      alertHeader="Cancel Editing Project?"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectEditDialog
