/* eslint-disable import/no-default-export */
import React, { useState, useEffect } from 'react'
import { IonIcon, IonInput, IonItem } from '@ionic/react'
import { searchOutline as searchIcon, closeCircleOutline } from 'ionicons/icons'
import { SearchFieldProps } from './SearchField.types'
import styles from './SearchField.module.css'

const SearchField: React.FC<SearchFieldProps> = ({
  className,
  autocomplete = 'off',
  disabled = false,
  value,
  onValueChange,
  ...restProps
}) => {
  const [clearIconVisible, setClearIconVisible] = useState(false)
  useEffect(() => {
    if (value && value.length > 0) {
      setClearIconVisible(true)
    } else {
      setClearIconVisible(false)
    }
  }, [value])

  const onChange = (event: any) => {
    if (event.target.value !== undefined && onValueChange) {
      onValueChange(event.target.value)
    }
  }

  const clear = () => {
    if (onValueChange) {
      onValueChange(undefined)
    }
  }

  return (
    <div className={className}>
      <IonItem lines="none" className={styles.container}>
        <IonIcon slot="start" icon={searchIcon} className={styles.searchIcon} />
        {clearIconVisible && (
          <IonIcon
            slot="end"
            icon={closeCircleOutline}
            onClick={clear}
            aria-label="Clear Search"
            className={styles.searchIcon}
          />
        )}
        <IonInput
          className={styles.searchInput}
          onIonChange={onChange}
          placeholder="Search Projects"
          type="text"
          debounce={500}
          value={value}
          autocomplete={autocomplete}
          disabled={disabled}
          {...restProps}
        />
      </IonItem>
    </div>
  )
}

export default SearchField
