/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const projectUsersQuery = gql`
  query ProjectUsers($id: ID!) {
    project(id: $id) {
      id
      permissions
      users {
        id
        name
        email
        avatarUrl
        roleId
        isRegistered
      }
    }
  }
`

export const deleteProjectUserMutation = gql`
  mutation DeleteProjectUser($projectId: ID!, $userId: ID!) {
    deleteProjectUser(projectId: $projectId, userId: $userId) {
      id
    }
  }
`
