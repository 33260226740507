import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import { useNotification } from '../../contexts/notification'
import { addProjectDigitalTwinMutation } from './ProjectDigitalTwinDialog.graphql'
import { ProjectDigitalTwinAddFormFields } from './ProjectDigitalTwinDialog.types'
import { projectListQuery } from '../../pages/ProjectsListPage/ProjectListPage.graphql'
import { projectQuery } from '../../pages/ProjectPage/ProjectPage.graphql'
import {
  captur3DRegex,
  matterportRegex,
} from './ProjectDigitalTwinForm.constants'

const matterportFormatter = (value: string) => {
  // Browser link https://my.matterport.com/models/AbcDEfgHi12?cta_origin=all_spaces_page&section=media
  if (/models\/[A-z0-9]{11}/.test(value)) {
    const [, id] = value.split('models/')
    return id ? id.substring(0, 11) : value
  }
  // Copied link https://my.matterport.com/show/?m=AbcDEfgHi12
  // Copied link https://my.matterport.com/work?m=AbcDEfgHi12
  // Copied embed <iframe width='853' height='480' src='https://my.matterport.com/show/?m=AbcDEfgHi12' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
  if (/\?m=[A-z0-9]{11}/.test(value)) {
    const [, id] = value.split('?m=')
    return id ? id.substring(0, 11) : value
  }
  return value
}

export const useAddProjectDigitalTwin = (
  projectId: number,
  updateQuery: any,
  onDismiss: () => void,
) => {
  const [addProjectDigitalTwinRequest, { loading }] = useMutation(
    addProjectDigitalTwinMutation,
    {
      refetchQueries: [
        {
          query: projectListQuery,
        },
        {
          query: projectQuery,
          variables: { id: projectId.toString() },
        },
      ],
    },
  )

  const { notify } = useNotification()

  const addProjectDigitalTwin = async (
    values: ProjectDigitalTwinAddFormFields,
    formikHelpers: FormikHelpers<ProjectDigitalTwinAddFormFields>,
  ) => {
    try {
      let type
      let { value } = values
      if (captur3DRegex.test(values.value)) {
        type = 'captur3d'
      } else if (matterportRegex.test(values.value)) {
        type = 'matterport'
        value = matterportFormatter(values.value)
      } else {
        throw new Error(
          'Digital Twin Link accepts only Captur3D and Matterport links',
        )
      }

      await addProjectDigitalTwinRequest({
        variables: {
          ...values,
          value,
          type,
          projectId,
        },
      })

      notify('Digital Twin Link successfully added')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    addProjectDigitalTwin,
    loading,
  }
}
