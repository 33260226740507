import { IonRouterOutlet } from '@ionic/react'
import { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { pageNames, withPageTracker } from '../hocs/withTracking'
import { BackendError } from '../components/BackendError'
import { SharedAssetPage } from '../pages/SharedAssetPage'
import { useAuthContext } from '../contexts/auth'

export const NoOrg = () => {
  const { isLoggedIn, redirectUrl, setRedirectUrl } = useAuthContext()
  const history = useHistory()

  useEffect(() => {
    // if we have a redirect url, we need to redirect to it
    if (isLoggedIn) {
      if (redirectUrl && redirectUrl !== '') {
        history.replace(redirectUrl)
        setRedirectUrl('')
      }
    }
  }, [isLoggedIn])
  return (
    <IonRouterOutlet>
      <Switch>
        <Route
          path="/asset/:uuid"
          component={withPageTracker(SharedAssetPage, pageNames.SHARED_ASSET)}
          exact
        />
        <Route
          component={() => (
            <BackendError message="You have insufficient permissions to view this page. If you believe this is an error, please contact support or your local administrator." />
          )}
        />
      </Switch>
    </IonRouterOutlet>
  )
}
