/* eslint-disable import/no-default-export */
import { useField } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { IonIcon, IonItem, IonText } from '@ionic/react'
import { close } from 'ionicons/icons'
import { ErrorComponent } from '../../ui/ErrorComponent'
import { ImageUploadFieldProps } from './ImageUploadField.types'
import { FlexBox } from '../../ui/FlexBox'
import { Button } from '../../ui/Button'
import imagePlaceholder from '../../../assets/placeholder_600x400.svg'
import { useDisplaySize } from '../../../helpers/useDisplaySize'

const StyledIonText = styled(IonText)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    flex: 0 1 fit-content;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`

const HiddenInput = styled.input`
  display: none;
`

const FilePickerWrapper = styled(FlexBox)`
  overflow: hidden;
`

const StyledBox = styled.div`
  align-self: stretch;
  height: 32px;
  background-color: var(--sync-shadowed-white);
  padding: 0 0.5rem;
  margin-bottom: 8px;
`

const StyledFileName = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: 0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledIonIconClose = styled(IonIcon)`
  font-size: 14px;
  margin-left: 16px;
  cursor: pointer;
`

const PreviewContainer = styled.img`
  object-fit: cover;
`
const FileName = ({ name, onClear }: { name: string; onClear: () => void }) => (
  <StyledBox>
    <FlexBox direction="row" justifyContent="space-between" fullHeight>
      <StyledFileName>{name}</StyledFileName>
      <FlexBox direction="row" justifyContent="flex-end">
        <StyledIonIconClose color="black" icon={close} onClick={onClear} />
      </FlexBox>
    </FlexBox>
  </StyledBox>
)

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  name,
  label,
  className,
  isRequired,
  buttonTitle = 'Pick a file',
  ...restProps
}) => {
  /* eslint-disable-next-line operator-linebreak */
  const [{ value }, { error }, { setValue, setTouched }] = useField(name)

  // PreviewContainer (img) src value
  const [preview, setPreview] = useState(value)
  const [fileName, setFileName] = useState<string>('No file selected')

  const { isMobile } = useDisplaySize()

  useEffect(() => {
    // we clear value via cross button
    if (value === undefined) {
      setPreview(undefined)
      setFileName('No file selected')
      if (fileInput.current) {
        // @ts-ignore
        fileInput.current.value = null
      }
      // and set value to null to inform backend to delete existing image from s3
      setValue(null)
    }

    // probably URL is passed as a default value
    // hence we display this in the image tag "as is"
    if (typeof value === 'string') {
      setFileName(
        // eslint-disable-next-line operator-linebreak
        value.split('/').pop()?.split('#')[0].split('?')[0] ||
          'No file selected',
      )
      setPreview(value)
    }

    // file object, we need to extract file data to display
    if (value !== null && typeof value === 'object') {
      setFileName(value.name)

      const reader = new FileReader()
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        setPreview(ev.target?.result)
      }
      reader.readAsDataURL(value)
    }
  }, [value])

  const fileInput = useRef<HTMLInputElement>(null)

  const handleChange = (event: any) => {
    if (event.target.files[0]) {
      setValue(event.target.files[0], true)
      setTouched(true, true)
    }
  }

  return (
    <FlexBox
      direction="column"
      alignItems="stretch"
      gap={20}
      className={className}
      {...restProps}
    >
      {label && (
        <IonItem className="ion-no-margin ion-no-padding" lines="none">
          <StyledIonText>
            {label}
            {isRequired && '*'}
          </StyledIonText>
        </IonItem>
      )}
      <FlexBox
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isMobile ? 'center' : 'flex-start'}
        gap={10}
      >
        <PreviewContainer
          src={preview || imagePlaceholder}
          alt={name}
          width={190}
          height={190}
        />
        <FilePickerWrapper
          direction="column"
          justifyContent="flex-start"
          alignSelf={isMobile ? 'stretch' : undefined}
          alignItems={isMobile ? 'center' : 'flex-start'}
          grow={5}
          gap={20}
        >
          <FileName name={fileName} onClear={() => setValue(undefined)} />
          <HiddenInput
            type="file"
            name="thumbnail"
            ref={fileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <Button
            color="light"
            onClick={() => {
              fileInput?.current?.click()
            }}
          >
            {buttonTitle}
          </Button>
        </FilePickerWrapper>
      </FlexBox>
      {error && <ErrorComponent>{error}</ErrorComponent>}
    </FlexBox>
  )
}

export default ImageUploadField
