/* eslint-disable import/no-default-export */
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { IonBadge, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons'
import { TitleProps } from './Title.types'

import { Link } from '../Link'
import styles from './Title.module.css'

const Title = ({
  children,
  value,
  href,
  className = '',
  collapsible,
  onCollapse,
}: TitleProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    if (onCollapse) {
      onCollapse(isCollapsed)
    }
  }, [isCollapsed])

  const onCollapseClick = () => {
    setIsCollapsed((prev) => !prev)
  }

  return (
    <IonItem
      lines="none"
      className={clsx(className, styles.container, {
        [styles.containerCollapsible]: collapsible,
      })}
      data-testid="Title"
      onClick={onCollapseClick}
    >
      <IonItem
        lines="none"
        className={clsx('ion-no-padding', {
          [styles.noBackground]: collapsible,
        })}
      >
        <IonLabel className={styles.label}>{children}</IonLabel>
        {!!value && <IonBadge className={styles.badge}>{value}</IonBadge>}
      </IonItem>
      {href && (
        <Link slot="end" className="ion-no-margin" to={href}>
          See all
        </Link>
      )}
      {collapsible && (
        <IonIcon
          slot="end"
          className="collapseChevron ion-no-margin"
          icon={isCollapsed ? chevronForwardOutline : chevronDownOutline}
        />
      )}
    </IonItem>
  )
}
export default Title
