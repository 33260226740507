/* eslint-disable import/no-default-export */
// import { HeaderProps } from './Header.types'
import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react'
import { addIcons } from 'ionicons'
import styled from 'styled-components'
import { chevronDown } from 'ionicons/icons'

import { Anchor, Link } from '../ui/Link'
import { UserInfo } from '../ui/UserInfo'
import LogoImg1x from '../../assets/logo_1x.png'
import LogoImg2x from '../../assets/logo_2x.png'
import LogoImg3x from '../../assets/logo_3x.png'
import { FlexBox } from '../ui/FlexBox'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { SearchForm } from '../SearchForm'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import HelpDialog from '../HelpDialog/HelpDialog.component'
import { HelpDialogHookProps } from '../HelpDialog/HelpDialog.types'
import { ObsoleteBrowserBanner } from '../ObsoleteBrowserBanner'

addIcons({ 'chevron-down': chevronDown })

const StyledImg = styled.img`
  cursor: pointer;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  &&:hover {
    color: var(--sync-blue);
  }
`

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  cursor: pointer;
  &&:hover {
    color: var(--sync-blue);
  }
`

const StyledIonGrid = styled(IonGrid)`
  max-width: var(--page-max-size);
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 200;
  padding: 10px 25px 0;
  left: -50%;
  right: -50%;
  ion-col {
    padding-bottom: 0;
  }

  @media (max-width: 768px) {
    box-shadow: rgb(0 0 0 / 25%) 0 2px 4px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
  }
`

const Header = () => {
  const { push } = useIonRouter()
  const { isMobile } = useDisplaySize()

  const [openHelpDialog] = useDialog<HelpDialogHookProps>(HelpDialog)

  const onLogoClick = () => {
    push('/projects')
  }
  return (
    <StyledIonGrid data-testid="Header">
      <ObsoleteBrowserBanner />
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol className="ion-no-padding">
          <StyledImg
            onClick={onLogoClick}
            srcSet={`${LogoImg1x},
        ${LogoImg2x} 2x,
        ${LogoImg3x} 3x`}
            src={LogoImg1x}
            alt="SyncTech logo"
            height="42"
          />
        </IonCol>
        <IonRow className="ion-align-items-center">
          <IonCol className="ion-hide-sm-down">
            <FlexBox direction="row" gap={15}>
              {!isMobile && <SearchForm />}
              <StyledLink to="/projects" color="black">
                Projects
              </StyledLink>
              {/* <StyledLink to="/portfolios" color="black"> */}
              {/*   Portfolios */}
              {/* </StyledLink> */}
              {process.env.REACT_APP_FEATURE_HELP_CENTER === 'true' && (
                <StyledAnchor onClick={openHelpDialog} color="black">
                  Help
                </StyledAnchor>
              )}
            </FlexBox>
          </IonCol>
          <IonRow className="ion-align-items-center">
            <UserInfo />
          </IonRow>
        </IonRow>
      </IonRow>
    </StyledIonGrid>
  )
}

export default Header
