import { gql } from '@apollo/client'

export const addProjectNoteMutation = gql`
  mutation addProjectNote($projectId: ID!, $name: String, $value: String) {
    addProjectAsset(
      projectId: $projectId
      type: custom
      name: $name
      value: $value
    ) {
      id
      type
      name
      value
    }
  }
`
