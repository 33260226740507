import { gql } from '@apollo/client'

export const updateProjectPublicItemsMutation = gql`
  mutation updateProjectPublicItems(
    $projectId: ID!
    $description: Boolean
    $assets: [ID]
  ) {
    updateProjectPublicItems(
      projectId: $projectId
      description: $description
      assets: $assets
    ) {
      id
      sharedLinkEnabled
      shared_link_uuid
      attributes {
        projectPublicItems {
          description
          assets
        }
      }
    }
  }
`
