/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const userProfileQuery = gql`
  query UserProfile($id: ID!) {
    userProfile(id: $id) {
      id
      name
      email
      phone
      avatarUrl
    }
  }
`
