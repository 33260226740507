/* eslint-disable import/no-default-export */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { Button } from '../ui/Button'
import { TextInputField } from '../fields/TextInputField'
import { FlexBox } from '../ui/FlexBox'
import { ProjectAssetNameFormProps } from './ProjectAssetNameForm.types'
import { ErrorComponent } from '../ui/ErrorComponent'

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledStretchTextInputField = styled(TextInputField)`
  align-self: stretch;
`

const ButtonContainer = styled(FlexBox)`
  margin-top: auto;
  @media (max-width: 768px) {
    flex-direction: column-reverse !important;
    justify-content: stretch !important;
  }
`

const ProjectAssetNameForm: React.FC<ProjectAssetNameFormProps> = ({
  validationSchema,
  initialValues,
  onSubmit,
  onDismiss,
  setAllowedToDismiss,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    setAllowedToDismiss(!formik.dirty)
  }, [formik.dirty])

  return (
    <FormikProvider value={formik}>
      <FlexBox
        direction="column"
        fullWidth
        fullHeight
        gap={20}
        justifyContent="flex-start"
      >
        <StyledStretchTextInputField
          name="name"
          type="text"
          label="Title"
          placeholder="Asset title"
          isRequired
          maxLength={255}
        />
        <ErrorMessage name="error" component={ErrorComponent} />
        <ButtonContainer direction="row" fullWidth gap={20}>
          <StyledButton
            color="light"
            expand="block"
            onClick={() => onDismiss()}
          >
            Cancel
          </StyledButton>
          <StyledButton expand="block" onClick={formik.submitForm}>
            Save
          </StyledButton>
        </ButtonContainer>
      </FlexBox>
    </FormikProvider>
  )
}

export default ProjectAssetNameForm
