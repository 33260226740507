import * as yup from 'yup'
import { ProjectDigitalTwinAddFormFields } from './ProjectDigitalTwinDialog.types'

export const captur3DRegex = /^https:\/\/capture\.synctech\.io\/*/
export const matterportRegex = /^https:\/\/my\.matterport\.com\/*/

export const validationSchema = yup.object({
  name: yup.string().max(255).required('Title is required'),
  value: yup
    .string()
    .required('Digital Twin Link is required')
    .ensure()
    .test(
      'is-digital-twin-link',
      'Must be valid Captur3D or Matterport link',
      (value) => {
        const isCaptur3DLink = captur3DRegex.test(value)
        const isMatterportLink = matterportRegex.test(value)

        return isCaptur3DLink || isMatterportLink
      },
    )
    .max(255),
})

export const initialValues: ProjectDigitalTwinAddFormFields = {
  name: '',
  value: '',
  error: null,
}
