/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const changeProjectUserRole = gql`
  mutation changeProjectUserRole(
    $projectId: ID!
    $userId: ID!
    $newRoleId: Int!
  ) {
    changeProjectUserRole(
      projectId: $projectId
      userId: $userId
      newRoleId: $newRoleId
    ) {
      id
    }
  }
`
