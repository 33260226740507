import { useMutation } from '@apollo/client'
import prettyBytes from 'pretty-bytes'
import { useEffect, useState } from 'react'
import { useProjectContext } from '../../contexts/project'
import { vimeoClient } from '../../helpers/vimeoAPI'
import {
  fileAssetFileMaxSize,
  videoAssetFileMaxSize,
} from '../../pages/ProjectPage/ProjectPage.constants'
import {
  assetsTypesMapping,
  MaxFileSize,
  Project,
  ProjectAsset,
  ProjectAssetsTypes,
} from '../../pages/ProjectPage/ProjectPage.types'
import {
  addFileAssetMutation,
  addVideoAssetMutation,
} from './FileUpload.graphql'
import { ProjectFileAsset } from './FileUpload.types'

const validateFileSize = (
  fileSize: number,
  maxFileSize: MaxFileSize,
): string | undefined => {
  if (fileSize > maxFileSize.binary) {
    return `File you trying to upload exceeds the maximum allowed file size of ${prettyBytes(
      maxFileSize.simple,
    )}`
  }
  return undefined
}

export const useFileUpload = (fileAsset: ProjectFileAsset) => {
  const { projectId, updateQuery } = useProjectContext()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [invalid, setInvalid] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [abort, setAbort] = useState<null | Function>(null)
  const [videoUploading, setVideoUploading] = useState<boolean>(false)
  const { file, type } = fileAsset

  const [uploadFile, { loading }] = useMutation(addFileAssetMutation, {
    variables: {
      file,
      type,
      projectId,
    },
    context: {
      fetchOptions: {
        useCancel: true,
        onAbortPossible: (abortHandler: any) => {
          setAbort(() => abortHandler)
        },
      },
    },
  })

  const onFileUpload = async () => {
    // File validation process
    const validationErrorMessage = validateFileSize(
      fileAsset.file.size,
      fileAssetFileMaxSize,
    )
    if (validationErrorMessage) {
      setInvalid(true)
      setErrorMessage(validationErrorMessage)
      setLoaded(false)
      return
    }

    setInvalid(false)
    setLoaded(false)
    try {
      const {
        data: { addProjectAsset },
      } = await uploadFile()

      updateQuery(({ project }: { project: Project }) => {
        // @ts-ignore
        const assetType = assetsTypesMapping[addProjectAsset.type]
        // @ts-ignore Object is possibly 'undefined'
        const editedAssets: [ProjectAsset] = project?.assets[assetType]

        const result = {
          ...project,
          assets: {
            ...project.assets,
            [assetType]: [...editedAssets, addProjectAsset],
          },
        }
        return {
          project: result,
        }
      })

      setLoaded(true)
    } catch (err) {
      // @ts-ignore
      setErrorMessage(err?.error || err?.message)
      setInvalid(true)
    }
  }

  // used for video upload
  const [addVideoAsset] = useMutation(addVideoAssetMutation)

  const onVideoUpload = async () => {
    const { file } = fileAsset

    // File validation process
    const validateResult = validateFileSize(file.size, videoAssetFileMaxSize)
    if (validateResult) {
      setInvalid(true)
      setErrorMessage(validateResult)
      setLoaded(false)
      return
    }

    setInvalid(false)
    setLoaded(false)
    setVideoUploading(true)
    try {
      const name = file.name.substring(0, file.name.lastIndexOf('.'))

      // Set video upload params
      const params = {
        name,
        privacy: {
          embed: 'whitelist',
          view: 'disable',
        },
        ...(process.env.REACT_APP_VIMEO_FOLDER_ID && {
          folder_uri: `/me/projects/${process.env.REACT_APP_VIMEO_FOLDER_ID}`,
        }),
      }

      // Upload a video to Vimeo
      vimeoClient.upload(
        file,
        params,
        async (uri: string) => {
          const {
            data: { addProjectAsset },
          } = await addVideoAsset({
            variables: {
              name,
              value: uri,
              projectId,
              type: ProjectAssetsTypes.VIDEO,
            },
          })

          updateQuery(({ project }: { project: Project }) => {
            // @ts-ignore
            const assetType = assetsTypesMapping[addProjectAsset.type]
            // @ts-ignore Object is possibly 'undefined'
            const editedAssets: [ProjectAsset] = project?.assets[assetType]

            const result = {
              ...project,
              assets: {
                ...project.assets,
                [assetType]: [...editedAssets, addProjectAsset],
              },
            }
            return {
              project: result,
            }
          })

          setVideoUploading(false)
          setLoaded(true)
        },
        () => {},
        (error) => {
          // @ts-ignore
          setErrorMessage(error)
          setInvalid(true)
        },
      )
    } catch (err) {
      // @ts-ignore
      setErrorMessage(err?.error || err?.message)
      setInvalid(true)
      setVideoUploading(false)
    }
  }

  const onUploadAbort = () => {
    if (abort) {
      abort()
      setInvalid(true)
      setErrorMessage('File upload has been aborted')
    }
  }

  useEffect(
    () => () => {
      if (abort) {
        abort()
      }
    },
    [abort],
  )

  return type === ProjectAssetsTypes.VIDEO
    ? {
        uploadFile: onVideoUpload,
        loading: videoUploading,
        loaded,
        invalid,
        onUploadAbort: () => {}, // do nothing for now
        errorMessage,
      }
    : {
        uploadFile: onFileUpload,
        loading,
        loaded,
        invalid,
        onUploadAbort,
        errorMessage,
      }
}
