import * as yup from 'yup'

export const validationSchema = yup.object({
  name: yup.string().max(255).required('Name is required'),
  email: yup.string().email().max(255).required('Email is required'),
  phone: yup
    .string()
    .matches(/^\+\d*$/, 'Phone number must contain only digits')
    .max(15)
    .nullable(),
})

export const phoneBookingRequiredSchema = yup.object({
  phone: yup
    .string()
    .matches(/^\+\d*$/, 'Phone number must contain only digits')
    .max(15)
    .required('To complete a booking, please provide your phone number.')
    .nullable(),
})
