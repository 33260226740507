/* eslint-disable import/no-default-export */
import { IonAlert } from '@ionic/react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Dialog } from '../Dialog'
import { DialogWithAlertProps } from './DialogWithAlert.types'

const DialogWithAlert: React.VFC<DialogWithAlertProps> = ({
  dialogTitle,
  alertHeader = 'Are you sure?',
  alertMessage,
  alertCancelLabel = 'Cancel',
  alertConfirmLabel = 'Yes',
  content: Content,
  onDismiss,
}) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [allowedToDismiss, setAllowedToDismiss] = useState<boolean>(false)
  const [callbackAfterDismiss, setCallbackAfterDismiss] = useState<Function>()

  const history = useHistory<string>()

  // alert state management helpers
  const openAlert = () => setAlertOpen(true)

  const closeAlert = () => {
    setCallbackAfterDismiss(undefined)
    setAlertOpen(false)
  }

  const handleDismiss = () => {
    if (!allowedToDismiss) {
      openAlert()
    } else {
      onDismiss()
    }
  }

  const onAlertConfirm = () => {
    if (callbackAfterDismiss !== undefined) {
      callbackAfterDismiss()
    }
    onDismiss()
  }

  useEffect(() => {
    const handleEscapePress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        // Because opening and closing alert makes sense only if
        // validation fails or files upload is in progress,
        if (!allowedToDismiss) {
          setAlertOpen(!alertOpen)
          // This matter because we don't want Dialogue's listeners to trigger onDismiss callback
          e.stopPropagation()
        } else {
          onDismiss()
        }
      }
    }
    document.addEventListener('keydown', handleEscapePress)
    return () => {
      document.removeEventListener('keydown', handleEscapePress)
    }
  }, [allowedToDismiss, alertOpen])

  // react-router history change handler
  // prevents navigation if dialog is open
  useEffect(() => {
    const unblock = history.block(
      // @ts-ignore
      ({ pathname }) => {
        // if callback is set we don't use history block
        if (!allowedToDismiss && callbackAfterDismiss === undefined) {
          // why we use this construction () => () => {:
          // if argument for setter is function, it uses function to modify current value.
          // See https://reactjs.org/docs/hooks-reference.html#functional-updates for the reference.
          // In our case we return function as a result of functional update.
          setCallbackAfterDismiss(() => () => {
            // To alter navigation path we need to sequentially call unblock() and history.push
            // This solution allows us to pass the reference to unblock() and current path to the
            // onAlertConfirm callback
            unblock()
            history.push(pathname)
          })
          openAlert()
          return false
        }
        onDismiss()
        return true
      },
    )
    return () => {
      unblock()
    }
  }, [allowedToDismiss, history])

  return (
    <Dialog
      title={dialogTitle}
      backdropDismiss={false}
      onDismiss={handleDismiss}
      forceDismiss={onDismiss}
    >
      <Content
        onDismiss={handleDismiss}
        forceDismiss={onDismiss}
        allowedToDismiss={allowedToDismiss}
        setAllowedToDismiss={setAllowedToDismiss}
      />
      <IonAlert
        isOpen={alertOpen}
        header={alertHeader}
        message={alertMessage}
        cssClass="sync-alert"
        buttons={[
          {
            text: alertCancelLabel,
            role: 'cancel',
            handler: closeAlert,
          },
          {
            text: alertConfirmLabel,
            handler: onAlertConfirm,
            cssClass: 'alert-button__confirm',
          },
        ]}
      />
    </Dialog>
  )
}

export default DialogWithAlert
