/* eslint-disable import/no-default-export */
import { ProjectProvider, useProjectContext } from '../../../contexts/project'
import { FileAssetsUploadForm } from '../../FileAssetsUploadForm'
import { FileAssetsUploadFormProps } from '../../FileAssetsUploadForm/FileAssetsUploadForm.types'
import { useDialog } from '../Dialog/Dialog.hooks'
import { DialogWithAlert } from '../DialogWithAlert'
import { DialogWithAlertHookProps } from '../DialogWithAlert/DialogWithAlert.types'
import { PopoverItem } from '../PopoverItem'
import { FileUploadPopoverItemProps } from './FileUploadPopoverItem.types'

const FileUploadPopoverItem: React.FC<FileUploadPopoverItemProps> = ({
  children,
  type,
}) => {
  const { projectId, updateQuery } = useProjectContext()

  const Content = (props: FileAssetsUploadFormProps) => (
    <ProjectProvider value={{ projectId, updateQuery }}>
      <FileAssetsUploadForm {...props} type={type} />
    </ProjectProvider>
  )

  const [openDialog] = useDialog<DialogWithAlertHookProps>(DialogWithAlert, {
    dialogTitle: 'Upload',
    alertHeader: 'Close upload window?',
    alertMessage:
      'If you close this dialogue the upload will be cancelled and any pending files will not be saved. Select confirm to continue. Cancel to stay on the current page.',
    alertConfirmLabel: 'Confirm',
    content: Content,
  })
  return <PopoverItem onClick={openDialog}>{children}</PopoverItem>
}

export default FileUploadPopoverItem
