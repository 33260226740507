import { ProjectRoleIds } from '@synctech/portal-types'
/* eslint-disable import/no-default-export */
import { availableProjectRoles } from '../ProjectUsersDialog/ProjectUsersDialog.constants'
import { FlexBox } from '../ui/FlexBox'
import { UserAssignRoleItem } from '../UserAssignRoleItem'
import { UserAssignRoleMenuProps } from './UserAssignRoleMenu.types'

const UserAssignRoleMenu = ({
  listedUserId,
  listedUserRoleId,
  permissions,
  projectId,
}: UserAssignRoleMenuProps) => (
  <FlexBox alignItems="stretch" dataTestid="UserAssignRoleMenu">
    {availableProjectRoles.map(([projectRoleIdString, roleName]) => (
      <UserAssignRoleItem
        key={projectRoleIdString}
        projectRoleId={Number(projectRoleIdString) as ProjectRoleIds}
        projectId={projectId}
        listedUserId={listedUserId}
        listedUserRoleId={listedUserRoleId}
        permissions={permissions}
        roleName={roleName}
      />
    ))}
  </FlexBox>
)

export default UserAssignRoleMenu
