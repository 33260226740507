/* eslint-disable import/no-default-export */
import React from 'react'
import styled from 'styled-components'
import { IonItem, IonText } from '@ionic/react'
// @ts-ignore: No types are declared for the package
import ReadMoreReact from 'read-more-react'

import { useDisplaySize } from '../../helpers/useDisplaySize'
import { ResponsiveDescriptionProps } from './ResponsiveDescription.types'

const StyledIonText = styled(IonText)`
  line-height: 24px;
  white-space: pre-wrap;

  @media (max-width: 768px) {
    line-height: 18px;
  }
`

const StyledReadMore = styled.div`
  && {
    & .read-more-button {
      display: inline;
      text-decoration: underline;
      cursor: pointer;
    }
    & .read-more-button:before {
      content: '... ';
      text-decoration: none;
      display: inline-block;
      margin-right: 5px;
    }
  }
`
const ResponsiveDescription: React.FC<ResponsiveDescriptionProps> = ({
  description,
}) => {
  const { isMobile } = useDisplaySize()

  if (!description) {
    return (
      <IonItem lines="none">
        <StyledIonText>No description</StyledIonText>
      </IonItem>
    )
  }

  return isMobile ? (
    <StyledReadMore>
      <StyledIonText>
        <ReadMoreReact
          text={description}
          min={180}
          ideal={215}
          max={230}
          readMoreText="(Read more)"
        />
      </StyledIonText>
    </StyledReadMore>
  ) : (
    <StyledIonText>{description}</StyledIonText>
  )
}
export default ResponsiveDescription
