import { gql } from '@apollo/client'

export const addProjectMutation = gql`
  mutation addProject(
    $organisation_id: ID!
    $description: String
    $thumbnail: Upload
    $title: String
    $address: String!
    $status: String!
    $reference_id: String
    $portfolio_id: ID
  ) {
    addProject(
      organisation_id: $organisation_id
      thumbnail: $thumbnail
      description: $description
      title: $title
      address: $address
      status: $status
      reference_id: $reference_id
      portfolio_id: $portfolio_id
    ) {
      id
      thumbnail
      thumbnailUrl
      address
      status
      permissions
      usersPreview {
        id
        name
        avatarUrl
      }
    }
  }
`
