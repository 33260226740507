import { createContext, useContext } from 'react'

export interface IPopoverContext {
  openPopover: any
  closePopover: any
}
export const PopoverContext = createContext<IPopoverContext>({
  openPopover: () => {},
  closePopover: () => {},
})

export const usePopoverContext = () =>
  useContext<IPopoverContext>(PopoverContext)
