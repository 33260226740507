import { gql } from '@apollo/client'

export const disableSharedLinkMutation = gql`
  mutation disableSharedLink($projectId: ID!) {
    disableProjectSharedLink(projectId: $projectId) {
      id
      sharedLinkEnabled
      shared_link_uuid
    }
  }
`

export const getReportQuery = gql`
  query getCaptureReport($projectId: ID!) {
    captureReport(projectId: $projectId)
  }
`
