import { ProjectDigitalTwinFullScreenProps } from './ProjectDigitalTwinFullScreen.types'
import { captur3DRegex } from '../ProjectDigitalTwinDialog/ProjectDigitalTwinForm.constants'
import { FlexBox } from '../ui/FlexBox'
import styles from './ProjectDigitalTwinFullScreen.module.css'

function ProjectDigitalTwinFullScreen({
  digitalTwinAsset,
}: ProjectDigitalTwinFullScreenProps) {
  const valid = captur3DRegex.test(digitalTwinAsset.value)

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {valid ? (
        <iframe
          width="100%"
          className={styles.viewer}
          src={digitalTwinAsset.value}
          frameBorder="0"
          allow="xr-spatial-tracking"
          allowFullScreen
          title={digitalTwinAsset.name}
        />
      ) : (
        <FlexBox fullHeight fullWidth>
          <FlexBox className={styles.wrapper}>
            <h2 className={styles.header}>Cannot load Digital Twin asset.</h2>
            <div className={styles.message}>
              You have insufficient permissions to view this page. If you
              believe this is an error, please contact support or your local
              administrator.
            </div>
          </FlexBox>
        </FlexBox>
      )}
    </>
  )
}

export default ProjectDigitalTwinFullScreen
