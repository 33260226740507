/* eslint-disable import/no-default-export */
import { IonCol, IonImg } from '@ionic/react'

import { ScrollToContainer } from '../ui/ScrollToContainer'
import { ProjectImagesProps } from './ProjectImages.types'
import { MediaItemCard } from '../ui/Card'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ProjectAsset } from '../../pages/ProjectPage/ProjectPage.types'
import { hasPermission } from '../../helpers/casl'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ImagePreviewDialogHookProps } from '../ImagePreviewDialog/ImagePreviewDialog.types'
import { ImagePreviewDialog } from '../ImagePreviewDialog'
import { withCallbackTracker } from '../../hocs/withTracking'

import MediaItemList from '../ui/Card/MediaItemList'

const ProjectImages = ({ images }: ProjectImagesProps) => {
  if (!images?.length) {
    return null
  }
  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const editMenu = hasPermission(project, [
    'DeleteProjectAssets',
    'EditProjectAssets',
  ])
    ? (image: ProjectAsset) => <AssetPopoverMenu asset={image} />
    : () => null

  return (
    <ScrollToContainer name="project-images" data-testid="ProjectImages">
      <MediaItemList title="Images" itemCount={images?.length}>
        {images.map((image, index) => {
          const [openImageDialog] = useDialog<ImagePreviewDialogHookProps>(
            ImagePreviewDialog,
            {
              requestedId: index,
              projectId,
              imageList: images,
            },
          )
          return (
            <IonCol
              className="cardContainer"
              key={image.id}
              size="5"
              sizeLg="6"
              sizeXl="4"
            >
              <MediaItemCard
                editMenu={() => editMenu(image)}
                title={image.name}
              >
                <IonImg
                  src={image.value}
                  alt={image.name}
                  className="cardThumbnail"
                  onClick={withCallbackTracker(
                    openImageDialog,
                    'FE - Asset View',
                    {
                      Asset_Type: 'Image',
                      Asset_Name: image.name,
                      Project_ID: projectId,
                      Project_Name: project.title,
                      Project_Address: project.address,
                    },
                  )}
                />
              </MediaItemCard>
            </IonCol>
          )
        })}
      </MediaItemList>
    </ScrollToContainer>
  )
}

export default ProjectImages
