/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const projectListQuery = gql`
  query Projects(
    $organisationId: ID!
    $searchQuery: String
    $orderField: String
    $orderDirection: String
    $offset: Int
    $limit: Int
  ) {
    projects(
      organisationId: $organisationId
      searchQuery: $searchQuery
      orderField: $orderField
      orderDirection: $orderDirection
      offset: $offset
      limit: $limit
    ) {
      id
      thumbnailUrl
      address
      status
      permissions
      usersPreview {
        id
        name
        avatarUrl
      }
    }
  }
`
