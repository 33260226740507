/* eslint-disable import/no-default-export */
import { IonText } from '@ionic/react'
import styled from 'styled-components'
import { AvatarStackProps } from './AvatarStack.types'
import defaultAvatar from '../../../assets/default-avatar.svg'

const AvatarContainer = styled.div`
  cursor: pointer;
`

const AvatarListWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled.img`
  border: 1px solid white;
  border-radius: 11px;
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-left: -7px;
  background-color: white;

  &:first-child {
    margin-left: 0;
  }
`

const StyledIonText = styled(IonText)`
  text-decoration: underline;
  font-size: 12px;
`
const AvatarStack = ({ users, itemsCount, onClick }: AvatarStackProps) => (
  <AvatarContainer onClick={onClick} data-testid="AvatarStack">
    <AvatarListWrapper>
      {users.slice(0, itemsCount).map((user) => (
        <Avatar
          key={user.id}
          src={user.avatarUrl || defaultAvatar}
          alt={user.name}
          title={user.name}
        />
      ))}
    </AvatarListWrapper>
    {itemsCount && <StyledIonText>{`${itemsCount} people`}</StyledIonText>}
  </AvatarContainer>
)

export default AvatarStack
