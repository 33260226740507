import { useState } from 'react'
/* eslint-disable import/no-default-export */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
} from '@ionic/react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { Header } from '../../components/Header'
import { ProjectListAddPopoverMenu } from '../../components/ProjectListAddPopoverMenu'
import { ProjectMobileControls } from '../../components/ProjectMobileControls'
import { ProjectsList } from '../../components/ProjectsList'
import { ProjectListProvider } from '../../contexts/projectList'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { useProjectListQuery } from './ProjectListPage.hooks'
import { useOrganisationContext } from '../../contexts/organisation'
import { ProjectListDesktopControls } from '../../components/ProjectListDesktopControls'

const StyledIonGrid = styled(IonGrid)`
  && {
    width: 100%;
    margin: auto;
    max-width: var(--page-max-size);
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
  }
`
// Placeholder for the left scroll menu
const Spacer = styled.div`
  min-width: 100px;
  width: 188px;
  @media (max-width: 1140px) {
    width: 100%;
  }
`
const ContainerRow = styled(IonRow)`
  max-width: 1440px;
  margin: 0 auto;
`

const StyledContent = styled.div`
  overflow: scroll;
  height: 100%;
  padding-top: 60px;
`

const StyledPageContainer = styled.div`
  overflow: hidden;
  height: 100%;
`

const StyledMobileControlsContainer = styled.div`
  margin-top: 112px;
`

const ProjectPage = ({ location }: RouteComponentProps<{}>) => {
  const params = new URLSearchParams(location.search)
  const searchQuery = params.get('q')?.trim() || undefined
  const orderField = params.get('o')?.trim() || undefined
  const orderDirection = params.get('od')?.trim() || undefined

  const { organisationId } = useOrganisationContext()

  const {
    projectList = [],
    loading,
    updateQuery,
    fetchMore,
  } = useProjectListQuery(
    organisationId,
    searchQuery,
    orderField,
    orderDirection,
  )

  const { isMobile } = useDisplaySize()
  const [hasMore, setHasMore] = useState(true)

  const InfiniteScroll = () => {
    const onInfinite = async () => {
      const { data } = await fetchMore({
        variables: {
          organisationId,
          searchQuery,
          orderField,
          orderDirection,
          offset: projectList.length,
        },
      })

      if (data.projects.length === 0) {
        setHasMore(false)
      }
    }

    return (
      <IonInfiniteScroll
        onIonInfinite={onInfinite}
        threshold="120px"
        disabled={!hasMore}
      >
        <IonInfiniteScrollContent loadingSpinner="lines-small" />
      </IonInfiniteScroll>
    )
  }

  return (
    <IonPage data-testid="ProjectsListPage">
      <StyledPageContainer>
        <Header />
        <StyledContent id="scroll-container">
          <ProjectListProvider value={{ updateQuery, fetchMore }}>
            <IonContent>
              <StyledIonGrid>
                <ContainerRow
                  className="ion-justify-content-center"
                  data-testid="ProjectsList"
                >
                  {!isMobile && (
                    <IonCol size="auto">
                      <Spacer />
                    </IonCol>
                  )}
                  <ProjectsList projectList={projectList} loading={loading} />
                  {!isMobile && (
                    <IonCol size="auto">
                      <ProjectListDesktopControls />
                    </IonCol>
                  )}
                </ContainerRow>
              </StyledIonGrid>
              <InfiniteScroll />
              {isMobile && (
                <>
                  <StyledMobileControlsContainer />
                  <ProjectMobileControls
                    menuItems={ProjectListAddPopoverMenu}
                  />
                </>
              )}
            </IonContent>
          </ProjectListProvider>
        </StyledContent>
      </StyledPageContainer>
    </IonPage>
  )
}

export default ProjectPage
