import { gql } from '@apollo/client'

export const registerMutation = gql`
  mutation register(
    $name: String
    $password: String
    $token: String
    $email: String
  ) {
    register(name: $name, password: $password, token: $token, email: $email) {
      isDone
    }
  }
`
