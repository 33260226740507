import * as yup from 'yup'
import { ProjectNoteAddFormFields } from './ProjectNoteDialog.types'

export const validationSchema = yup.object({
  name: yup.string().max(255).required('Title is required'),
  value: yup.string().max(2000).required('Description is required'),
})

export const initialValues: ProjectNoteAddFormFields = {
  name: '',
  value: '',
  error: null,
}
