import { useMutation } from '@apollo/client'
import { changePortfolioUserRole } from './PortfolioUserAssignRoleItem.graphql'
import { portfolioUsersQuery } from '../PortfolioUsersDialog/PortfolioUsersDialog.graphql'

export const useChangePortfolioUserRole = (
  portfolioId: number,
  userId: number,
  newRoleId: number,
) => {
  const [changeUserRole, { loading }] = useMutation(changePortfolioUserRole, {
    variables: {
      portfolioId,
      userId,
      newRoleId,
    },
    refetchQueries: [
      {
        query: portfolioUsersQuery,
        variables: { id: portfolioId.toString() },
      },
    ],
  })

  return { changeUserRole, loading }
}
