import { useQuery } from '@apollo/client'
import { userProfileQuery } from './ProfilePage.graphql'
import { UserProfileQueryResult } from './ProfilePage.types'

export const useUserProfile = (id: number): UserProfileQueryResult => {
  const { data, loading, error } = useQuery(userProfileQuery, {
    variables: {
      id,
    },
  })

  return {
    user: data?.userProfile,
    loading,
    error,
  }
}
