import { Storage } from '@ionic/storage'
import React, { createContext, useContext, useEffect } from 'react'

interface StorageContext {
  store?: Storage
}

const Context = createContext<StorageContext>({
  store: undefined,
})

export const useStorageContext = () => useContext<StorageContext>(Context)

export const StorageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const store = new Storage()
  useEffect(() => {
    ;(async () => {
      await store.create()
    })()
  }, [])

  return <Context.Provider value={{ store }}>{children}</Context.Provider>
}
