import { ProjectRoleIds } from './project'

export const portfolioActions = [
  // PORTFOLIO
  'ViewAssignedPortfolios',
  'EditPortfolioDetails',
  'DeletePortfolio',
  // PROJECT
  'ViewAllProjectsInPortfolio',
  'AddPortfolioProjects',
  'RemovePortfolioProjects',
  // PORTFOLIO USERS
  'ViewPortfolioUsers',
  'AddPortfolioUser',
  'EditPortfolioUserPermission',
  'DeletePortfolioUsers',
  // PROJECT USERS ROLES
  'ManagePortfolioAdminRole',
  'ManagePortfolioContributorRole',
  'ManagePortfolioMemberRole',
  'ManagePortfolioGuestRole',
  'ManagePortfolioTestNoPermissionsRole',
] as const

export const portfolioRoles = {
  1: 'admin',
  2: 'contributor',
  3: 'member',
  4: 'guest',
  5: 'testNoPermissions',
}

export type PortfolioRoleIds = keyof typeof portfolioRoles
export type PortfolioRoleNames = typeof portfolioRoles[PortfolioRoleIds]

type PortfolioRolesMapType = {
  [key in PortfolioRoleNames]: PortfolioRoleIds
}

export const portfolioRolesMap: PortfolioRolesMapType = {
  admin: 1,
  contributor: 2,
  member: 3,
  guest: 4,
  testNoPermissions: 5,
}

export const portfolioPermissions: {
  [key in PortfolioRoleIds]: typeof portfolioActions[number]
} = {
  1: 'ManagePortfolioAdminRole',
  2: 'ManagePortfolioContributorRole',
  3: 'ManagePortfolioMemberRole',
  4: 'ManagePortfolioGuestRole',
  5: 'ManagePortfolioTestNoPermissionsRole',
}

export const portfolioToProjectRolesMap: {
  [key in PortfolioRoleIds]: ProjectRoleIds
} = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
} as const
