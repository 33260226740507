import { ApolloError, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { projectQuery } from './ProjectPage.graphql'
import {
  Project,
  ProjectAssetsCollectionsTypes,
  ProjectDetailsQuery,
} from './ProjectPage.types'
import { unpackPermissions } from '../../helpers/casl'
import { ScrollMenuItem } from '../../contexts/scrollMenu.types'

export const useProjectQuery = (
  props: ProjectDetailsQuery,
): {
  project: Project
  loading: boolean
  updateQuery: any
  error?: ApolloError
} => {
  const { data, loading, error, updateQuery } = useQuery(projectQuery, {
    variables: { id: props.id },
  })

  return {
    project: {
      ...(data && data.project),
      permissions: unpackPermissions(data?.project?.permissions),
    },
    error,
    loading,
    updateQuery,
  }
}

export const useCountableMenu = (
  menu: ScrollMenuItem[],
  project: Project,
): ScrollMenuItem[] =>
  useMemo<ScrollMenuItem[]>(
    () =>
      // iterate over menu and add count to each item
      menu.map((item) => {
        const assetName = item.id.split('-')[1]

        // skip counting for description, makes no sense
        if (assetName === 'description') {
          return item
        }

        // for DigitalTwin section
        if (assetName === 'captur3d') {
          return {
            ...item,
            count:
              (project.assets?.captur3d?.length || 0) +
              (project.assets?.matterport?.length || 0) +
              (project.assets?.self_hosted_tour?.length || 0),
          }
        }

        // for Aerial section
        if (assetName === 'pix4d') {
          return {
            ...item,
            count:
              (project.assets?.pix4d?.length || 0) +
              (project.assets?.nearmap?.length || 0),
          }
        }

        let count
        if (project.assets) {
          count =
            project.assets[assetName as ProjectAssetsCollectionsTypes]?.length
        }

        return {
          ...item,
          count,
        }
      }),
    [menu, project],
  )
