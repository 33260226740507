/* eslint-disable import/no-default-export */
import {
  IonCardContent,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonText,
} from '@ionic/react'
import styled from 'styled-components'
import { CardEditButton } from '../CardEditButton'
import { CardTitleProps, ItemCardProps } from './ItemCard.types'
import ShadowCard from './ShadowCard.component'
import { AssetPopoverMenu } from '../../AssetPopoverMenu'
import { useProjectContext } from '../../../contexts/project'
import { useProjectQuery } from '../../../pages/ProjectPage/ProjectPage.hooks'
import { ProjectAsset } from '../../../pages/ProjectPage/ProjectPage.types'
import { hasPermission } from '../../../helpers/casl'

const StyledIonCard = styled(ShadowCard)`
  margin: 10px 0;
  width: 100%;
`

const StyledIonCardContent = styled(IonCardContent)`
  && {
    padding: 15px 5px 15px 15px;
    @media (max-width: 768px) {
      padding: 10px 0 10px 10px;
    }
  }
`

const StyledIonText = styled(IonText)`
  line-height: 24px !important;

  a {
    color: black;
    text-decoration: none;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px !important;
    a {
      font-size: 12px;
    }
  }
`

const StyledBoldIonText = styled(StyledIonText)`
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledLinkIonIcon = styled(IonIcon)`
  color: var(--sync-blue);
  margin: 0 8px 0 0;
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 7px;
`

const StyledIonItem = styled(IonItem)`
  --inner-padding-end: 0;
  ::part(native) {
    min-height: 10px;
  }
`

const CardTitle = ({ title, url, onClick }: CardTitleProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!url) return <>{title}</>

  return (
    <a href={url} target="_blank" rel="noreferrer" onClick={onClick}>
      {title}
    </a>
  )
}

const ItemCard = ({
  asset,
  title,
  url,
  description,
  icon,
  onTitleClick,
}: ItemCardProps) => {
  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const editMenu = hasPermission(project, [
    'DeleteProjectAssets',
    'EditProjectAssets',
  ])
    ? (asset: ProjectAsset) => <AssetPopoverMenu asset={asset} />
    : () => null

  return (
    <StyledIonCard>
      <StyledIonCardContent>
        <StyledIonItem lines="none" className="ion-no-padding">
          {icon && <StyledLinkIonIcon size="small" slot="start" icon={icon} />}
          <IonItemGroup>
            <StyledIonItem
              lines="none"
              className="ion-no-padding ion-no-margin"
            >
              <StyledBoldIonText>
                <CardTitle title={title} url={url} onClick={onTitleClick} />
              </StyledBoldIonText>
            </StyledIonItem>
            {description && (
              <StyledIonItem
                lines="none"
                className="ion-no-padding ion-no-margin"
              >
                <StyledIonText>{description}</StyledIonText>
              </StyledIonItem>
            )}
          </IonItemGroup>
          <CardEditButton editMenu={() => editMenu(asset)} />
        </StyledIonItem>
      </StyledIonCardContent>
    </StyledIonCard>
  )
}
export default ItemCard
