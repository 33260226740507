/* eslint-disable import/no-default-export */
import { useEffect, useRef } from 'react'
import { IonAvatar, useIonAlert } from '@ionic/react'
import styled from 'styled-components'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { useDisplaySize } from '../../helpers/useDisplaySize'
import { FlexBox } from '../ui/FlexBox'
import { UserProfileProps } from './UserProfile.types'
import { Title } from '../ui/Title'
import defaultAvatar from '../../assets/default-avatar.svg'
import { TextInputField } from '../fields/TextInputField'
import { Button } from '../ui/Button'
import {
  useDeleteUserAvatar,
  useEditUserProfile,
  useUpdateUserAvatar,
} from './UserProfileEdit.hooks'
import { ErrorComponent } from '../ui/ErrorComponent'
import {
  phoneBookingRequiredSchema,
  validationSchema,
} from './UserProfileEdit.constants'
import { PhoneInputField } from '../fields/PhoneInputField'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ChangePasswordDialog } from '../ChangePasswordDialog'

const HiddenInput = styled.input`
  display: none;
`

const StyledFlexBox = styled(FlexBox)`
  margin-top: 26px;
`

const SubmitButton = styled(Button)`
  margin-top: 20px;
`

const Avatar = styled(IonAvatar)`
  --border-radius: 100px;
  min-width: 200px;
  min-height: 200px;
  object-fit: cover;
  background-color: white;
`

const AvatarRemoveButton = () => {
  const [present] = useIonAlert()

  const { deleteUserAvatar } = useDeleteUserAvatar()

  const avatarDelete = async () => {
    present({
      cssClass: 'sync-alert',
      header: 'Remove avatar',
      message: 'Are you sure you want to remove your avatar?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove',
          cssClass: 'alert-button__confirm',
          handler: deleteUserAvatar,
        },
      ],
    })
  }

  return (
    <Button color="light" onClick={avatarDelete}>
      Remove
    </Button>
  )
}

const AvatarUploadButton = () => {
  const fileInput = useRef<HTMLInputElement>(null)
  const { updateUserAvatar } = useUpdateUserAvatar()

  const avatarUpload = async (event: any) => {
    if (event.target.validity.valid) {
      await updateUserAvatar(event.target.files[0])
    }
  }

  return (
    <>
      <HiddenInput
        type="file"
        id="file"
        ref={fileInput}
        onChange={avatarUpload}
        accept="image/*"
      />
      <Button
        onClick={() => {
          fileInput?.current?.click()
        }}
      >
        Upload
      </Button>
    </>
  )
}

const UserProfileEdit = ({ user }: UserProfileProps) => {
  const { isMobile } = useDisplaySize()

  const { editUserProfile } = useEditUserProfile()

  const [changePasswordDialogPresent] = useDialog(ChangePasswordDialog)
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)

  const formValidationSchema =
    params.get('fromBooking') === 'true'
      ? validationSchema.concat(phoneBookingRequiredSchema)
      : validationSchema

  const formik = useFormik({
    initialValues: {
      name: user.name || '',
      email: user.email || '',
      phone: user.phone,
    },
    validationSchema: formValidationSchema,
    onSubmit: editUserProfile,
  })

  useEffect(() => {
    formik.validateForm()
  }, [])

  const onFormSubmit = async () => {
    await formik.submitForm()
    if (formik.isValid && params.get('fromBooking') === 'true') {
      history.goBack()
    }
  }

  return (
    <>
      <Title>Profile Details</Title>
      <StyledFlexBox
        alignItems={isMobile ? 'center' : 'flex-start'}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        gap={70}
      >
        <FlexBox alignItems="stretch" gap={15}>
          <Avatar>
            <img
              src={user?.avatarUrl || defaultAvatar}
              alt={user?.name}
              title={user?.name}
            />
          </Avatar>
          <FlexBox alignItems="stretch" gap={10}>
            <AvatarUploadButton />
            <AvatarRemoveButton />
            <div className="ion-margin-top" />
            <Button onClick={changePasswordDialogPresent}>
              Change Password
            </Button>
          </FlexBox>
        </FlexBox>
        <FlexBox alignItems="stretch" fullWidth gap={20}>
          <FormikProvider value={formik}>
            <TextInputField name="name" label="Full Name" />
            <TextInputField name="email" label="Email" />
            <PhoneInputField name="phone" label="Contact Phone" />
            <ErrorMessage name="error" component={ErrorComponent} />
            <SubmitButton onClick={onFormSubmit}>Save</SubmitButton>
          </FormikProvider>
        </FlexBox>
      </StyledFlexBox>
    </>
  )
}

export default UserProfileEdit
