/* eslint-disable import/no-default-export */
import { IonImg } from '@ionic/react'
import styled from 'styled-components'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ThumbnailedAsset } from '../../pages/ProjectPage/ProjectPage.types'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { MediaItemCard } from '../ui/Card'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { MatterportAssetsCardProps } from './ProjectDigitalTwinAssets.types'
import placeholder from '../../assets/placeholder_captur3d.png'
import { withCallbackTracker } from '../../hocs/withTracking'
import { ProjectMatterportAssetsDialogHookProps } from '../ProjectMatterportAssetsDialog/ProjectMatterportAssetsDialog.types'
import ProjectMatterportAssetsDialog from '../ProjectMatterportAssetsDialog/ProjectMatterportAssetsDialog.component'

const StyledImg = styled(IonImg)`
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 185px;

  @media (max-width: 768px) {
    height: 100px;
  }
`

const MatterportAssetCard = ({ matterport }: MatterportAssetsCardProps) => {
  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const [openDialog] = useDialog<ProjectMatterportAssetsDialogHookProps>(
    ProjectMatterportAssetsDialog,
    {
      matterportAsset: matterport,
    },
  )
  const editMenu = project.permissions.includes('DeleteProjectAssets')
    ? (asset: ThumbnailedAsset) => <AssetPopoverMenu asset={asset} />
    : () => null
  return (
    <MediaItemCard
      editMenu={() => editMenu(matterport)}
      title={matterport.name}
    >
      <StyledImg
        onClick={withCallbackTracker(openDialog, 'FE - Asset View', {
          Asset_Type: 'Matterport',
          Asset_Name: matterport.name,
          Project_ID: projectId,
          Project_Name: project.title,
          Project_Address: project.address,
        })}
        src={matterport.previewUrl || placeholder}
        alt={matterport.name}
      />
    </MediaItemCard>
  )
}

export default MatterportAssetCard
