/* eslint-disable import/no-default-export */
import React from 'react'
import { IonItem } from '@ionic/react'
import styled from 'styled-components'
import { usePopoverContext } from '../../../contexts/popover'
import { PopoverItemProps } from './PopoverItem.types'

const StyledIonItem = styled(IonItem)`
  && {
    font-size: 16px;
    font-weight: 700;
    --background-hover: var(--sync-light-blue);
    --background-hover-opacity: 1;
    --background-activated: var(--sync-light-blue);
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`

const PopoverItem: React.FC<PopoverItemProps> = ({
  children,
  onClick = () => {},
  disabled = false,
  ...rest
}) => {
  const { closePopover } = usePopoverContext()

  const onButtonClick = () => {
    onClick()
    closePopover()
  }

  return (
    <StyledIonItem
      lines="none"
      detail={false}
      button
      disabled={disabled}
      onClick={onButtonClick}
      {...rest}
      data-testid="PopoverItem"
    >
      {children}
    </StyledIonItem>
  )
}

export default PopoverItem
