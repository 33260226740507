import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { ProjectLinkAddFormFields } from './ProjectLinkDialog.types'
import { addProjectLinkMutation } from './ProjectLinkAddDialog.graphql'
import {
  Project,
  ProjectAsset,
} from '../../pages/ProjectPage/ProjectPage.types'
import { useNotification } from '../../contexts/notification'

export const useAddProjectLink = (
  projectId: string,
  updateQuery: any,
  onDismiss: () => void,
) => {
  const [addProjectLinkRequest, { loading }] = useMutation(
    addProjectLinkMutation,
  )

  const { notify } = useNotification()

  const addProjectLink = async (
    values: ProjectLinkAddFormFields,
    formikHelpers: FormikHelpers<ProjectLinkAddFormFields>,
  ) => {
    try {
      const newAsset = await addProjectLinkRequest({
        variables: {
          ...values,
          projectId,
        },
      })

      updateQuery(({ project }: { project: Project }) => ({
        project: {
          ...project,
          assets: {
            ...project.assets,
            urls: (project.assets?.urls || ([] as ProjectAsset[]))
              .concat([newAsset.data.addProjectAsset as ProjectAsset])
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
              ),
          },
        },
      }))

      notify('Link successfully added')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    addProjectLink,
    loading,
  }
}
