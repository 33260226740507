/* eslint-disable import/no-default-export */
import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { IonItem, IonText } from '@ionic/react'
import { chevronDownOutline } from 'ionicons/icons'
import { ErrorComponent } from '../../ui/ErrorComponent'
import { Select } from '../../ui/Select'
import { SelectFieldProps } from './SelectField.types'

const StyledIonText = styled(IonText)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    flex: 0 1 fit-content;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  placeholder,
  label,
  className,
  isRequired,
  disabled,
  children,
  ...restProps
}) => {
  /* eslint-disable-next-line operator-linebreak */
  const [{ onChange, onBlur, value, ...rest }, { error, touched }] =
    useField(name)

  return (
    <div className={className}>
      {label && (
        <IonItem className="ion-no-margin ion-no-padding" lines="none">
          <StyledIonText>
            {label}
            {isRequired && '*'}
          </StyledIonText>
        </IonItem>
      )}
      <Select
        onIonChange={onChange}
        onIonBlur={onBlur}
        placeholder={placeholder}
        value={value}
        className={touched && error ? 'error' : ''}
        interface="popover"
        disabled={disabled}
        labelPlacement="floating"
        toggleIcon={chevronDownOutline}
        {...restProps}
        {...rest}
      >
        {children}
      </Select>
      {touched && error && <ErrorComponent>{error}</ErrorComponent>}
    </div>
  )
}

export default SelectField
