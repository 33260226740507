import React, { createContext, useContext } from 'react'

interface PortfolioContext {
  portfolioId: number
  updateQuery: any
}

const Context = createContext<PortfolioContext>({
  portfolioId: 0,
  updateQuery: () => {},
})

export const usePortfolioContext = () => useContext<PortfolioContext>(Context)

export const PortfolioProvider: React.FC<{
  value: PortfolioContext
  children: React.ReactNode
}> = ({ children, value }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
)
