import { gql } from '@apollo/client'

export const addProjectDigitalTwinMutation = gql`
  mutation addProjectDigitalTwin(
    $projectId: ID!
    $name: String
    $value: String
    $type: ProjectAssetsTypes
  ) {
    addProjectAsset(
      projectId: $projectId
      type: $type
      name: $name
      value: $value
    ) {
      id
      type
      name
      value
      ... on ThumbnailedAsset {
        previewUrl
      }
    }
  }
`
