/* eslint-disable import/no-default-export */
import { IonItem } from '@ionic/react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import imagePlaceholder from '../../assets/placeholder_600x400.svg'
import { hasPermission } from '../../helpers/casl'
import { AvatarStack } from '../ui/AvatarStack'
import { MediaItemCard } from '../ui/Card'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { PortfolioProps } from '../../pages/PortfolioPage/PortfolioPage.types'
import { PortfolioUsersDialogHookProps } from '../PortfolioUsersDialog/PortfolioUsersDialog.types'
import { PortfolioUsersDialog } from '../PortfolioUsersDialog'

const StyledIonItemContainer = styled(IonItem)`
  --inner-padding-end: 0;
`

const StyledImg = styled.img`
  && {
    object-fit: cover;
    height: 150px;
    width: 100%;
    display: block;
  }
`

const BottomSlot = ({ portfolio }: PortfolioProps) => {
  // eslint-disable-next-line operator-linebreak
  const [openUserDialog] = useDialog<PortfolioUsersDialogHookProps>(
    PortfolioUsersDialog,
    {
      portfolioId: portfolio?.id,
    },
  )

  return (
    <IonItem lines="none" className="ion-no-padding">
      {hasPermission(portfolio, ['ViewPortfolioUsers']) && (
        <StyledIonItemContainer
          slot="end"
          lines="none"
          className="ion-no-padding ion-no-margin"
        >
          {!!portfolio?.usersPreview?.length && (
            <AvatarStack
              users={portfolio.usersPreview}
              onClick={openUserDialog}
            />
          )}
        </StyledIonItemContainer>
      )}
    </IonItem>
  )
}

const PortfolioInfoCard = ({ portfolio }: PortfolioProps) => (
  <MediaItemCard
    title={portfolio?.name || ''}
    editMenu={() => {}}
    bottomSlot={() => <BottomSlot portfolio={portfolio} />}
  >
    <Link to={`/portfolios/${portfolio?.id}`}>
      <StyledImg src={imagePlaceholder} alt={portfolio?.name} />
    </Link>
  </MediaItemCard>
)

export default PortfolioInfoCard
