/* eslint-disable import/no-default-export */
import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import PhoneInput from 'react-phone-number-input'
import { IonItem, IonText } from '@ionic/react'
import { ErrorComponent } from '../../ui/ErrorComponent'
import { PhoneInputFieldProps } from './PhoneInputField.types'
import { CharacterCounter } from '../../ui/CharacterCounter'
import 'react-phone-number-input/style.css'

const StyledIonText = styled(IonText)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    flex: 0 1 fit-content;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`

const StyledPhoneInput = styled(PhoneInput)`
  && {
    .PhoneInputInput {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;

      border-radius: 8px;

      padding: 10px 10px 10px 16px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      border: 1px solid transparent;
      outline: none;
      margin: 0 0 0 5px;
      z-index: 100;

      background-color: white;
    }

    .PhoneInputCountrySelectArrow {
      border-bottom-style: solid;
      border-bottom-width: 1px;

      border-right-style: solid;
      border-right-width: 1px;
    }

    &.error .PhoneInputInput {
      border-radius: 8px;
      //padding-start: 10.5px;

      box-shadow: 0 0 4px #e34148;
    }

    .PhoneInputInput:focus {
      box-shadow: none;
      border: 1px solid var(--sync-blue);
    }
  }
`

const PhoneInputField: React.FC<PhoneInputFieldProps> = ({
  name,
  placeholder,
  // type,
  label,
  className,
  maxLength,
  isRequired,
  ...restProps
}) => {
  /* eslint-disable-next-line operator-linebreak */
  const [{ value }, { error }, { setValue }] = useField(name)

  return (
    <div className={className}>
      {label && (
        <IonItem className="ion-no-margin ion-no-padding" lines="none">
          <StyledIonText>
            {label}
            {isRequired && '*'}
          </StyledIonText>
          {!!maxLength && (
            <CharacterCounter
              max={maxLength}
              count={value?.length || 0}
              slot="end"
            />
          )}
        </IonItem>
      )}

      <StyledPhoneInput
        placeholder={placeholder}
        value={value}
        defaultCountry="AU"
        withCountryCallingCode
        international
        onChange={setValue}
        {...restProps}
      />
      {error && <ErrorComponent>{error}</ErrorComponent>}
    </div>
  )
}

export default PhoneInputField
