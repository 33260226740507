import * as yup from 'yup'
import YupPassword from 'yup-password'

YupPassword(yup) // extend yup
// The password complexity need to meet the minimum policy requirements defined
// in https://docs.google.com/document/d/1QU3hU_YIGKZeF6RayLDHDhAHubUUv2usQLylPPwdRbg
export const passwordValidator = yup
  .string()
  .required('New Password is required')
  .min(10)
  .minSymbols(1)
  .minNumbers(1)
  .minUppercase(1)
  .minLowercase(1)
  .nullable()
