import React, { createContext, useContext, useState } from 'react'

interface AuthContext {
  isLoggedIn: boolean
  setIsLoggedIn: any
  redirectUrl: string
  setRedirectUrl: any
}

const Context = createContext<AuthContext>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  redirectUrl: '',
  setRedirectUrl: () => {},
})

export const useAuthContext = () => useContext<AuthContext>(Context)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false) // TODO initiate using platform storage
  const [redirectUrl, setRedirectUrl] = useState('')

  return (
    <Context.Provider
      value={{ isLoggedIn, setIsLoggedIn, redirectUrl, setRedirectUrl }}
    >
      {children}
    </Context.Provider>
  )
}
