/* eslint-disable import/no-default-export */
import { checkmarkCircleOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { portfolioPermissions, PortfolioRoleIds } from '@synctech/portal-types'
import { availablePortfolioRoles } from '../../PortfolioUsersDialog/PortfolioUsersDialog.constants'
import { FlexBox } from '../../ui/FlexBox'
import { PopoverItem } from '../../ui/PopoverItem'
import { PortfolioUserSelectRoleMenuProps } from './PortfolioUserSelectRoleMenu.types'

const PortfolioUserSelectRoleMenu = ({
  listedUserRoleId,
  permissions,
  onChange,
}: PortfolioUserSelectRoleMenuProps) => (
  <FlexBox alignItems="stretch">
    {availablePortfolioRoles.map(([portfolioRoleIdString, roleName]) => {
      const portfolioRoleId = parseInt(
        portfolioRoleIdString,
        10,
      ) as PortfolioRoleIds
      const canManageRole = permissions.includes(
        portfolioPermissions[portfolioRoleId],
      )

      const disabled =
        !canManageRole ||
        !permissions.includes(portfolioPermissions[portfolioRoleId])

      return (
        <PopoverItem
          key={portfolioRoleIdString}
          disabled={disabled}
          onClick={() => onChange(portfolioRoleId)}
        >
          {roleName}
          <IonIcon
            slot="end"
            color="primary"
            size="small"
            icon={
              listedUserRoleId === portfolioRoleId
                ? checkmarkCircleOutline
                : undefined
            }
          />
        </PopoverItem>
      )
    })}
  </FlexBox>
)

export default PortfolioUserSelectRoleMenu
