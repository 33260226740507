import { IonIcon, IonLabel } from '@ionic/react'
import { checkmarkOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { PopoverItem } from '../PopoverItem'
import { useMeQuery } from './UserInfo.hooks'
import { useOrganisationContext } from '../../../contexts/organisation'
import SubmenuPopoverItem from '../SubmenuPopoverItem/SubmenuPopoverItem.component'
import { usePopoverContext } from '../../../contexts/popover'

export const UserOrganisationsMenu = () => {
  const { user } = useMeQuery()
  const { organisationId, setOrganisationId } = useOrganisationContext()
  const history = useHistory()
  const { closePopover } = usePopoverContext()
  if (!user) {
    return null
  }

  if (user.organisations.length === 1) {
    return null
  }

  const onOrganisationSelect = (organisationId: number) => {
    closePopover()
    setOrganisationId(organisationId)
    history.push('/projects')
  }

  return (
    <SubmenuPopoverItem title="Switch Organisation">
      {user.organisations?.map((organisation) => (
        <PopoverItem
          onClick={() => onOrganisationSelect(organisation.id)}
          key={organisation.id}
        >
          <IonLabel>{organisation.name}</IonLabel>
          {organisation.id === organisationId && (
            <IonIcon icon={checkmarkOutline} />
          )}
        </PopoverItem>
      ))}
    </SubmenuPopoverItem>
  )
}
