import { useQuery } from '@apollo/client'
import { projectListQuery } from './ProjectListPage.graphql'
import { Project } from '../ProjectPage/ProjectPage.types'
import { unpackPermissions } from '../../helpers/casl'
import { PAGINATION_LIMIT } from './ProjectListPage.constants'

const mapper = (project: any) => ({
  ...project,
  permissions: unpackPermissions(project.permissions),
})

export const useProjectListQuery = (
  organisationId: number | undefined,
  searchQuery?: string,
  orderField?: string,
  orderDirection?: string,
): {
  projectList: Project[]
  loading: boolean
  updateQuery: any
  fetchMore: any
  refetch: any
} => {
  const { data, loading, updateQuery, fetchMore, refetch } = useQuery(
    projectListQuery,
    {
      variables: {
        organisationId,
        searchQuery,
        orderField,
        orderDirection,
        offset: 0,
        limit: PAGINATION_LIMIT,
      },
    },
  )

  return {
    projectList: data && data.projects.map(mapper),
    loading,
    updateQuery,
    fetchMore,
    refetch,
  }
}
