import { Project } from '../pages/ProjectPage/ProjectPage.types'
import { UserInfo } from '../components/ui/UserInfo/UserInfo.types'
import { Portfolio } from '../pages/PortfolioPage/PortfolioPage.types'

export const unpackPermissions = (
  caslPackedPermission: string = '',
): string[] => {
  let result = []
  try {
    if (caslPackedPermission) {
      const caslArray = JSON.parse(caslPackedPermission)
      result = caslArray.map((role: any) => role[0])
    }
  } catch (e) {
    console.error('error parsing permissions response')
  }
  return result
}

export const effectiveOrganisationPermissions = (
  user?: UserInfo,
  organisationId?: number,
): string[] => {
  let result: string[] = []

  if (!user || !organisationId) {
    return result
  }

  try {
    const organisation = user.organisations?.find(
      (organisation) => organisation.id === organisationId,
    )

    result = unpackPermissions(organisation?.permissions)
  } catch (e) {
    console.error('error parsing permissions response')
  }
  return result
}

export const hasPermission = (
  entity: Project | Portfolio,
  permissions: string[],
) => permissions.some((permission) => entity.permissions.includes(permission))
