/* eslint-disable import/no-default-export,prefer-template */
import styled from 'styled-components'
import { ProjectStatusIndicatorProps } from './ProjectStatusIndicator.types'
import { ProjectStatusDecoration } from './ProjectStatusIndicator.constants'

interface StyledDivProps {
  bgColor: string
  progress: number
}

const StyledBgDiv = styled.div`
  min-height: 4px;
  height: 4px;
  width: 100%;
  background-color: #d9d9d9;
`
const StyledDiv = styled.div<StyledDivProps>`
  min-height: 4px;
  height: 4px;
  width: ${(props) => props.progress + '%'};
  background-color: ${(props) => props.bgColor};
  z-index: 100;
`

const ProjectStatusProgress = ({ status }: ProjectStatusIndicatorProps) => (
  <StyledBgDiv>
    <StyledDiv
      bgColor={ProjectStatusDecoration[status].color}
      progress={ProjectStatusDecoration[status].progress}
    />
  </StyledBgDiv>
)

export default ProjectStatusProgress
