import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { ProjectNoteAddFormFields } from './ProjectNoteDialog.types'
import { addProjectNoteMutation } from './ProjectNoteAddDialog.graphql'
import {
  Project,
  ProjectAsset,
} from '../../pages/ProjectPage/ProjectPage.types'
import { useNotification } from '../../contexts/notification'

export const useAddProjectNote = (
  projectId: string,
  updateQuery: any,
  onDismiss: () => void,
) => {
  const [addProjectNoteRequest, { loading }] = useMutation(
    addProjectNoteMutation,
  )

  const { notify } = useNotification()

  const addProjectNote = async (
    values: ProjectNoteAddFormFields,
    formikHelpers: FormikHelpers<ProjectNoteAddFormFields>,
  ) => {
    try {
      const newAsset = await addProjectNoteRequest({
        variables: {
          ...values,
          projectId,
        },
      })

      updateQuery(({ project }: { project: Project }) => ({
        project: {
          ...project,
          assets: {
            ...project.assets,
            custom: (project.assets?.custom || ([] as ProjectAsset[]))
              .concat([newAsset.data.addProjectAsset as ProjectAsset])
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
              ),
          },
        },
      }))

      notify('Note successfully added')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    addProjectNote,
    loading,
  }
}
