import { gql } from '@apollo/client'

export const addPortfolioMutation = gql`
  mutation addPortfolio($organisation_id: ID!, $name: String!) {
    addPortfolio(organisation_id: $organisation_id, name: $name) {
      id
      name
      permissions
      usersPreview {
        id
        name
        avatarUrl
      }
    }
  }
`
