/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import React, { useEffect } from 'react'
import { IonIcon, IonItem, IonModal, IonText } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { close } from 'ionicons/icons'
import { DialogProps } from './Dialog.types'
import { FlexBox } from '../FlexBox'

const StyledHeader = styled(IonItem)`
  box-shadow: rgb(0 0 0 / 25%) 0 2px 4px;
  align-self: stretch;
  text-align: center;
  z-index: 200;
`

const StyledHeaderTitle = styled(IonText)`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
`

const StyledIonIcon = styled(IonIcon)`
  cursor: pointer;
`

const StyledContent = styled.div`
  padding: 25px 20px;
  width: 100%;
  flex: 1;
  overflow: scroll;
`

// If used with Alert component, backdropDismiss must be set to false
// in order to prevent dialog close if we have unsaved changes in a dialog's form
// is too tricky with Ionic backdrop
// ref: https://forum.ionicframework.com/t/modal-dismiss-how-to-abort-dismiss-if-changes-are-pending/188527
const Dialog: React.FC<DialogProps> = ({
  title,
  onDismiss,
  forceDismiss,
  backdropDismiss = true,
  children,
  ...rest
}) => {
  const history = useHistory<string>()

  useEffect(() => {
    const handleEscapePress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && backdropDismiss) {
        onDismiss()
      }
    }
    document.addEventListener('keydown', handleEscapePress)
    return () => {
      document.removeEventListener('keydown', handleEscapePress)
    }
  }, [])

  // react-router history change handler
  // prevents navigation if dialog is open
  useEffect(() => {
    const unblock = history.block(
      // @ts-ignore
      () => {
        // here we disable only simple dialogs without alert on leave
        // special cases are handled in the DialogWithAlert history handler.
        if (!forceDismiss) {
          onDismiss()
        }
        return true
      },
    )
    return () => {
      unblock()
    }
  }, [history, forceDismiss])

  return (
    <IonModal isOpen backdropDismiss={backdropDismiss} {...rest}>
      <FlexBox fullWidth fullHeight>
        <StyledHeader lines="none">
          <StyledHeaderTitle>{title}</StyledHeaderTitle>
          <StyledIonIcon icon={close} slot="end" onClick={onDismiss} />
        </StyledHeader>
        <StyledContent className="sync-modal-content-wrapper">
          {children}
        </StyledContent>
      </FlexBox>
    </IonModal>
  )
}

export default Dialog
