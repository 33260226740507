import { useState } from 'react'
import { Meta, Body, Uppy, UppyFile } from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { UploadResultWithSignal } from '@uppy/aws-s3/lib/utils'
import { MultipartUploadResultWithSignal } from '@uppy/aws-s3/src/utils'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  abortMultipartUploadMutation,
  addS3FileAssetMutation,
  completeMultipartUploadMutation,
  createMultipartUploadMutation,
  listMultipartUploadParts,
  presignedMultipartUrl,
  presignedPutUrl,
} from './FileAssetsDirectUploadForm.graphql'
import {
  MAX_SIMULTANEOUS_UPLOADS,
  MULTIPART_UPLOAD_THRESHOLD,
} from './FileAssetsDirectUploadForm.constants'
import { useProjectContext } from '../../contexts/project'
import {
  assetsTypesMapping,
  Project,
  ProjectAsset,
  ProjectAssetsTypes,
} from '../../pages/ProjectPage/ProjectPage.types'

export const useUppy = (type: ProjectAssetsTypes) => {
  const [getPresignedUrl] = useLazyQuery(presignedPutUrl, {
    fetchPolicy: 'no-cache',
  })

  const [getPresignedMultipartUrl] = useLazyQuery(presignedMultipartUrl, {
    fetchPolicy: 'no-cache',
  })

  const [getListMultipartUploadParts] = useLazyQuery(listMultipartUploadParts, {
    fetchPolicy: 'no-cache',
  })

  const [createMultipartUploadRequest] = useMutation(
    createMultipartUploadMutation,
  )

  const [abortMultipartUploadRequest] = useMutation(
    abortMultipartUploadMutation,
  )

  const [completeMultipartUploadRequest] = useMutation(
    completeMultipartUploadMutation,
  )

  const { projectId } = useProjectContext()

  // @ts-ignore
  const [uppy] = useState(() =>
    new Uppy({
      autoProceed: true,
    }).use(AwsS3, {
      limit: MAX_SIMULTANEOUS_UPLOADS,
      shouldUseMultipart(file) {
        // Use multipart only for files larger than 100MiB.
        return (file.size || 0) > MULTIPART_UPLOAD_THRESHOLD
      },

      // ========== Non-Multipart Uploads ==========
      async getUploadParameters(file: UppyFile<Meta, Body>) {
        const { data, error } = await getPresignedUrl({
          variables: {
            projectId,
            type,
            filename: file.name,
            contentType: file.type,
          },
        })

        if (error) {
          throw new Error(error.message)
        }

        // Return an object in the correct shape.
        return {
          method: data.presignedPutUrl.method,
          url: data.presignedPutUrl.url,
          fields: {}, // For presigned PUT uploads, this should be left empty.
          // Provide content type header required by S3
          headers: {
            'Content-Type': file.type,
          },
        }
      },

      // ========== Multipart Uploads ==========

      async createMultipartUpload(file: UppyFile<Meta, Body>) {
        const metadata = {}

        Object.keys(file.meta || {}).forEach((key) => {
          if (file.meta[key] != null) {
            // @ts-ignore
            metadata[key] = file.meta[key].toString()
          }
        })

        let response
        try {
          response = await createMultipartUploadRequest({
            variables: {
              projectId,
              type,
              filename: file.name,
              contentType: file.type,
              metadata,
            },
          })
        } catch (error) {
          throw new Error((error as Error).message)
        }

        return {
          key: response.data.createMultipartUpload.key,
          uploadId: response.data.createMultipartUpload.uploadId,
        }
      },

      async abortMultipartUpload(
        file: UppyFile<Meta, Body>,
        { key, uploadId }: UploadResultWithSignal,
      ) {
        try {
          await abortMultipartUploadRequest({
            variables: {
              uploadId,
              key,
            },
          })
        } catch (error) {
          throw new Error((error as Error).message)
        }
      },

      async signPart(
        file: UppyFile<Meta, Body>,
        options: {
          uploadId: string
          key: string
          partNumber: number
          body: Blob
          signal?: AbortSignal
        },
      ) {
        const { uploadId, key, partNumber, signal } = options

        signal?.throwIfAborted()

        if (uploadId == null || key == null || partNumber == null) {
          throw new Error(
            'Cannot sign without a key, an uploadId, and a partNumber',
          )
        }

        const { data, error } = await getPresignedMultipartUrl({
          variables: {
            uploadId,
            partNumber,
            key,
          },
        })

        if (error) {
          throw new Error(error.message)
        }

        return {
          method: data.presignedMultipartUrl.method,
          url: data.presignedMultipartUrl.url,
        }
      },

      async listParts(
        file: UppyFile<Meta, Body>,
        { key, uploadId, signal }: UploadResultWithSignal,
      ) {
        signal?.throwIfAborted()

        const { data, error } = await getListMultipartUploadParts({
          variables: {
            uploadId,
            key,
          },
        })

        if (error) {
          throw new Error(error.message)
        }

        return data.listMultipartUploadParts.map((part: any) => ({
          PartNumber: part.partNumber,
          // Size?: number
          ETag: part.etag,
        }))
      },

      async completeMultipartUpload(
        file: UppyFile<Meta, Body>,
        { key, uploadId, parts, signal }: MultipartUploadResultWithSignal,
      ) {
        signal?.throwIfAborted()

        let response
        try {
          response = await completeMultipartUploadRequest({
            variables: {
              uploadId,
              key,
              parts: parts.map((part) => ({
                PartNumber: part.PartNumber,
                ETag: part.ETag,
              })),
            },
          })
        } catch (error) {
          throw new Error((error as Error).message)
        }
        return {
          location: response.data.completeMultipartUploadRequest,
        }
      },
    }),
  )
  return uppy
}

export const useAddProjectAsset = (type: ProjectAssetsTypes) => {
  const { projectId, updateQuery } = useProjectContext()
  const [addProjectAssetRequest, { loading }] = useMutation(
    addS3FileAssetMutation,
  )

  const addProjectAsset = async (values: { name: string }) => {
    try {
      const newAsset = await addProjectAssetRequest({
        variables: {
          ...values,
          type,
          projectId,
        },
      })

      updateQuery(({ project }: { project: Project }) => {
        // @ts-ignore
        const assetType = assetsTypesMapping[type]
        // @ts-ignore Object is possibly 'undefined'
        const editedAssets: [ProjectAsset] = project?.assets[assetType]

        const result = {
          ...project,
          assets: {
            ...project.assets,
            [assetType]: [...editedAssets, newAsset.data.addProjectAsset],
          },
        }
        return {
          project: result,
        }
      })
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }
  return {
    addProjectAsset,
    loading,
  }
}
