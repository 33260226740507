/* eslint-disable import/no-default-export */
import { IonButton } from '@ionic/react'
import styled, { css } from 'styled-components'

const lightStyles = css`
  --border-style: solid;
  &::part(native):hover {
    border-color: var(--sync-blue);
    color: var(--sync-blue);
  }
`

const fillStyles = css`
  --border-style: none;
`

const StyledButton = styled(IonButton)`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  min-height: auto;
  height: 38px;
  margin: 0;
  letter-spacing: 0.02em;
  --border-color: #000000;
  --border-width: 1px;
  text-decoration: ${(props) =>
    props.fill === 'clear' ? 'underline' : 'none'};
  ${(props) => (props.color === 'light' ? lightStyles : fillStyles)};
`

export default StyledButton
