import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import { useState } from 'react'
import { resetMutation } from './ResetPage.graphql'
import { ResetFormFields } from './ResetPage.types'

export const useReset = (token: string) => {
  const [isDone, setIsDone] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [reset, { loading }] = useMutation(resetMutation)

  const handleReset = async (
    values: ResetFormFields,
    formikHelpers: FormikHelpers<ResetFormFields>,
  ) => {
    try {
      const { data: isDone } = await reset({
        variables: { password: values.password, token },
      })

      setIsDone(isDone)
    } catch (error) {
      setIsInvalid(true)
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    handleReset,
    loading,
    isDone,
    isInvalid,
  }
}
