/* eslint-disable import/no-default-export */
import { useMemo } from 'react'
import { IonAvatar, IonIcon, IonItem, IonText } from '@ionic/react'
import { chevronDown } from 'ionicons/icons'
import styled, { css } from 'styled-components'
import defaultAvatar from '../../../assets/default-avatar.svg'
import { usePopoverContext } from '../../../contexts/popover'
import { FlexBox } from '../FlexBox'
import { Popover } from '../Popover'
import { useMeQuery } from './UserInfo.hooks'
import { UserInfoMenu } from './UserInfoMenu'
import { useOrganisationContext } from '../../../contexts/organisation'

const StyledIonItem = styled(IonItem)`
  --detail-icon-opacity: 1;
  --background-hover: transparent;
  --background-activated: transparent;
`

const StyledIonAvatar = styled(IonAvatar)`
  width: 42px;
  height: 42px;
`

const StyledIonText = styled(IonText)`
  margin-right: 16px;
  margin-left: 10px;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StyledUserNameIonText = styled(IonText)`
  font-size: 14px;
  display: block;
`

const popoverCss = css`
  && {
    .popover-content {
      transform: translateX(-16px) translateY(10px);
    }

    --width: 230px;
  }
`

const NoEvents = styled.div`
  pointer-events: none;
`

const TriggerMenuButton = () => {
  const { openPopover } = usePopoverContext()
  const { user } = useMeQuery()
  const { organisationId } = useOrganisationContext()

  const organisationName = useMemo(() => {
    if (!user) {
      return null
    }

    const organisation = user.organisations?.find(
      (organisation) => organisation.id === organisationId,
    )

    return organisation ? organisation.name : null
  }, [organisationId, user?.organisations])

  return (
    <StyledIonItem
      lines="none"
      button
      style={{ '--inner-padding-end': 0 }}
      detail={false}
      onClick={openPopover}
      data-testid="UserInfo"
    >
      <NoEvents>
        <FlexBox direction="row">
          <StyledIonAvatar style={{}}>
            <img alt="user avatar" src={user?.avatarUrl || defaultAvatar} />
          </StyledIonAvatar>
          <StyledIonText className="ion-hide-sm-down">
            {organisationName}
            <StyledUserNameIonText>{user?.name}</StyledUserNameIonText>
          </StyledIonText>
          <IonIcon
            size="small"
            className="ion-hide-sm-down"
            icon={chevronDown}
          />
        </FlexBox>
      </NoEvents>
    </StyledIonItem>
  )
}

const UserInfo = () => (
  <Popover css={popoverCss} trigger={TriggerMenuButton}>
    <UserInfoMenu />
  </Popover>
)

export default UserInfo
