/* eslint-disable import/no-default-export */
import React from 'react'
import {
  ProjectCaptur3dDialogHookProps,
  ProjectDigitalTwinDialogProps,
} from './ProjectDigitalTwinDialog.types'
import { useAddProjectDigitalTwin } from './ProjectDigitalTwinDialog.hooks'
import ProjectDigitalTwinForm from './ProjectDigitalTwinForm.component'
import {
  initialValues,
  validationSchema,
} from './ProjectDigitalTwinForm.constants'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'

const DigitalTwinForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  projectId,
  updateQuery,
}: DialogWithAlertInnerAugment & ProjectCaptur3dDialogHookProps) => {
  const { addProjectDigitalTwin } = useAddProjectDigitalTwin(
    projectId,
    updateQuery,
    forceDismiss,
  )

  return (
    <ProjectDigitalTwinForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addProjectDigitalTwin}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
    />
  )
}

const ProjectDigitalTwinDialog: React.FC<ProjectDigitalTwinDialogProps> = ({
  projectId,
  updateQuery,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <DigitalTwinForm
      {...props}
      projectId={projectId}
      updateQuery={updateQuery}
    />
  )

  return (
    <DialogWithAlert
      dialogTitle="Add Digital Twin Link"
      alertHeader="Cancel Adding Digital Twin Link?"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectDigitalTwinDialog
