/* eslint-disable import/no-default-export */
import { ScrollToContainer } from '../ui/ScrollToContainer'
import { ProjectNotesProps } from './ProjectNotes.types'
import { ItemCard } from '../ui/Card'
import ItemList from '../ui/Card/ItemList'

const ProjectNotes = ({ notes }: ProjectNotesProps) => {
  if (!notes?.length) {
    return null
  }

  return (
    <ScrollToContainer name="project-custom" data-testid="ProjectNotes">
      <ItemList title="Notes" itemCount={notes?.length}>
        {notes.map((note) => (
          <ItemCard
            key={note.id}
            asset={note}
            title={note.name}
            description={note.value}
          />
        ))}
      </ItemList>
    </ScrollToContainer>
  )
}

export default ProjectNotes
