import { useQuery } from '@apollo/client'
import { userPersonalProfileQuery } from './UserProfilePage.graphql'
import { UserProfileQueryResult } from './UserProfilePage.types'

export const usePersonalProfile = (): UserProfileQueryResult => {
  const { data, loading } = useQuery(userPersonalProfileQuery)

  return {
    user: data?.me,
    loading,
  }
}
