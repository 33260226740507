/* eslint-disable import/no-default-export */
import { IonCol, IonRow } from '@ionic/react'
import styled from 'styled-components'
import { InfoFieldProps } from './InfoField.types'

const StyledTitleIonCol = styled(IonCol)`
  font-weight: bold;
  line-height: 24px;
`

const StyledValueIonCol = styled(IonCol)`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`
const InfoField = ({ title, value }: InfoFieldProps) => (
  <>
    <IonRow className="ion-justify-content-center  ion-align-items-center">
      <StyledTitleIonCol className="ion-no-padding">{title}</StyledTitleIonCol>
    </IonRow>
    <IonRow className="ion-justify-content-center  ion-align-items-center">
      <StyledValueIonCol className="ion-no-padding">{value}</StyledValueIonCol>
    </IonRow>
  </>
)

export default InfoField
