/* eslint-disable import/no-default-export */
import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonSpinner,
  IonText,
  useIonAlert,
} from '@ionic/react'
import { chevronDown, trashOutline } from 'ionicons/icons'
import { portfolioPermissions } from '@synctech/portal-types'
import styled, { css } from 'styled-components'
import defaultAvatar from '../../assets/default-avatar.svg'
import { usePopoverContext } from '../../contexts/popover'
import { Button } from '../ui/Button'
import { ShadowCard } from '../ui/Card'
import { FlexBox } from '../ui/FlexBox'
import { Popover } from '../ui/Popover'
import { usePortfolioUserDelete } from './PortfolioUsersDialog.hooks'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { Link } from '../ui/Link'
import { PortfolioUserProps } from './PortfolioUsersDialog.types'
import { PortfolioUserAssignRoleMenu } from '../PortfolioUserAssignRoleMenu'
import { portfolioRoles } from './PortfolioUsersDialog.constants'
import { useGetAssignedProjectsQuery } from './PortfolioUser.hooks'

const StyledIonAvatar = styled(IonAvatar)`
  width: 40px;
  height: 40px;
  margin-right: 15px;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
`

const EmailText = styled(IonText)<Partial<PortfolioUserProps>>`
  font-size: 14px;
  color: ${(props) =>
    props.isRegistered ? 'inherit' : css`var(--sync-darky-gray)`};
  @media (max-width: 768px) {
    display: ${(props) => (props.name ? 'none' : 'inherit')};
  }
`

const NameText = styled.div<Partial<PortfolioUserProps>>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  font-weight: bold;
  color: ${(props) =>
    props.isRegistered ? 'inherit' : css`var(--sync-darky-gray)`};
  @media (max-width: 768px) {
    -webkit-line-clamp: 2;
    font-size: 12px;
  }
`
const PendingChip = styled.div`
  height: 32px;
  background-color: var(--sync-gray);
  color: var(--sync-darky-gray);
  font-size: 14px;
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  font-weight: 700;
  margin-left: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 22px;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    margin-left: auto;
  }
`

const StyledButton = styled(Button)<Partial<PortfolioUserProps>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) =>
    props.isRegistered ? css`var(--sync-blue)` : css`var(--sync-darky-gray)`};
  background-color: ${(props) =>
    props.isRegistered
      ? css`var(--sync-sky-blue)`
      : css`var(--sync-lighter-gray)`};
  font-weight: bold;
  height: 32px;
  line-height: 32px;
  min-width: 200px;
  border-radius: 16px;

  margin: 0 0 0 auto;
  padding: 0 15px 0 20px;

  flex-shrink: 0;
  @media (max-width: 768px) {
    font-size: 12px;
    height: 24px;
    border-radius: 12px;

    min-width: inherit;
    padding: 0 10px 0 15px;
  }
`

const StyledText = styled(IonText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;

  height: 32px;
  padding-right: 0.5rem;

  @media (max-width: 768px) {
    font-size: 12px;
    flex-shrink: 0.5;
  }
`

const StyledIonIcon = styled(IonIcon)`
  pointer-events: none;
`

const StyledIonItem = styled(IonItem)<Partial<PortfolioUserProps>>`
  padding: 15px;
  --inner-padding-end: 0;
  --min-height: auto;
  background-color: ${(props) =>
    props.isRegistered ? 'inherit' : css`var(--sync-lightest-gray)`};
  --ion-item-background: ${(props) =>
    props.isRegistered ? 'inherit' : css`var(--sync-lightest-gray)`};
  @media (max-width: 768px) {
    padding: 3px 5px;
  }
`
const DeleteIcon = styled(IonIcon)`
  font-size: 22px;
  margin-left: 15px;
  cursor: pointer;
  width: 28px; // to have the same width as spinner
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`

const DeleteSpinner = styled(IonSpinner)`
  font-size: 22px;
  margin-left: 15px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`

const changeRolePopoverCss = css`
  && {
    .popover-content {
      width: 200px;
      transform: translateY(3px);
      @media (max-width: 768px) {
        width: 180px;
        transform: translateX(-19px) translateY(3px);
      }
    }
  }
`

const TextLink = styled(Link)`
  text-decoration: none;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  outline: none;
`

const TriggerRoleChange: React.VFC<{
  currentRoleName: string
  isRegistered: boolean
}> = ({ currentRoleName, isRegistered }): JSX.Element => {
  const { openPopover } = usePopoverContext()

  return (
    <StyledButton as="button" onClick={openPopover} isRegistered={isRegistered}>
      <StyledText>{currentRoleName}</StyledText>
      <StyledIonIcon
        slot="end"
        onClick={openPopover}
        size="small"
        icon={chevronDown}
      />
    </StyledButton>
  )
}

const RoleEditButton = ({ rolesMenu, trigger }: any) => (
  <Popover css={changeRolePopoverCss} trigger={trigger}>
    {rolesMenu && rolesMenu()}
  </Popover>
)

const DeleteUserButton = ({
  id,
  portfolioId,
  name,
}: {
  id: number
  portfolioId: number
  name: string
}) => {
  const [present] = useIonAlert()
  const { deletePortfolioUser, loading } = usePortfolioUserDelete(
    portfolioId,
    id,
  )

  const { getAssignedProjects } = useGetAssignedProjectsQuery()

  const onDeleteIconClick = async () => {
    const { data } = await getAssignedProjects({
      variables: {
        portfolioId,
        userId: id,
      },
    })

    let message = 'Are you sure you want to remove this member?'
    if (data?.getAssignedProjects?.length) {
      message = `Are you sure you want to remove this member? This member is assigned to ${data?.getAssignedProjects?.length} project(s) within this portfolio.`
    }
    present({
      cssClass: 'sync-alert',
      header: 'Remove member',
      subHeader: `You are about to remove ${name}`,
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove',
          cssClass: 'alert-button__confirm',
          handler: deletePortfolioUser,
        },
      ],
    })
  }

  if (loading) {
    return <DeleteSpinner name="lines-small" />
  }

  return (
    <DeleteIcon
      icon={trashOutline}
      onClick={onDeleteIconClick}
      aria-label="Delete User"
    />
  )
}

const PortfolioUser = ({
  id,
  name,
  avatar,
  email,
  roleId,
  userId,
  portfolioId,
  permissions,
  isRegistered,
  ...rest
}: PortfolioUserProps) => {
  const { isMobile } = useDisplaySize()
  const hideRoleChange = isMobile && !isRegistered

  const triggerRoleChange = () => (
    <TriggerRoleChange
      currentRoleName={portfolioRoles[roleId]}
      isRegistered={isRegistered}
    />
  )

  const canManageRole =
    permissions.includes(portfolioPermissions[roleId]) &&
    portfolioRoles[roleId] !== 'Test No Permissions'

  const rolesMenu = () => (
    <PortfolioUserAssignRoleMenu
      listedUserId={userId}
      listedUserRoleId={roleId}
      portfolioId={portfolioId}
      permissions={permissions}
    />
  )

  const profileLink = `/profile/${id}`

  return (
    <ShadowCard className="ion-no-margin ion-no-padding" {...rest}>
      <StyledIonItem
        lines="none"
        className="ion-no-margin ion-no-padding"
        isRegistered={isRegistered}
      >
        <TextLink to={profileLink}>
          <StyledIonAvatar slot="start">
            <img alt="user avatar" src={avatar || defaultAvatar} />
          </StyledIonAvatar>
        </TextLink>
        <FlexBox direction="row" fullWidth>
          <TextLink to={profileLink}>
            <FlexBox direction="column" alignItems="flex-start">
              <NameText isRegistered={isRegistered}>{name}</NameText>
              <EmailText isRegistered={isRegistered} name={name}>
                {email}
              </EmailText>
            </FlexBox>
          </TextLink>

          {!hideRoleChange && (
            <RoleEditButton rolesMenu={rolesMenu} trigger={triggerRoleChange} />
          )}
          {!isRegistered && <PendingChip>Pending</PendingChip>}
          {permissions.includes('DeletePortfolioUsers') && canManageRole && (
            <DeleteUserButton id={id} portfolioId={portfolioId} name={name} />
          )}
        </FlexBox>
      </StyledIonItem>
    </ShadowCard>
  )
}

export default PortfolioUser
