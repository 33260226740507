/* eslint-disable import/no-default-export */
import { IonCol } from '@ionic/react'
import { VideoPlayer } from '../ui/VideoPlayer'
import { ProjectVideosProps } from './ProjectVideos.types'
import { MediaItemCard } from '../ui/Card'
import { ScrollToContainer } from '../ui/ScrollToContainer'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ProjectAsset } from '../../pages/ProjectPage/ProjectPage.types'
import { hasPermission } from '../../helpers/casl'
import mixpanel from '../../helpers/tracking'
import MediaItemList from '../ui/Card/MediaItemList'

const ProjectVideos = ({ videos }: ProjectVideosProps) => {
  if (!videos?.length) {
    return null
  }

  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const trackPlayback = (name: string) => () => {
    mixpanel.track('FE - Asset View', {
      Asset_Type: 'Video',
      Asset_Name: name,
      Project_ID: projectId,
      Project_Name: project.title,
      Project_Address: project.address,
    })
  }

  const editMenu = hasPermission(project, [
    'DeleteProjectAssets',
    'EditProjectAssets',
  ])
    ? (video: ProjectAsset) => <AssetPopoverMenu asset={video} />
    : () => null

  return (
    <ScrollToContainer name="project-videos" data-testid="ProjectVideos">
      <MediaItemList title="Videos" itemCount={videos?.length}>
        {videos.map((video, i) => (
          <IonCol
            className="cardContainer"
            key={video.id}
            size="5"
            sizeLg="6"
            sizeXl="4"
          >
            <MediaItemCard editMenu={() => editMenu(video)} title={video.name}>
              <VideoPlayer
                url={video.value}
                id={i.toString()}
                fullscreenOnStart
                onStart={trackPlayback(video.name)}
              />
            </MediaItemCard>
          </IonCol>
        ))}
      </MediaItemList>
    </ScrollToContainer>
  )
}

export default ProjectVideos
