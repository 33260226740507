/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const addProjectUsersMutation = gql`
  mutation AddProjectUsers($projectId: ID!, $emails: [String]!, $roleId: Int!) {
    addProjectUsers(projectId: $projectId, emails: $emails, roleId: $roleId) {
      id
    }
  }
`

export const emailHintsQuery = gql`
  query EmailHints($projectId: ID, $email: String) {
    emailHints(projectId: $projectId, email: $email)
  }
`
