/* eslint-disable import/no-default-export */
import { FlexBox } from '../ui/FlexBox'
import { PopoverItem as Item } from '../ui/PopoverItem'
import { useProjectListContext } from '../../contexts/projectList'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ProjectAddDialogHookProps } from '../ProjectDialog/ProjectDialog.types'
import ProjectAddDialog from '../ProjectDialog/ProjectAddDialog.component'
import { useMeQuery } from '../ui/UserInfo/UserInfo.hooks'
import { useOrganisationContext } from '../../contexts/organisation'
import { effectiveOrganisationPermissions } from '../../helpers/casl'

const ProjectListAddPopoverMenu = () => {
  const { updateQuery } = useProjectListContext()
  const { user } = useMeQuery()
  const { organisationId } = useOrganisationContext()

  const permissions = effectiveOrganisationPermissions(user, organisationId)

  const [addProjectDialogPresent] = useDialog<ProjectAddDialogHookProps>(
    ProjectAddDialog,
    {
      updateQuery,
    },
  )
  return (
    <FlexBox alignItems="stretch" dataTestid="ProjectListAddPopoverMenu">
      {permissions.includes('CreateProject') && (
        <Item onClick={addProjectDialogPresent}>Add project</Item>
      )}
    </FlexBox>
  )
}

export default ProjectListAddPopoverMenu
