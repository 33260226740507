/* eslint-disable import/no-default-export */
import { Link } from 'react-scroll'
import styled from 'styled-components'
import { IonBadge } from '@ionic/react'
import { AsideScrollMenuProps } from './AsideScrollMenu.types'

const StyledLink = styled(Link)`
  cursor: pointer;
  color: var(--sync-dark-gray);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
  &.active {
    color: var(--sync-blue) !important;
  }
  // hover styles applied for desktop devices only
  // to avoid sticky hover bug for ionic button component
  // https://github.com/ionic-team/ionic-framework/issues/18570
  @media (pointer: fine) {
    &:hover {
      color: var(--sync-light-gray);
    }
  }
`

const StyledMenuContainer = styled.div`
  margin-top: 50px;
  width: 188px;
  padding-right: 10px;

  @media (max-width: 1140px) {
    width: 100%;
  }
`

const StyledIonBadge = styled(IonBadge)`
  margin-left: 5px;
  vertical-align: text-bottom;
  && {
    font-size: 12px;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
`

const AsideScrollMenu = ({ menuItems }: AsideScrollMenuProps) => {
  if (!menuItems) return null

  return (
    <StyledMenuContainer data-testid="AsideScrollMenu">
      {menuItems.map((item) => (
        <div key={item.id}>
          <StyledLink
            activeClass="active"
            to={item.id}
            spy
            smooth
            duration={500}
            offset={-50}
            containerId="scroll-container"
          >
            {item.title}
            {!!item.count && <StyledIonBadge>{item.count}</StyledIonBadge>}
          </StyledLink>
        </div>
      ))}
    </StyledMenuContainer>
  )
}

export default AsideScrollMenu
