import { useMutation, useQuery } from '@apollo/client'
import { useNotification } from '../../contexts/notification'
import { unpackPermissions } from '../../helpers/casl'
import {
  deletePortfolioUserMutation,
  portfolioUsersQuery,
} from './PortfolioUsersDialog.graphql'
import { PortfolioUser } from './PortfolioUsersDialog.types'
import { portfolioQuery } from '../../pages/PortfolioPage/PortfolioPage.graphql'

export const useUserListQuery = (
  portfolioId: number,
): {
  userList: PortfolioUser[]
  permissions: string[]
  loading: boolean
  updateQuery: any
} => {
  const { data, loading, updateQuery } = useQuery(portfolioUsersQuery, {
    variables: {
      id: portfolioId.toString(),
    },
  })

  return {
    userList: data && data.portfolio.users,
    permissions: unpackPermissions(data?.portfolio?.permissions),
    loading,
    updateQuery,
  }
}

export const usePortfolioUserDelete = (portfolioId: number, userId: number) => {
  const [deletePortfolioUserRequest, { loading }] = useMutation(
    deletePortfolioUserMutation,
    {
      variables: {
        portfolioId,
        userId: Number(userId), // it comes as a string form backend "project" request 0_o
      },
      refetchQueries: [
        // For now we don't trigger project list refetch because it not doing well with paginated results
        {
          query: portfolioUsersQuery,
          variables: { id: portfolioId.toString() },
        },
        {
          query: portfolioQuery,
          variables: { id: portfolioId.toString() },
        },
      ],
    },
  )

  const { notify, notifyError } = useNotification()

  const deletePortfolioUser = async () => {
    try {
      await deletePortfolioUserRequest()

      notify('Portfolio member successfully deleted')
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  return {
    deletePortfolioUser,
    loading,
  }
}
