/* eslint-disable import/no-default-export */
import { IonPopover } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PopoverContext } from '../../../contexts/popover'
import { PopoverProps } from './Popover.types'

const StyledIonPopover = styled(IonPopover)<Partial<PopoverProps>>`
  && {
    .popover-arrow {
      display: none !important;
    }

    .popover-content {
      --box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
  }
  ${(props) => props.css}
`

const Popover: React.FC<PopoverProps> = ({
  trigger: TriggerComponent,
  css,
  children,
}) => {
  const [popoverState, setPopoverState] = useState({
    showPopover: false,
    event: undefined,
  })

  const closePopover = () => {
    setPopoverState({ showPopover: false, event: undefined })
  }

  const openPopover = (e: any) => {
    e.persist()
    setPopoverState({ showPopover: true, event: e })
  }

  useEffect(
    () => () => {
      closePopover()
    },
    [],
  )

  return (
    <PopoverContext.Provider value={{ closePopover, openPopover }}>
      <TriggerComponent />
      <StyledIonPopover
        showBackdrop={false}
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={closePopover}
        css={css}
      >
        {children}
      </StyledIonPopover>
    </PopoverContext.Provider>
  )
}

export default Popover
