/* eslint-disable import/no-default-export */
import React from 'react'
import {
  ProjectLinkAddDialogHookProps,
  ProjectLinkAddDialogProps,
} from './ProjectLinkDialog.types'
import { useAddProjectLink } from './ProjectLinkAddDialog.hooks'
import ProjectLinkForm from './ProjectLinkForm.component'
import { initialValues, validationSchema } from './ProjectLinkForm.constants'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'

const LinkForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  projectId,
  updateQuery,
}: DialogWithAlertInnerAugment & ProjectLinkAddDialogHookProps) => {
  const { addProjectLink } = useAddProjectLink(
    projectId,
    updateQuery,
    forceDismiss,
  )

  return (
    <ProjectLinkForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addProjectLink}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
    />
  )
}

const ProjectLinkAddDialog: React.FC<ProjectLinkAddDialogProps> = ({
  projectId,
  updateQuery,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <LinkForm {...props} projectId={projectId} updateQuery={updateQuery} />
  )

  return (
    <DialogWithAlert
      dialogTitle="Add Link"
      alertHeader="Cancel Adding Link?"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectLinkAddDialog
