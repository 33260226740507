// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { ReactComponentOrElement } from '@ionic/react/dist/types/hooks/useOverlay'
import { useDialogContext } from '../../../contexts/dialog'

/** how to use
 * SIMPLE DIALOG
 *
 * useDialog hook should be typed propely to have TS support on inner component's props
 * e.g. useDialog<ProjectEditDialogHookProps>
 *
 * To have access to Dialogue's onDismiss callback, inner component's type must extend
 * noticed above props with "onDismiss: () => void" param, e.g.
 *   ProjectEditDialogProps extends ProjectEditDialogHookProps {
 *     onDismiss: () => void
 *   }
 *
 *
 * DIALOG WITH ALERT
 *
 * Same approach as above for DialogWithAlert, but useDialog hook must be typed with
 * DialogWithAlertHookProps type, e.g.
 *   useDialog<DialogWithAlertHookProps>
 *
 * Inner component in such hook usage must be DialogWithAlert
 *
 * Prop "content" passed to component's props is a reference to an inner component,
 * To be able to contol the dialogue, this component type must be typed with:
 *   onDismiss: () => void | false
 *   allowedToDismiss: boolean
 *   setAllowedToDismiss: Dispatch<SetStateAction<boolean>>
 *
 * For easy use "DialogWithAlertInnerAugment" type is exported from DialogWithAlert.types.ts, e.g.
 *   interface FileAssetsUploadFormProps extends DialogWithAlertInnerAugment {
 *     accept?: string
 *     // and other types for inner component's props
 *   }
 */

export const useDialog = <DialogComponentsProps>(
  component: ReactComponentOrElement,
  componentProps?: { onDismiss?: () => void | false } & DialogComponentsProps,
) => {
  const { openDialog, closeDialog } = useDialogContext()

  const handleDismiss = () => {
    if (componentProps?.onDismiss) {
      // we allow cancel dialog close if we return false in the custom dismiss handler
      if (componentProps.onDismiss() === false) {
        return
      }
    }
    closeDialog()
  }
  return [
    () =>
      openDialog(component, {
        ...componentProps,
        onDismiss: handleDismiss,
      }),
  ]
}
