/* eslint-disable import/no-default-export */
import React from 'react'
import styled from 'styled-components'
import { HelpDialogProps } from './HelpDialog.types'
import { Dialog } from '../ui/Dialog'

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

const HelpDialog: React.FC<HelpDialogProps> = ({ onDismiss }) => (
  <Dialog
    title="Portal Support Station"
    onDismiss={onDismiss}
    cssClass="sync-help-modal"
  >
    <StyledIframe
      src="https://www.synctech.io/portal-help"
      title="Portal Support Station"
    />
  </Dialog>
)

export default HelpDialog
