import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import { useState } from 'react'
import { registerMutation } from './RegisterPage.graphql'
import { RegisterFormFields } from './RegisterPage.types'

export const useRegister = (token: string) => {
  const [isDone, setIsDone] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [register, { loading }] = useMutation(registerMutation)

  const handleRegister = async (
    values: RegisterFormFields,
    formikHelpers: FormikHelpers<RegisterFormFields>,
  ) => {
    try {
      const { data: isDone } = await register({
        variables: {
          name: values.name,
          password: values.password,
          email: values.email,
          token,
        },
      })

      setIsDone(isDone)
    } catch (error) {
      setIsInvalid(true)
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    handleRegister,
    loading,
    isDone,
    isInvalid,
  }
}
