/* eslint-disable import/no-default-export,indent */
import { IonSpinner } from '@ionic/react'
import { FlexBox } from '../ui/FlexBox'
import { PopoverItemWithAlert } from '../ui/PopoverItemWithAlert'
import { useAssetDelete } from './AssetPopoverMenu.hooks'
import { AssetPopoverMenuProps } from './AssetPopoverMenu.types'
import { PopoverItem } from '../ui/PopoverItem'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ProjectAssetNameEditDialogHookProps } from '../ProjectAssetNameDialog/ProjectAssetNameDialog.types'
import { ProjectAssetNameEditDialog } from '../ProjectAssetNameDialog'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { hasPermission } from '../../helpers/casl'
import { useUpdateProjectPublicItems } from '../ProjectSharedLinkAccessDialog/ProjectSharedLinkAccessDialog.hooks'

const AssetPopoverMenu: React.FC<AssetPopoverMenuProps> = ({ asset }) => {
  const { projectId, updateQuery } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const { deleteAsset, loading } = useAssetDelete(asset)
  const { updatePublicItems } = useUpdateProjectPublicItems(projectId, () => {})

  const [editProjectAssetNameDialogPresent] =
    useDialog<ProjectAssetNameEditDialogHookProps>(ProjectAssetNameEditDialog, {
      projectId,
      assetId: asset.id,
      name: asset.name,
      updateQuery,
    })

  const isAssetShared =
    project.attributes?.projectPublicItems?.assets?.includes(asset.id)

  const sharedViewText = isAssetShared ? 'Disable Sharing' : 'Enable Sharing'

  const switchAssetSharing = async () => {
    let visibleAssets: string[] | undefined
    if (isAssetShared) {
      visibleAssets = project.attributes?.projectPublicItems?.assets?.filter(
        (assetId) => assetId !== asset.id,
      )
    } else {
      visibleAssets = [
        ...(project.attributes?.projectPublicItems?.assets || []),
        asset.id,
      ]
    }
    await updatePublicItems(
      project.attributes?.projectPublicItems?.description || false,
      visibleAssets || [],
    )
  }

  return (
    <FlexBox alignItems="stretch" dataTestid="AssetPopoverMenu">
      {hasPermission(project, ['ManageSharedLink']) &&
        project.sharedLinkEnabled && (
          <PopoverItem onClick={switchAssetSharing}>
            {sharedViewText}
          </PopoverItem>
        )}

      {hasPermission(project, ['EditProjectAssets']) && (
        <PopoverItem onClick={editProjectAssetNameDialogPresent}>
          Rename
        </PopoverItem>
      )}
      {hasPermission(project, ['DeleteProjectAssets']) && (
        <PopoverItemWithAlert
          header="Delete"
          message="Deleting items is a permanent action and cannot be undone. Are you sure you want to Delete this item?"
          confirmButtonText="Delete"
          onClick={deleteAsset}
        >
          Delete
          {loading && <IonSpinner slot="end" name="lines-small" />}
        </PopoverItemWithAlert>
      )}
    </FlexBox>
  )
}

export default AssetPopoverMenu
