/* eslint-disable import/no-default-export */
import { useState } from 'react'
import clsx from 'clsx'
import styles from './OrderParameter.module.css'
import { OrderOption, OrderParameterProps } from './OrderParameter.types'

const OrderParameter: React.FC<OrderParameterProps> = ({
  options,
  value,
  onChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onValueChange = (value: OrderOption) => {
    onChange(value)
    setIsExpanded(false)
  }

  return (
    <div
      className={styles.container}
      tabIndex={-1}
      onBlur={() => setIsExpanded(false)}
    >
      <div
        className={clsx(styles.label, {
          [styles.label__open]: isExpanded,
        })}
        onClick={() => setIsExpanded((s) => !s)}
      >
        {value.label}
      </div>
      <div
        className={`${styles.selectionListContainer} ${
          isExpanded ? styles.selectionListContainer__open : ''
        }`}
      >
        <ul className={styles.itemsList}>
          {options.map((option) => (
            <li
              key={option.value}
              className={styles.item}
              onClick={() => onValueChange(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default OrderParameter
