/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const projectSharedQuery = gql`
  query sharedProject($uuid: String!) {
    sharedProject(uuid: $uuid) {
      id
      title
      address
      status
      description
      reference_id
      created_at
      assets {
        documents {
          id
          value
          name
          type
        }
        images {
          id
          value
          name
          type
        }
        videos {
          id
          value
          name
          type
        }
        custom {
          id
          value
          name
          type
        }
        urls {
          id
          value
          name
          type
        }
        pix4d {
          id
          value
          name
          type
        }
        nearmap {
          id
          value
          name
          type
        }
        captur3d {
          id
          value
          name
          type
          previewUrl
        }
        matterport {
          id
          value
          name
          type
          previewUrl
        }
        bim_file {
          id
          value
          name
          type
          previewUrl
          attributes {
            bim_file {
              is_autodesk_published
              autodesk_status
              autodesk_urn
            }
          }
        }
        self_hosted_tour {
          id
          name
          value
          type
          attributes {
            self_hosted_tour {
              status
            }
          }
        }
      }
    }
  }
`
