/* eslint-disable import/no-default-export */
import { IonChip, IonLabel } from '@ionic/react'
import styled from 'styled-components'
import { ProjectStatusIndicatorProps } from './ProjectStatusIndicator.types'
import { ProjectStatusDecoration } from './ProjectStatusIndicator.constants'

interface StyledIonChipProps {
  mainColor: string
  bgColor: string
}

const StyledIonChip = styled(IonChip)<StyledIonChipProps>`
  font-size: 12px;
  height: 22px;
  text-transform: capitalize;
  --color: ${(props) => props.mainColor};
  --background: ${(props) => props.bgColor};
`

const ProjectStatusIndicator = ({ status }: ProjectStatusIndicatorProps) => (
  <StyledIonChip
    slot="start"
    mainColor={ProjectStatusDecoration[status].color}
    bgColor={ProjectStatusDecoration[status].bgColor}
  >
    <IonLabel>{status}</IonLabel>
  </StyledIonChip>
)

export default ProjectStatusIndicator
