/* eslint-disable import/no-default-export */
import { link as linkIcon } from 'ionicons/icons'
import { ProjectLinksProps } from './ProjectLinks.types'
import { ItemCard } from '../ui/Card'
import { ScrollToContainer } from '../ui/ScrollToContainer'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import mixpanel from '../../helpers/tracking'
import ItemList from '../ui/Card/ItemList'

const ProjectLinks = ({ links }: ProjectLinksProps) => {
  if (!links?.length) {
    return null
  }

  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const trackClick = (name: string) => () => {
    mixpanel.track('FE - Asset View', {
      Asset_Type: 'Link',
      Asset_Name: name,
      Project_ID: projectId,
      Project_Name: project.title,
      Project_Address: project.address,
    })
    // in order to allow follow the link
    return true
  }

  return (
    <ScrollToContainer name="project-urls" data-testid="ProjectLinks">
      <ItemList title="Links" itemCount={links?.length}>
        {links.map((link) => (
          <ItemCard
            key={link.id}
            asset={link}
            title={link.name}
            url={link.value}
            icon={linkIcon}
            onTitleClick={trackClick(link.name)}
          />
        ))}
      </ItemList>
    </ScrollToContainer>
  )
}

export default ProjectLinks
