import { gql } from '@apollo/client'

export const addProjectAerialMapMutation = gql`
  mutation addProjectAerialMap(
    $projectId: ID!
    $name: String
    $value: String
    $type: ProjectAssetsTypes
  ) {
    addProjectAsset(
      projectId: $projectId
      type: $type
      name: $name
      value: $value
    ) {
      id
      type
      name
      value
    }
  }
`
