export enum ProjectStatus {
  PENDING = 'pending',
  NEW = 'new',
  INSPECTION = 'inspection',
  PRODUCTION = 'production',
  DELIVERED = 'delivered',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  DECLINED = 'declined',
  HOLD = 'hold',
  ARCHIVED = 'archived',
}

export enum ProjectAssetsTypes {
  URL = 'url',
  CAPTUR3D = 'captur3d',
  MATTERPORT = 'matterport',
  PIX4D = 'pix4d',
  DOCUMENT = 'document',
  IMAGE = 'image',
  VIDEO = 'video',
  CUSTOM = 'custom',
  BIM_FILE = 'bim_file',
  SELF_HOSTED_TOUR = 'self_hosted_tour',
  NEARMAP = 'nearmap',
}

export enum SelfHostedTourStatus {
  PRE_PROCESSING = 'pre_processing',
  COMPILED = 'compiled',
}
