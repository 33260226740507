import { useLazyQuery, useMutation } from '@apollo/client'
import {
  disableSharedLinkMutation,
  getReportQuery,
} from './ProjectSharedLinkControls.graphql'
import { useNotification } from '../../contexts/notification'

export const useSharedLinkDisable = (projectId: string) => {
  const [disableSharedLinkRequest, { loading }] = useMutation(
    disableSharedLinkMutation,
    {
      variables: {
        projectId,
      },
    },
  )

  const { notify, notifyError } = useNotification()

  const disableSharedLink = async () => {
    try {
      await disableSharedLinkRequest()
      notify('Shared link successfully disabled')
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  return {
    disableSharedLink,
    loading,
  }
}

export const useGetCaptureReport = () => {
  const [getCaptureReport, { loading }] = useLazyQuery(getReportQuery)

  return {
    getCaptureReport,
    loading,
  }
}
