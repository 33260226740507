/* eslint-disable import/no-default-export */
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SearchField } from '../fields/SearchField'

const StyledSearchField = styled(SearchField)`
  margin-right: 20px;
`

const SearchForm = () => {
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)
  const searchQuery = params.get('q') || undefined

  const onSearch = (value?: string) => {
    if (value && value.length > 0) {
      history.replace(`/projects?q=${value}`)
    } else {
      history.replace(`/projects`)
    }
  }

  return (
    <StyledSearchField
      name="searchProjects"
      height="28px"
      value={searchQuery || undefined}
      onValueChange={onSearch}
    />
  )
}

export default SearchForm
