import { ApolloError, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { sharedAssetQuery } from './SharedAssetPage.graphql'
import { SharedAssetQuery } from './SharedAssetPage.types'
import { ProjectAsset } from '../ProjectPage/ProjectPage.types'

export const useSharedAssetQuery = (
  props: SharedAssetQuery,
): {
  asset: ProjectAsset
  loading: boolean
  updateQuery: any
  error?: ApolloError
} => {
  const history = useHistory()

  const { data, loading, error, updateQuery } = useQuery(sharedAssetQuery, {
    variables: { uuid: props.uuid },
  })
  const asset = data?.sharedAsset

  if (asset?.project_id) {
    history.push(`/projects/${asset.project_id}`)
  }

  return {
    asset,
    error,
    loading,
    updateQuery,
  }
}
