import { IonList, useIonRouter } from '@ionic/react'
import { useLogoutMutation } from './UserInfo.hooks'
import { useAuthContext } from '../../../contexts/auth'
import { PopoverItem } from '../PopoverItem'
import { UserOrganisationsMenu } from './UserOrganisationsMenu'

export const UserInfoMenu = () => {
  const { logout } = useLogoutMutation()
  const { setIsLoggedIn } = useAuthContext()
  const { push } = useIonRouter()

  const onProfileClick = () => {
    push('/profile')
  }

  const onLogoutClick = () => {
    logout()
    setIsLoggedIn(false)
  }

  return (
    <IonList>
      <UserOrganisationsMenu />
      <PopoverItem onClick={onProfileClick}>Profile</PopoverItem>
      <PopoverItem onClick={onLogoutClick}>Logout</PopoverItem>
    </IonList>
  )
}
