import { useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import * as Sentry from '@sentry/react'
import { useAuthContext } from '../../contexts/auth'
import { loginMutation } from './LoginPage.graphql'
import { LoginFormFields } from './LoginPage.types'
import mixpanel from '../../helpers/tracking'
import { useOrganisationContext } from '../../contexts/organisation'

export const useLogin = () => {
  const { setIsLoggedIn } = useAuthContext()
  const { setOrganisationId } = useOrganisationContext()
  const [login, { loading }] = useMutation(loginMutation, {
    refetchQueries: ['Me'],
  })

  const handleLogin = async (
    values: LoginFormFields,
    formikHelpers: FormikHelpers<LoginFormFields>,
  ) => {
    try {
      const { data } = await login({ variables: values })
      if (data && data?.login?.id) {
        mixpanel.identify(data?.login?.id)
        Sentry.setUser({
          id: data.login,
          email: values.email || undefined,
        })
      }

      setOrganisationId(data?.login?.organisations?.[0]?.id)

      setIsLoggedIn(data?.login?.isLoggedIn)
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    handleLogin,
    loading,
  }
}
