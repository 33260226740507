/* eslint-disable import/no-default-export */
import React from 'react'
import { Title } from '../ui/Title'
import { ScrollToContainer } from '../ui/ScrollToContainer'
import ResponsiveDescription from './ResponsiveDescription.component'

interface ProjectDescriptionProps {
  description: string | undefined
}

const ProjectDescription: React.FC<ProjectDescriptionProps> = ({
  description,
}) => (
  <ScrollToContainer
    name="project-description"
    data-testid="ProjectDescription"
  >
    <Title>Description</Title>
    <ResponsiveDescription description={description} />
  </ScrollToContainer>
)
export default ProjectDescription
