import React, { createContext, useContext } from 'react'

interface ProjectListContext {
  updateQuery: any
  fetchMore: any
}

const Context = createContext<ProjectListContext>({
  updateQuery: () => {},
  fetchMore: () => {},
})

export const useProjectListContext = () =>
  useContext<ProjectListContext>(Context)

export const ProjectListProvider: React.FC<{
  value: ProjectListContext
  children: React.ReactNode
}> = ({ children, value }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
)
