import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { ChangePasswordFormFields } from './ChangePasswordDialog.types'
import { changePasswordMutation } from './ChangePasswordDialog.graphql'
import { useNotification } from '../../contexts/notification'

export const useChangePassword = (onDismiss: () => void) => {
  const [changePasswordRequest, { loading }] = useMutation(
    changePasswordMutation,
  )

  const { notify } = useNotification()

  const changePassword = async (
    values: ChangePasswordFormFields,
    formikHelpers: FormikHelpers<ChangePasswordFormFields>,
  ) => {
    try {
      await changePasswordRequest({
        variables: {
          ...values,
        },
      })

      notify('New password successfully set')

      onDismiss()
    } catch (error) {
      // @ts-ignore
      formikHelpers.setFieldError('error', error.message)
    }
  }

  return {
    changePassword,
    loading,
  }
}
