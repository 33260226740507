import { useQuery } from '@apollo/client'
import { portfolioListQuery } from './PortfoliosListPage.graphql'
import { unpackPermissions } from '../../helpers/casl'
import { Portfolio } from '../PortfolioPage/PortfolioPage.types'

const mapper = (portfolio: any) => ({
  ...portfolio,
  permissions: unpackPermissions(portfolio.permissions),
})

export const usePortfolioListQuery = (
  organisationId: number | undefined,
): {
  portfolioList: Portfolio[]
  loading: boolean
  updateQuery: any
  fetchMore: any
  refetch: any
} => {
  const { data, loading, updateQuery, fetchMore, refetch } = useQuery(
    portfolioListQuery,
    {
      variables: {
        organisationId,
      },
    },
  )

  return {
    portfolioList: data && data.portfolios.map(mapper),
    loading,
    updateQuery,
    fetchMore,
    refetch,
  }
}
