/* eslint-disable import/no-default-export */
import { IonIcon, IonSpinner } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { portfolioPermissions, portfolioRoles } from '@synctech/portal-types'
import { useNotification } from '../../contexts/notification'
import { PopoverItem } from '../ui/PopoverItem'
import { PopoverItemWithAlert } from '../ui/PopoverItemWithAlert'
import { useChangePortfolioUserRole } from './PortfolioUserAssignRoleItem.hooks'
import { PortfolioUserAssignRoleItemProps } from './PortfolioUserAssignRoleItem.types'

const PortfolioUserAssignRoleItem = ({
  portfolioId,
  listedUserId,
  listedUserRoleId,
  portfolioRoleId,
  roleName,
  permissions,
}: PortfolioUserAssignRoleItemProps) => {
  const { changeUserRole, loading } = useChangePortfolioUserRole(
    portfolioId,
    listedUserId,
    portfolioRoleId,
  )
  const { notify, notifyError } = useNotification()

  const onRoleChange = async () => {
    try {
      await changeUserRole()

      notify(
        `Role changed from ${portfolioRoles[listedUserRoleId]} to ${roleName}`,
      )
    } catch (err) {
      // @ts-ignore
      notifyError(err?.error || err?.message)
    }
  }

  const message = `Are you sure you want to change this user's role from ${portfolioRoles[listedUserRoleId]} to ${roleName}?`

  const canManageRole = permissions.includes(
    portfolioPermissions[listedUserRoleId],
  )
  const disabled =
    !canManageRole ||
    !permissions.includes(portfolioPermissions[portfolioRoleId])

  return !(portfolioRoleId === listedUserRoleId) ? (
    <PopoverItemWithAlert
      key={portfolioRoleId}
      header="Assign a new role"
      message={message}
      confirmButtonText="Assign"
      onClick={onRoleChange}
      disabled={disabled}
    >
      {roleName}
      {loading && <IonSpinner slot="end" name="lines-small" />}
    </PopoverItemWithAlert>
  ) : (
    <PopoverItem key={portfolioRoleId} disabled={disabled}>
      {roleName}
      <IonIcon
        slot="end"
        color="primary"
        size="small"
        icon={checkmarkCircleOutline}
      />
    </PopoverItem>
  )
}

export default PortfolioUserAssignRoleItem
